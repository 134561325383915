export default {
  // AUTH
  login: 'iniciar sesión',
  Password: 'Contraseña',
  forgotPassword: 'Se te olvidó tu contraseña',
  noAccountButton: 'No tienes una cuenta',
  invalidLogin: 'Usuario o contraseña invalido',
  emailSent: 'Te hemos enviado un correo electrónico para restablecer tu contraseña',
  sendEmail: 'Enviar E-mail',
  playgroundHowMuch: 'Cuánto quieres pedir prestado?',
  requestAccessTitle: 'Solicitar Acceso',
  requestAccessButton: 'Solicitar Acceso',
  collateralNeeded: 'Garantía necesaria',
  firstNameField: 'Nombre',
  lastNameField: 'Apellido',
  phoneField: 'Teléfono',
  createAccount: 'Crear una Cuenta',
  countrySelector: 'País',
  resetPassword: 'Restablecer la Contraseña',
  passwordResetSuccess: 'Tu contraseña fue restablecida con éxito!',
  goToLogin: 'Ir a Login',
  newPassword: 'Nueva Contraseña',
  repeatPassword: 'Repite la Contraseña',

  // CREDIT SCREEN
  activity: 'actividad',
  creditBalance: 'balance de crédito',
  balanceBarBalance: 'balance',
  safe: 'seguro',
  atRisk: 'en riesgo',
  payback: 'reembolso',
  creditLimit: 'límite de crédito',
  collateral: 'colateral',
  credit: 'crédito',
  walletTotal: 'valor de cartera',
  totalCredit: 'crédito total',
  deposit: 'depositar',
  withdraw: 'retirar',
  liquidationPrice: 'precio de liquidación',
  liquidation: 'liquidación',
  limit: 'límite',
  card: 'tarjeta',
  more: 'más',
  loans: 'prestamos',
  loan: 'préstamo',
  awaitingApproval: 'Esperando aprobacion',
  awaitingApprovalDesc: 'Esto puede llevar hasta 24 horas',
  end: 'fin',
  simulate: 'Simular',
  awaitingTransfer: 'en espera de transferencia',
  awaitingTransferCaption: 'Una vez que se confirme su depósito, podrá obtener una línea de crédito.',
  creditZeroStateTitle: 'Felicidades! Has sido aprobado.',
  creditZeroStateDesc: 'Deposite algunas criptomonedas ahora y obtenga una línea de crédito.',

  // LOAN DETAIL
  loanWalletReceived: 'Recibido en tu billetera',
  loanWalletAwaiting: 'Esperando recibir fondos',
  amountBorrowed: 'Cantidad prestada',
  interestPerMonth: 'intereses / mes',
  loanPeriod: 'periodo de préstamo',
  loanNotFound: 'Lo sentimos, préstamo no encontrado',

  // REQUEST LOAN

  // SINGLE
  month: 'mes',
  interest: 'interesar',
}
