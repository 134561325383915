import I18n from 'i18n-js'
import * as RNLocalize from 'react-native-localize'

import en from './locales/en'
import es from './locales/es'
import pt from './locales/pt'

const LANGUAGE_TAGS = ['en', 'es', 'pt']

I18n.locale = RNLocalize.findBestAvailableLanguage(LANGUAGE_TAGS)?.languageTag || 'en-US'
I18n.fallbacks = true
I18n.translations = {
  en,
  es,
  pt,
}

export const localizeString = (item: I18nKey | null | undefined) => {
  if (!item) {
    return ''
  }
  return (item = Array.isArray(item) ? I18n.t(...item) : I18n.t(item))
}

export default I18n
