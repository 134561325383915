import React, { useEffect, useState } from 'react'
import { Image, Linking } from 'react-native'

import { COLORS, SCREEN_SIZE } from '../../styles/global-styles'
import loadFonts from '../../utils/loadFonts'
import Box from '../common/Box'
import Loader from '../common/Loader'
import { Screen } from '../common/Screen'
import Text from '../common/Text'
import Button from '../form/Button'

const LOGO_SCALE = 0.37

export default function NotAvailable() {
  const { height, width } = SCREEN_SIZE
  const [loadingFonts, setLoadingFonts] = useState(true)

  useEffect(() => {
    const didMount = async () => {
      await loadFonts()
      setLoadingFonts(false)
    }
    didMount()
  }, [])

  if (loadingFonts) {
    return <Loader noAnimation />
  }

  return (
    <Screen
      hideHeader
      statusBarMode="light"
      disableSafeAreaView
      backgroundColor={COLORS.WHITE}
      safeAreaInsets={{
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}>
      <Box flex1>
        <Box absolute top={0} left={0} paddingX={7} paddingY={1} safeArea="top">
          <Image
            source={require('../../assets/images/logo/logo-spend.png')}
            style={{ width: 282 * LOGO_SCALE, height: 174 * LOGO_SCALE }}
          />
        </Box>
        <Box center width={width} height={height} flex1 absolute top={-135} right={-80} safeArea="top">
          <Image
            source={require('../../assets/images/welcome-slider/bank-orange.png')}
            style={{
              width,
            }}
            resizeMode="contain"
          />
        </Box>
        <Box absolute bottom={0} safeArea="bottom" padding={8} align="flex-start">
          <Text variant="NUMBERS_HEADER_BROWN">An update</Text>
          <Box paddingY={3}>
            <Text>
              Hightop is currently not available to the public; we’ll email you with future updates. Thank you for your
              support! If you need help, please email <Text color={COLORS.ORANGE}>hello@hightop.com</Text>.
            </Text>
          </Box>
          <Button
            label="Learn about what’s next"
            outline
            submit={false}
            onPress={() => Linking.openURL('https://medium.com/hightop/were-doubling-down-on-defi-ce93fa457328')}
            style={{ paddingRight: 18, paddingLeft: 18 }}
          />
        </Box>
      </Box>
    </Screen>
  )
}
