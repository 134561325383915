import { createContext, useContext } from 'react'

import { AssetCode, AssetType } from '../../api/graphqlTypes/globalTypes'
import { FirebaseUser } from '../../services/firebase'
import { SCREEN_SIZE } from '../../styles/global-styles'
import EventEmitter from '../../utils/EventEmitter'

export type UserPreferences = {
  nativeCurrency: Asset
}

export type SafeAreaInsets = {
  top: number
  right: number
  bottom: number
  left: number
}

export type ScreenSize = {
  type: 'x-small' | 'small' | 'medium' | 'large'
  width: number
  height: number
}

export type CardFlowState = 'zero' | 'not-issued' | 'issued' | 'shipped' | 'delivered' | 'active'

export type ContextType = {
  user?: FirebaseUser | null | undefined
  safeAreaInsets: SafeAreaInsets
  screenSize: ScreenSize
  userPreferences: UserPreferences
  subscribeAuthChange: () => void
  eventEmitter: EventEmitter
}

export const NATIVE_CURRENCY: Asset = {
  __typename: 'Asset',
  id: 'native-usd-asset',
  name: 'US Dollars',
  code: AssetCode.USD,
  symbol: '$',
  decimals: 2,
  type: AssetType.FIAT,
}

const AppContext = createContext<ContextType>({
  user: null,
  safeAreaInsets: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  screenSize: { type: 'large', ...SCREEN_SIZE },
  userPreferences: {
    nativeCurrency: NATIVE_CURRENCY,
  },
  subscribeAuthChange: () => {},
  eventEmitter: new EventEmitter(),
})

export const useAppContext = () => {
  return useContext<ContextType>(AppContext)
}

export const useNativeAsset = () => {
  const { userPreferences } = useAppContext()
  return userPreferences.nativeCurrency
}

export default AppContext
