import React, { useEffect } from 'react'
import { Platform, LogBox } from 'react-native'

import RNBootSplash from 'react-native-bootsplash'
import codePush from 'react-native-code-push'
import { SafeAreaProvider } from 'react-native-safe-area-context'

import Box from './components/common/Box'
import NotAvailable from './components/core/NotAvailable'
import { COLORS } from './styles/global-styles'
import LaunchArguments from './utils/LaunchArguments'

const launchArgs = LaunchArguments.value()
LogBox.ignoreAllLogs(!!launchArgs?.e2eTesting)

export const App = () => {
  useEffect(() => {
    if (Platform.OS !== 'web') {
      RNBootSplash.hide()
    }
  }, [])

  return (
    <SafeAreaProvider>
      <Box flex1 backgroundColor={COLORS.WHITE}>
        <NotAvailable />
      </Box>
    </SafeAreaProvider>
  )
}

export default Platform.OS === 'web'
  ? App
  : codePush({
      checkFrequency: codePush.CheckFrequency.ON_APP_START,
      installMode: codePush.InstallMode.IMMEDIATE,
    })(App)
