import React from 'react'

import ICONS from './IconPaths'

type Props = {
  name: string
  width?: number
  height?: number
}

export default function Icon(props: Props) {
  return (
    <img
      alt={props.name}
      width={props.width}
      height={props.height}
      // @ts-ignore
      src={ICONS[props.name]}
    />
  )
}
