import React, { useState } from 'react'
import { View, StyleSheet } from 'react-native'

import { Status, getStatusMessage } from '../../hooks'
import { localizeString } from '../../localization/localize'
import { COLORS, SPACING_BASE } from '../../styles/global-styles'
import Animation from './Animation'
import Icon from './Icon'
import Spacer from './Spacer'
import Text from './Text'
import TextButton from './TextButton'

type Props = {
  loadingMessageI18nKey?: string
  titleI18nKey?: string
  messageI18nKey?: string
  loadingMessage?: string
  successI18nKey?: string
  error?:
    | boolean
    | {
        i18nMessage?: string
        i18nTitle?: string
        title?: string
        message?: string | null
      }
    | null
  success?: boolean | null
  successMessage?: string | null
  containerStyle?: StyleProp
  fill?: boolean
  dark?: boolean
  noAnimation?: boolean
  infinite?: boolean
  backgroundColor?: string
  testID?: string
  errorButton?: {
    onPress: () => any
    i18nKey?: string
    label?: string
  } | null
  highlightColor?: string
}

const Loader = (props: Props) => {
  const [infinite, setInfinite] = useState<boolean>(false)
  const completeIcon = props.dark ? 'completeCheckWhite' : 'completeCheck'

  const containerStyles: StyleProp[] = [styles.container]

  if (props.fill) {
    containerStyles.push(styles.fill)
  }

  containerStyles.push({
    backgroundColor: props.backgroundColor ? props.backgroundColor : props.dark ? COLORS.BLACK : COLORS.WHITE,
  })

  const errorButton = props.errorButton && (
    <TextButton
      style={styles.errorButton}
      onPress={props.errorButton.onPress}
      label={props.errorButton.label}
      labelI18nKey={props.errorButton.i18nKey}
      color={COLORS.RED}
    />
  )

  if (props.error) {
    return (
      <View style={[...containerStyles, props.containerStyle]}>
        <View style={styles.iconContainer}>
          <Icon name="warning" width={50} height={50} />
        </View>
        {typeof props.error === 'boolean' ? null : (
          <>
            <Text
              center
              style={styles.errorTitle}
              color={COLORS.RED_ALERT}
              i18nKey={!props.error.title ? props.error.i18nTitle || 'genericErrorTitle' : null}
              variant="BODY_TEXT_MEDIUM">
              {props.error.title || null}
            </Text>
            <Text center i18nKey={props.error.i18nMessage} variant="SMALL_TEXT_REGULAR">
              {props.error.message}
            </Text>
          </>
        )}
        {errorButton}
      </View>
    )
  }

  if (props.success || props.successMessage || props.successI18nKey) {
    const message = props.successMessage || (props.successI18nKey && localizeString(props.successI18nKey)) || ''

    let black = message
    let highlightedText = ''

    const words = message.split(' ')
    if (words.length > 1) {
      highlightedText = words.pop()
      black = words.join(' ')
    }

    return (
      <View style={[...containerStyles, props.containerStyle]}>
        <View style={styles.iconContainer}>
          <Icon name={completeIcon} />
        </View>
        <Text variant="BODY_TEXT_BOLD" color={props.dark ? COLORS.WHITE : COLORS.BLACK} center>
          {black}
          <Text variant="BODY_TEXT_BOLD" color={props.highlightColor || COLORS.ORANGE}>
            {' '}
            {highlightedText}
          </Text>
        </Text>
      </View>
    )
  }

  return (
    <View testID={props.testID} style={[...containerStyles, props.containerStyle]}>
      <View style={styles.square}>
        {props.noAnimation ? null : props.dark ? (
          infinite || props.infinite ? (
            <Animation loop autoplay data={require(`../../assets/animations/GB_InfiniteLoader_BlackBG.json`)} />
          ) : (
            <Animation
              onFinish={() => setInfinite(true)}
              loop={false}
              autoplay
              data={require(`../../assets/animations/GB_StartLoader_BlackBG.json`)}
            />
          )
        ) : infinite || props.infinite ? (
          <Animation loop autoplay data={require(`../../assets/animations/GB_InfiniteLoader.json`)} />
        ) : (
          <Animation
            onFinish={() => setInfinite(true)}
            loop={false}
            autoplay
            data={require(`../../assets/animations/GB_StartLoader.json`)}
          />
        )}
      </View>
      {props.titleI18nKey ? (
        <>
          <Spacer v={12} />
          <Text variant="TITLE_LARGE_BOLD" center i18nKey={props.titleI18nKey} />
        </>
      ) : null}
      {props.messageI18nKey ? (
        <>
          <Spacer v={6} />
          <Text center color={COLORS.GREY4} i18nKey={props.messageI18nKey} />
        </>
      ) : null}
      {props.loadingMessage || props.loadingMessageI18nKey ? (
        <>
          <Spacer v={4} />
          <Text center color={props.dark ? COLORS.WHITE : COLORS.BLACK} i18nKey={props.loadingMessageI18nKey}>
            {props.loadingMessage}
          </Text>
        </>
      ) : null}
    </View>
  )
}

export default Loader

type StatusLoaderProps = Props & {
  status: Status
}

export const StatusLoader = (props: StatusLoaderProps) => {
  const { status, ...other } = props

  const loaderProps =
    status.state === 'ERROR'
      ? {
          error: {
            message: getStatusMessage(status),
          },
        }
      : status.state === 'COMPLETE'
      ? {
          successMessage: getStatusMessage(status),
        }
      : {}
  return <Loader {...loaderProps} {...other} />
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 40,
  },
  errorTitle: {
    marginBottom: SPACING_BASE * 2,
  },
  square: {
    width: 60,
    height: 60,
  },
  iconContainer: {
    paddingBottom: SPACING_BASE * 5,
  },
  errorButton: {
    marginTop: SPACING_BASE * 2,
  },
  fill: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
})
