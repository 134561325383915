import React from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'

import { useForm } from 'formact'

import COLORS from '../../styles/colors'
import Text from '../common/Text'

type Props = {
  labelI18nKey?: I18nKey
  label?: string
  transparent?: boolean
  white?: boolean
  submit?: boolean
  disabled?: boolean
  invalidFormDisabled?: boolean
  children?: any
  testID?: string
  outline?: boolean
  fullWidth?: boolean
  onPress?: () => any
  style?: {
    [key: string]: any
  }
  textStyle?: {
    [key: string]: any
  }
}

export default function Button({ disabled, invalidFormDisabled, ...props }: Props) {
  const { valid, submit: submitForm } = useForm()

  const onSubmit = () => {
    const isDisabled = disabled || (invalidFormDisabled && !valid)
    if (!isDisabled) {
      if (props.submit) {
        submitForm()
      }
      props.onPress?.()
    }
  }

  const { label, transparent, white, children, style, textStyle, labelI18nKey, testID, outline, fullWidth } = props

  const btnStyles: StyleProp[] = [styles.button]

  if (transparent) {
    btnStyles.push(styles.transparent)
  }

  if (white) {
    btnStyles.push(styles.white)
  }

  if (style) {
    btnStyles.push(style)
  }

  if (outline) {
    btnStyles.push(styles.outline)
  }

  if (fullWidth) {
    btnStyles.push(styles.fullWidth)
  }

  if (disabled) {
    btnStyles.push(styles.disabled)
  }

  return (
    <TouchableOpacity disabled={disabled} onPress={onSubmit} style={btnStyles} testID={testID}>
      {children || (
        <Text
          variant="BODY_TEXT_MEDIUM"
          color={outline ? COLORS.BLACK : COLORS.WHITE}
          i18nKey={labelI18nKey}
          style={textStyle}>
          {label}
        </Text>
      )}
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  button: {
    minWidth: 200,
    padding: 20,
    backgroundColor: COLORS.ORANGE,
    paddingHorizontal: 5,
    paddingVertical: 10,
    marginVertical: 5,
    borderRadius: 3,
    alignItems: 'center',
    justifyContent: 'center',
  },
  transparent: {
    minWidth: 0,
    width: 'auto',
    backgroundColor: COLORS.TRANSPARENT,
  },
  white: {
    backgroundColor: COLORS.WHITE,
  },
  outline: {
    backgroundColor: COLORS.TRANSPARENT,
    borderWidth: 1,
    borderColor: COLORS.BLACK,
    minWidth: 180,
  },
  fullWidth: {
    minWidth: undefined,
    flex: 1,
  },
  disabled: {
    opacity: 0.3,
  },
})
