import React from 'react'

import Text from './Text'

import '../../styles/css/styles.css'

type Props = {
  children: Children
}

export function Screen(props: Props) {
  return (
    <div className="background">
      <div className="container">{props.children}</div>
      <div className="footer">
        <Text variant="SMALL_TEXT_REGULAR" center>
          © {new Date().getFullYear()}, Genesis Block. All rights reserved.
        </Text>
      </div>
    </div>
  )
}

export const GlobalScreen = Screen

export default Screen
