import { StyleSheet, Platform, Dimensions } from 'react-native'

import Constants from 'expo-constants'

import COLORS from './colors'

export { default as COLORS } from './colors'

export type TextVariant =
  | 'SYNE'
  | 'NUMBERS_HEADER_MAIN'
  | 'NUMBERS_HEADER_MAIN_SE'
  | 'NUMBERS_HEADER_SECONDARY'
  | 'NUMBERS_HEADER_SECONDARY_SE'
  | 'NUMBERS_HEADER_BROWN'
  | 'NUMBERS'
  | 'NUMBERS_EARN_MEDIUM_LARGE'
  | 'TITLE_MAIN_MEDIUM'
  | 'TITLE_MAIN_REGULAR'
  | 'TITLE_SECONDARY_REGULAR'
  | 'TITLE_SECONDARY_MEDIUM'
  | 'TITLE_LARGE_BOLD'
  | 'TITLE_LARGE_REGULAR'
  | 'TITLE_XLARGE_BOLD'
  | 'TITLE_BOLD'
  | 'CAPTION'
  | 'NANO_TEXT_REGULAR'
  | 'NANO_TEXT_MEDIUM'
  | 'MICRO_TEXT_REGULAR'
  | 'MICRO_TEXT_MEDIUM'
  | 'MICRO_TEXT_BOLD'
  | 'SMALL_TEXT_REGULAR'
  | 'SMALL_TEXT_MEDIUM'
  | 'SMALL_TEXT_BOLD'
  | 'BODY_TEXT_REGULAR'
  | 'BODY_TEXT_MEDIUM'
  | 'BODY_TEXT_BOLD'
  | 'BODY_TEXT_EXTRA_BOLD'
  | 'MEDIUM_TEXT_REGULAR'
  | 'MEDIUM_TEXT_MEDIUM'
  | 'MEDIUM_TEXT_BOLD'
  | 'LARGE_TEXT_REGULAR'
  | 'LARGE_TEXT_MEDIUM'
  | 'LARGE_TEXT_BOLD'
  | 'RATIO_BAR_LABEL'
  | 'WELCOME_TITLE'
  | 'BROWN_HEADER_REGULAR'

export const FONTS = {
  FAMILY: {
    BROWN_LL_REGULAR: 'brown-ll-regular',
    TEXT_EXTRA_BOLD: 'helvetica-new-text-extrabold',
    TEXT_BOLD: 'helvetica-new-text-bold',
    MICRO_NORMAL: 'helvetica-new-micro-normal',
    MICRO_MEDIUM: 'helvetica-new-micro-medium',
    MICRO_BOLD: 'helvetica-new-micro-bold',
    MICRO_EXTRA_BOLD: 'helvetica-new-micro-extrabold',
    MICRO_LIGHT: 'helvetica-new-micro-light',
    MICRO_ITALIC: 'helvetica-new-micro-italic',
    SYNE: 'syne',
    NOW_NORMAL: 'helvetica-now-text-regular',
  },
  WEIGHT: {
    NORMAL: 400,
    MEDIUM: 500,
    BOLD: 700,
    EXTRA_BOLD: 800,
  },
}

const NANO_TEXT = {
  lineHeight: 13,
  fontSize: 9,
}

const MICRO_TEXT = {
  lineHeight: 14,
  fontSize: 10,
}

const SMALL_TEXT = {
  lineHeight: 16,
  fontSize: 11,
}

const BODY_TEXT = {
  lineHeight: 18,
  fontSize: 13,
}

const MEDIUM_TEXT = {
  lineHeight: 18,
  fontSize: 15,
}

const LARGE_TEXT = {
  lineHeight: 22,
  fontSize: 16,
}

export const TEXT_STYLES = StyleSheet.create({
  SYNE: {
    fontFamily: FONTS.FAMILY.SYNE,
    fontSize: 24,
    lineHeight: 24,
  },
  //NUMBERS:
  NUMBERS_HEADER_MAIN: {
    fontFamily: FONTS.FAMILY.TEXT_EXTRA_BOLD,
    fontSize: 28,
    lineHeight: 36,
  },
  NUMBERS_HEADER_BROWN: {
    fontFamily: FONTS.FAMILY.BROWN_LL_REGULAR,
    fontSize: 28,
    lineHeight: 36,
  },
  NUMBERS_HEADER_MAIN_SE: {
    fontFamily: FONTS.FAMILY.TEXT_EXTRA_BOLD,
    fontSize: 24,
    lineHeight: 28,
  },
  NUMBERS_HEADER_SECONDARY: {
    fontFamily: FONTS.FAMILY.TEXT_EXTRA_BOLD,
    fontSize: 16,
    lineHeight: 24,
  },
  NUMBERS_HEADER_SECONDARY_SE: {
    fontFamily: FONTS.FAMILY.TEXT_EXTRA_BOLD,
    fontSize: 14,
    lineHeight: 21,
  },
  NUMBERS: {
    fontFamily: FONTS.FAMILY.TEXT_BOLD,
    fontSize: 17,
    lineHeight: 24,
  },
  NUMBERS_EARN_MEDIUM_LARGE: {
    letterSpacing: 1,
    lineHeight: 23,
    fontFamily: FONTS.FAMILY.TEXT_EXTRA_BOLD,
    fontSize: 19,
  },

  //TITLES:
  TITLE_MAIN_MEDIUM: {
    lineHeight: 12,
    textTransform: 'uppercase',
    letterSpacing: 2,
    fontFamily: FONTS.FAMILY.MICRO_MEDIUM,
    fontSize: 10,
  },
  TITLE_MAIN_REGULAR: {
    lineHeight: 24,
    letterSpacing: 1,
    fontFamily: FONTS.FAMILY.NOW_NORMAL,
    fontSize: 16,
  },
  TITLE_SECONDARY_REGULAR: {
    lineHeight: 15,
    textTransform: 'uppercase',
    letterSpacing: 1.5,
    fontFamily: FONTS.FAMILY.MICRO_NORMAL,
    fontSize: 10,
  },

  TITLE_SECONDARY_MEDIUM: {
    lineHeight: 15,
    textTransform: 'uppercase',
    letterSpacing: 2,
    fontSize: 10,
    fontFamily: FONTS.FAMILY.MICRO_MEDIUM,
  },

  TITLE_LARGE_BOLD: {
    letterSpacing: 1,
    lineHeight: 24,
    fontFamily: FONTS.FAMILY.TEXT_BOLD,
    fontSize: 20,
  },

  TITLE_LARGE_REGULAR: {
    letterSpacing: 1,
    lineHeight: 24,
    fontFamily: FONTS.FAMILY.MICRO_MEDIUM,
    fontSize: 20,
  },

  TITLE_XLARGE_BOLD: {
    letterSpacing: 1,
    lineHeight: 26,
    fontFamily: FONTS.FAMILY.TEXT_BOLD,
    fontSize: 22,
  },

  TITLE_BOLD: {
    letterSpacing: 1,
    lineHeight: 18,
    fontFamily: FONTS.FAMILY.TEXT_BOLD,
    fontSize: 16,
  },

  CAPTION: {
    lineHeight: 15,
    fontFamily: FONTS.FAMILY.MICRO_ITALIC,
    fontStyle: 'italic',
    fontSize: 10,
  },

  NANO_TEXT_REGULAR: {
    ...NANO_TEXT,
    fontFamily: FONTS.FAMILY.MICRO_NORMAL,
  },
  NANO_TEXT_MEDIUM: {
    ...NANO_TEXT,
    fontFamily: FONTS.FAMILY.MICRO_MEDIUM,
  },

  MICRO_TEXT_REGULAR: {
    ...MICRO_TEXT,
    fontFamily: FONTS.FAMILY.MICRO_NORMAL,
  },
  MICRO_TEXT_MEDIUM: {
    ...MICRO_TEXT,
    fontFamily: FONTS.FAMILY.MICRO_MEDIUM,
  },
  MICRO_TEXT_BOLD: {
    ...MICRO_TEXT,
    fontFamily: FONTS.FAMILY.MICRO_BOLD,
  },

  SMALL_TEXT_REGULAR: {
    ...SMALL_TEXT,
    fontFamily: FONTS.FAMILY.MICRO_NORMAL,
  },
  SMALL_TEXT_MEDIUM: {
    ...SMALL_TEXT,
    fontFamily: FONTS.FAMILY.MICRO_MEDIUM,
  },
  SMALL_TEXT_BOLD: {
    ...SMALL_TEXT,
    fontFamily: FONTS.FAMILY.MICRO_BOLD,
  },

  BODY_TEXT_REGULAR: {
    ...BODY_TEXT,
    fontFamily: FONTS.FAMILY.MICRO_NORMAL,
  },
  BODY_TEXT_MEDIUM: {
    ...BODY_TEXT,
    fontFamily: FONTS.FAMILY.MICRO_MEDIUM,
  },
  BODY_TEXT_BOLD: {
    ...BODY_TEXT,
    fontFamily: FONTS.FAMILY.MICRO_BOLD,
  },
  BODY_TEXT_EXTRA_BOLD: {
    ...BODY_TEXT,
    fontFamily: FONTS.FAMILY.MICRO_EXTRA_BOLD,
  },

  MEDIUM_TEXT_REGULAR: {
    ...MEDIUM_TEXT,
    fontFamily: FONTS.FAMILY.MICRO_NORMAL,
  },
  MEDIUM_TEXT_MEDIUM: {
    ...MEDIUM_TEXT,
    fontFamily: FONTS.FAMILY.MICRO_MEDIUM,
  },
  MEDIUM_TEXT_BOLD: {
    ...MEDIUM_TEXT,
    fontFamily: FONTS.FAMILY.MICRO_BOLD,
  },

  LARGE_TEXT_REGULAR: {
    ...LARGE_TEXT,
    fontFamily: FONTS.FAMILY.MICRO_NORMAL,
  },
  LARGE_TEXT_MEDIUM: {
    ...LARGE_TEXT,
    fontFamily: FONTS.FAMILY.MICRO_MEDIUM,
  },
  LARGE_TEXT_BOLD: {
    ...LARGE_TEXT,
    fontFamily: FONTS.FAMILY.MICRO_BOLD,
  },
  RATIO_BAR_LABEL: {
    ...SMALL_TEXT,
    fontFamily: FONTS.FAMILY.MICRO_MEDIUM,
    color: COLORS.GREY4,
    textTransform: 'capitalize',
  },
  WELCOME_TITLE: {
    fontFamily: FONTS.FAMILY.BROWN_LL_REGULAR,
    fontSize: 24,
    lineHeight: 24,
    letterSpacing: 1,
  },
  LARGE_TITLE: {
    fontFamily: FONTS.FAMILY.BROWN_LL_REGULAR,
    fontSize: 24,
    lineHeight: 24,
    letterSpacing: 1,
  },
  BROWN_HEADER_REGULAR: {
    fontFamily: FONTS.FAMILY.BROWN_LL_REGULAR,
    fontSize: 20,
    lineHeight: 24,
    fontWeight: '400',
    letterSpacing: 1,
  },
})

export const SPACING_BASE = 4

export const SCREEN_SIZES = {
  XSMALL: 320,
  SMALL: 480,
  MEDIUM: 768,
}

export const TABLET_DIMENSIONS = {
  WIDTH: 600,
  HEIGHT: 1000,
}

export const MAX_WEB_CONTAINER_WIDTH = 1000
export const MAX_WEB_CONTAINER_HEIGHT = 1000

export const STATUS_BAR_HEIGHT = Platform.select({
  ios: Constants.statusBarHeight,
  android: Constants.statusBarHeight + 20,
  web: 0,
})

const RN_SCREEN_SIZE = Dimensions.get('screen')

export const SCREEN_SIZE = {
  width: Math.min(RN_SCREEN_SIZE.width, RN_SCREEN_SIZE.height),
  height: Math.max(RN_SCREEN_SIZE.width, RN_SCREEN_SIZE.height),
}
