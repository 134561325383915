import React from 'react'
import { StyleSheet, TouchableOpacity, View, ActivityIndicator } from 'react-native'

import { useForm } from 'formact'

import { COLORS, SPACING_BASE, TextVariant } from '../../styles/global-styles'
import Icon from './Icon'
import Text from './Text'

type FormProps = {
  submit?: boolean | null | undefined
  invalidFormDisabled?: boolean
  formValid?: boolean
  submitForm?: () => void
  withArrow?: boolean
}

type Props = FormProps & {
  labelI18nKey?: I18nKey
  label?: string
  disabled?: boolean | null | undefined
  onPress?: () => any
  textVariant?: TextVariant
  style?: {
    [key: string]: any
  }
  textStyle?: {
    [key: string]: any
  }
  loading?: boolean | null | undefined
  secondary?: boolean
  containerStyle?: {
    [key: string]: any
  }
  capitalize?: boolean
  color?: string
  disabledColor?: string
  loadSubmitting?: boolean
  buttonTestID?: string
}

export default function TextButton({ textVariant = 'BODY_TEXT_MEDIUM', ...props }: Props) {
  const { valid, submit, submitting } = useForm()

  const isDisabled = () => {
    const { disabled, invalidFormDisabled, formValid = valid } = props
    return disabled || (invalidFormDisabled && !formValid)
  }

  const onSubmit = () => {
    if (!isDisabled()) {
      if (props.submit) {
        submit()
      }
      props.onPress?.()
    }
  }

  const {
    label,
    style,
    textStyle,
    labelI18nKey,
    loading,
    loadSubmitting,
    secondary,
    containerStyle,
    capitalize,
    color,
    withArrow,
    buttonTestID,
  } = props

  const btnStyles: StyleProp[] = [styles.button]
  const txtStyles: StyleProp[] = [styles.text]
  const containerStyles: StyleProp[] = [styles.container]

  const isLoading = loading || (loadSubmitting && submitting)

  if (secondary) {
    txtStyles.push(styles.txtSecondary)
  }

  if (textStyle) {
    txtStyles.push(textStyle)
  }

  if (isDisabled()) {
    txtStyles.push(styles.txtDisabled)
  }

  if (style) {
    btnStyles.push(style)
  }

  if (color) {
    txtStyles.push({ color })
  }

  if (containerStyle) {
    containerStyles.push(containerStyle)
  }

  const arrow = withArrow && (
    <View style={styles.arrow}>
      <Icon name="arrowRight" color={color || COLORS.BLACK} />
    </View>
  )

  return (
    <View style={containerStyles}>
      {isLoading ? (
        <ActivityIndicator color={color || COLORS.GREY5} />
      ) : (
        <TouchableOpacity testID={buttonTestID} onPress={onSubmit} disabled={isDisabled()} style={btnStyles}>
          <Text capitalize={capitalize} variant={textVariant} i18nKey={labelI18nKey} style={txtStyles}>
            {label}
          </Text>
          {arrow}
        </TouchableOpacity>
      )}
    </View>
  )
}

export const BUTTON_HEIGHT = SPACING_BASE * 8

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: BUTTON_HEIGHT,
  },
  button: {
    minWidth: 'auto',
    backgroundColor: COLORS.TRANSPARENT,
    paddingHorizontal: SPACING_BASE,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  txtDisabled: {
    color: COLORS.GREY5,
  },
  text: {
    color: COLORS.BLACK,
  },
  txtSecondary: {
    color: COLORS.ORANGE,
  },
  arrow: {
    marginLeft: SPACING_BASE,
  },
})
