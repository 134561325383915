import React, { useMemo } from 'react'
import { Text as RNText, Animated, TextProps as RNTextProps } from 'react-native'

import { localizeString } from '../../localization/localize'
import { TEXT_STYLES, COLORS, TextVariant } from '../../styles/global-styles'

type TextStyleProps = {
  bold?: boolean
  size?: number
  color?: string | null | undefined
  animated?: boolean
  center?: boolean
  right?: boolean
  uppercase?: boolean
  capitalize?: boolean
  i18nKey?: I18nKey | null | undefined
  variant?: TextVariant
  numberOfLines?: number
  ellipsizeMode?: string
}

export type TextChild = Child | string | number

export type TextProps = TextStyleProps &
  Omit<RNTextProps, 'style'> & {
    testID?: string | null
    children?: TextChild | TextChild[]
    onPress?: () => any
    style?: StyleProp
  }

export default function Text(props: TextProps) {
  const {
    children,
    bold,
    uppercase,
    size,
    color,
    style,
    center,
    i18nKey,
    right,
    variant,
    animated,
    capitalize,
    ...other
  } = props

  const textStyles = useMemo(() => {
    let propsStyles: StyleProp = {
      color: color || COLORS.BLACK,
    }

    if (bold) {
      propsStyles = { ...propsStyles, fontWeight: 'bold' }
    }

    if (size) {
      propsStyles = { ...propsStyles, fontSize: size }
    }

    if (uppercase) {
      propsStyles = { ...propsStyles, textTransform: 'uppercase' }
    }

    if (center) {
      propsStyles = { ...propsStyles, textAlign: 'center' }
    }

    if (right) {
      propsStyles = { ...propsStyles, textAlign: 'right' }
    }

    if (capitalize) {
      propsStyles = { ...propsStyles, textTransform: 'capitalize' }
    }

    return propsStyles
  }, [bold, uppercase, size, color, center, right, capitalize])

  const localizedText = i18nKey ? localizeString(i18nKey) : null

  const textProps = {
    style: [TEXT_STYLES[variant || 'BODY_TEXT_REGULAR'], textStyles, style],
    ...other,
    children: [localizedText, children],
  }

  if (animated) {
    return <Animated.Text {...textProps} allowFontScaling={false} />
  }

  return <RNText {...textProps} allowFontScaling={false} />
}
