export default {
  TRANSPARENT: 'transparent',
  LIGHT_GREY_EE: '#EEEEEE',
  LIGHT_GREY_DF: '#DFDFDF',
  MED_GREY_97: '#979797',
  MED_GREY_BLUE: '#505D68',
  LIGHT_GREEN: '#9CE2D3',
  DARK_GREEN: '#55CDB4',
  RED: '#FA3838',
  GOLD: '#ECCD2C',
  RED_DARK: '#8F1012',
  DISABLED_GREY: '#4C4C4C',
  DISABLED_GREY_BACKGROUND: '#181818',
  DISABLED_ORANGE: 'rgba(255,83,59,0.3)',

  BLACK: '#000000',
  WHITE: '#FFFFFF',
  GREY0: '#141414',
  GREY1: '#202020',
  GREY2: '#333333',
  GREY3: '#4F4F4F',
  GREY4: '#757575',
  GREY5: '#BDBDBD',
  GREY6: '#E5E5E5',
  GREY7: '#F2F2F2',
  GREY8: '#F7F7F7',
  GREY9: '#FBFBFB',
  GREY_FA: '#FAFAFA',
  ORANGE: '#FF533B',
  DARK_ORANGE: '#4C1912',
  BLUE: '#06FDFD',
  PURPLE: '#6E1EF0',
  GREEN: '#00F872',
  YELLOW: '#FFD600',
  YELLOW_SECONDARY: '#DD9200',
  RED_ALERT: '#E31717',
  GREEN_ALERT: '#3EBA25',
  BLUE_TEXT_BUTTON: '#007AFF',
  TRANSPARENT_WHITE: 'rgba(255,255,255,0.5)',
  SEMI_TRANSPARENT_WHITE_80: 'rgba(255,255,255,0.8)',
  SEMI_TRANSPARENT_WHITE_20: 'rgba(255,255,255,0.2)',
  SEMI_TRANSPARENT_BLACK_80: 'rgba(0,0,0,0.8)',
  SEMI_TRANSPARENT_BLACK_60: 'rgba(0,0,0,0.6)',
  SEMI_TRANSPARENT_BLACK_20: 'rgba(0,0,0,0.2)',
  TERTIARY_GREEN: '#00A34B',
  TERTIARY_ORANGE: '#DF4934',
  TERTIARY_BLUE: '#01B1B1',
  GREEN3: '#007C39',
  DARKEST_GREY: '#171717',
  GREY_VIDEO: '#232223',

  REWARDS_PLATINUM: '#C8B9A4',
}
