import * as Font from 'expo-font'

export default async () => {
  return await Font.loadAsync({
    'brown-ll-regular': require('../assets/fonts/brownLL-regular.otf'),
    'helvetica-new-text-bold': require('../assets/fonts/helvetica-new-text-bold.otf'),
    'helvetica-new-text-extrabold': require('../assets/fonts/helvetica-new-text-extrabold.otf'),
    'helvetica-new-micro-normal': require('../assets/fonts/helvetica-new-micro-normal.otf'),
    'helvetica-new-micro-light': require('../assets/fonts/helvetica-new-micro-light.otf'),
    'helvetica-new-micro-medium': require('../assets/fonts/helvetica-new-micro-medium.otf'),
    'helvetica-new-micro-bold': require('../assets/fonts/helvetica-new-micro-bold.otf'),
    'helvetica-new-micro-extrabold': require('../assets/fonts/helvetica-new-micro-extrabold.otf'),
    'helvetica-new-micro-italic': require('../assets/fonts/helvetica-new-micro-italic.otf'),
    'helvetica-now-text-regular': require('../assets/fonts/helvetica-now-text-regular.otf'),
    syne: require('../assets/fonts/syne-bold.otf'),
  })
}
