// eslint-disable-next-line no-unused-vars
import process from 'process'
import buffer from 'buffer'
global.Buffer = buffer.Buffer

import { AppRegistry } from 'react-native'
import App from './src/App'

AppRegistry.registerComponent('Hightop', () => App)

const rootTag =
  document.getElementById('root') || document.getElementById('main')
AppRegistry.runApplication('Hightop', { rootTag })
