import React from 'react'

import Lottie from 'lottie-react-web'

type Props = {
  data: {
    [key: string]: any
  }
  autoplay?: boolean
  loop?: boolean
  onFinish?: () => any
}

const App = (props: Props) => (
  <Lottie
    options={{
      animationData: props.data,
      loop: props.loop,
      autoplay: props.autoplay,
    }}
    eventListeners={
      props.onFinish
        ? [
            {
              eventName: 'complete',
              callback: props.onFinish,
            },
          ]
        : []
    }
  />
)

export default App
