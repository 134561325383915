export default class EventEmitter {
  _count = 0
  _listeners: Record<
    string,
    {
      name: string
      callback: (data?: any) => any
    }
  > = {}

  addListener(eventName: string, callback: () => any) {
    this._count++
    const eventId = 'l' + this._count
    this._listeners[eventId] = {
      name: eventName,
      callback,
    }

    return () => {
      this.removeListener(eventId)
    }
  }

  removeListener(id: string) {
    if (this._listeners[id]) {
      delete this._listeners[id]
    }
  }

  on(eventName: string, callback: (data?: any) => any) {
    return this.addListener(eventName, callback)
  }

  emit(eventName: string, data?: Record<string, any>) {
    Object.keys(this._listeners).forEach((id) => {
      if (this._listeners[id] && eventName === this._listeners[id].name) {
        this._listeners[id].callback(data)
      }
    })
  }
}
