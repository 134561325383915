/*eslint sort-keys: ["error", "asc", {"caseSensitive": false, "natural": false}]*/

import AccountFrozen from '../../assets/icons/account-frozen.svg'
import AchDeposit from '../../assets/icons/ach-deposit.svg'
import AchWithdrawal from '../../assets/icons/ach-withdrawal.svg'
import Activity from '../../assets/icons/activity.svg'
import AddressBookLarge from '../../assets/icons/address-book-large.svg'
import AddressBook from '../../assets/icons/address-book.svg'
import AiDocumentDarkBlue from '../../assets/icons/ai-document-dark-blue.svg'
import AndroidMini from '../../assets/icons/android-mini.svg'
import APayActivity from '../../assets/icons/apay-activity.svg'
import AppleMini from '../../assets/icons/apple-mini.svg'
import ApplePay from '../../assets/icons/applePay.svg'
import ArrowBackWhite from '../../assets/icons/arrow-back-white.svg'
import ArrowBack from '../../assets/icons/arrow-back.svg'
import ArrowDownThick from '../../assets/icons/arrow-down-thick.svg'
import ArrowDownTransfer from '../../assets/icons/arrow-down-transfer.svg'
import ArrowDown from '../../assets/icons/arrow-down.svg'
import ArrowForward from '../../assets/icons/arrow-forward.svg'
import ArrowLeft from '../../assets/icons/arrow-left.svg'
import ArrowRightBlack from '../../assets/icons/arrow-right-black.svg'
import ArrowRight from '../../assets/icons/arrow-right.svg'
import ArrowsTripleUp from '../../assets/icons/arrows-triple-up.svg'
import BitcoinSymbolLight from '../../assets/icons/asset-symbol-bitcoin-light.svg'
import BitcoinSymbol from '../../assets/icons/asset-symbol-bitcoin.svg'
import DaiSymbolLight from '../../assets/icons/asset-symbol-dai-light.svg'
import DaiSymbol from '../../assets/icons/asset-symbol-dai.svg'
import EthSymbolLight from '../../assets/icons/asset-symbol-eth-light.svg'
import EthSymbol from '../../assets/icons/asset-symbol-eth.svg'
import SavingsAccountLight from '../../assets/icons/asset-symbol-savings-account-light.svg'
import SavingsAccount from '../../assets/icons/asset-symbol-savings-account.svg'
import TetherSymbolLight from '../../assets/icons/asset-symbol-tether-light.svg'
import TetherSymbol from '../../assets/icons/asset-symbol-tether.svg'
import USDSymbolLight from '../../assets/icons/asset-symbol-usd-light.svg'
import USDSymbol from '../../assets/icons/asset-symbol-usd.svg'
import USDCSymbolLight from '../../assets/icons/asset-symbol-usdc-light.svg'
import USDCSymbol from '../../assets/icons/asset-symbol-usdc.svg'
import AttachmentFile from '../../assets/icons/attachment-file.svg'
import AttachmentVideo from '../../assets/icons/attachment-video.svg'
import Awaiting from '../../assets/icons/awaiting.svg'
import Backspace from '../../assets/icons/backspace.svg'
import BankDepositReceived from '../../assets/icons/bank-deposit-received.svg'
import BankWithdrawal from '../../assets/icons/bank-withdrawal.svg'
import BellDark from '../../assets/icons/bell-dark.svg'
import Bell from '../../assets/icons/bell.svg'
import BigArrowLeft from '../../assets/icons/big-arrow-left.svg'
import BigArrowRight from '../../assets/icons/big-arrow-right.svg'
import BigBank from '../../assets/icons/big-bank.svg'
import BigCard from '../../assets/icons/big-card.svg'
import BitcoinRewards from '../../assets/icons/bitcoin-rewards.svg'
import BlueBank from '../../assets/icons/blue-bank.svg'
import Borrow from '../../assets/icons/borrow.svg'
import Calculator from '../../assets/icons/calculator.svg'
import CardAmex from '../../assets/icons/card-amex.svg'
import CardDepositFees from '../../assets/icons/card-deposit-fees.svg'
import CardDeposit from '../../assets/icons/card-deposit.svg'
import CardMastercardGrey from '../../assets/icons/card-mastercard-grey.svg'
import CardMastercard from '../../assets/icons/card-mastercard.svg'
import CardSettings from '../../assets/icons/card-settings.svg'
import CardVisa from '../../assets/icons/card-visa.svg'
import CashIconBig from '../../assets/icons/cash-icon-big.svg'
import Checkbox from '../../assets/icons/check-box.svg'
import Check from '../../assets/icons/check.svg'
import CircleChecked from '../../assets/icons/circle-checked.svg'
import Circle from '../../assets/icons/circle.svg'
import CloseBanner from '../../assets/icons/close-banner.svg'
import ColoredCard from '../../assets/icons/colored-card.svg'
import CompleteCheckWhite from '../../assets/icons/complete-check-white.svg'
import CompleteCheck from '../../assets/icons/complete-check.svg'
import CompleteCrossWhite from '../../assets/icons/complete-cross-white.svg'
import CompleteCross from '../../assets/icons/complete-cross.svg'
import Congratulations from '../../assets/icons/congratulations.svg'
import CrossThin from '../../assets/icons/cross-thin.svg'
import CrossWhite from '../../assets/icons/cross-white.svg'
import Cross from '../../assets/icons/cross.svg'
import CryptoWithdrawal from '../../assets/icons/crypto-withdrawal.svg'
import DepositReceivedBTC from '../../assets/icons/deposit-received-btc.svg'
import DepositReceivedDAI from '../../assets/icons/deposit-received-dai.svg'
import DepositReceivedETH from '../../assets/icons/deposit-received-eth.svg'
import DepositReceivedUSDC from '../../assets/icons/deposit-received-usdc.svg'
import DepositReceivedUSDT from '../../assets/icons/deposit-received-usdt.svg'
import DepositReceived from '../../assets/icons/deposit-received.svg'
import DepositSelectMethod from '../../assets/icons/deposit-select-method.svg'
import DollarSign from '../../assets/icons/dollar-sign.svg'
import Driver from '../../assets/icons/driver.svg'
import EarnCoins from '../../assets/icons/earn-coins.svg'
import EarnGraph from '../../assets/icons/earn-graph.svg'
import EmailLight from '../../assets/icons/email-light.svg'
import Email from '../../assets/icons/email.svg'
import Envelope from '../../assets/icons/envelope.svg'
import EthIcon from '../../assets/icons/eth-icon.svg'
import ExclamationMark from '../../assets/icons/exclamation-mark.svg'
import ExpandDownDark from '../../assets/icons/expand-down-dark.svg'
import EyeCard from '../../assets/icons/eye-card.svg'
import EyeCrossed from '../../assets/icons/eye-crossed.svg'
import Eye from '../../assets/icons/eye.svg'
import FeeAdded from '../../assets/icons/fee-added.svg'
import Filter from '../../assets/icons/filter.svg'
import FixedRateArrow from '../../assets/icons/fixed-rate-arrow.svg'
import Flip from '../../assets/icons/flip.svg'
import Frozen from '../../assets/icons/frozen.svg'
import GameBTC from '../../assets/icons/game-btc.svg'
import GameCash from '../../assets/icons/game-cash.svg'
import GameControllerLight from '../../assets/icons/game-controller-light.svg'
import GameController from '../../assets/icons/game-controller.svg'
import GameDAI from '../../assets/icons/game-dai.svg'
import GameETH from '../../assets/icons/game-eth.svg'
import GameExplode from '../../assets/icons/game-explode.svg'
import GameTether from '../../assets/icons/game-tether.svg'
import GameUSDC from '../../assets/icons/game-usdc.svg'
import GBlock from '../../assets/icons/gblock.svg'
import GoldenTickets0 from '../../assets/icons/golden-ticket-0.svg'
import GoldenTicketEmail from '../../assets/icons/golden-ticket-email.svg'
import GoldenTicketRedeemed from '../../assets/icons/golden-ticket-redeemed.svg'
import GoldenTicket from '../../assets/icons/golden-ticket.svg'
import GreenCheck from '../../assets/icons/green-check.svg'
import Help from '../../assets/icons/help.svg'
import HightopLogo from '../../assets/icons/hightop-logo.svg'
import IconShadow from '../../assets/icons/icon-shadow.svg'
import IdCardDarkBlue from '../../assets/icons/id-card-dark-blue.svg'
import IdCardDark from '../../assets/icons/id-card-dark.svg'
import IdCardLight from '../../assets/icons/id-card-light.svg'
import IdCardOrange from '../../assets/icons/id-card-orange.svg'
import IdCardWhite from '../../assets/icons/id-card-white.svg'
import IdCircleDarkBlue from '../../assets/icons/id-circle-dark-blue.svg'
import Info from '../../assets/icons/info.svg'
import InternalTransfer from '../../assets/icons/internal-transfer.svg'
import IntroBank from '../../assets/icons/intro-bank.svg'
import IntroBorrow from '../../assets/icons/intro-borrow.svg'
import IntroEarn from '../../assets/icons/intro-earn.svg'
import IntroSpend from '../../assets/icons/intro-spend.svg'
import InvitePlusDashboard from '../../assets/icons/invite-plus-dashboard.svg'
import InvitePlusLight from '../../assets/icons/invite-plus-light.svg'
import InvitePlus from '../../assets/icons/invite-plus.svg'
import KYCDocDriversLicense from '../../assets/icons/kyc-doc-drivers-license.svg'
import KYCDocIdCard from '../../assets/icons/kyc-doc-id-card.svg'
import KYCDocPassport from '../../assets/icons/kyc-doc-passport.svg'
import KYCDocResidencePermit from '../../assets/icons/kyc-doc-residence-permit.svg'
import KYCHome from '../../assets/icons/kyc-home.svg'
import KYCListItem from '../../assets/icons/kyc-list-item.svg'
import KYCPerson from '../../assets/icons/kyc-person.svg'
import LargeGreaterThan from '../../assets/icons/large-greater-than.svg'
import Liquidation from '../../assets/icons/liquidation.svg'
import ListIcon from '../../assets/icons/list-icon.svg'
import LoanIcon0 from '../../assets/icons/loan-icon-0.svg'
import LoanIcon1 from '../../assets/icons/loan-icon-1.svg'
import LoanIcon10 from '../../assets/icons/loan-icon-10.svg'
import LoanIcon11 from '../../assets/icons/loan-icon-11.svg'
import LoanIcon2 from '../../assets/icons/loan-icon-2.svg'
import LoanIcon3 from '../../assets/icons/loan-icon-3.svg'
import LoanIcon4 from '../../assets/icons/loan-icon-4.svg'
import LoanIcon5 from '../../assets/icons/loan-icon-5.svg'
import LoanIcon6 from '../../assets/icons/loan-icon-6.svg'
import LoanIcon7 from '../../assets/icons/loan-icon-7.svg'
import LoanIcon8 from '../../assets/icons/loan-icon-8.svg'
import LoanIcon9 from '../../assets/icons/loan-icon-9.svg'
import Lock from '../../assets/icons/lock.svg'
import Login from '../../assets/icons/login.svg'
import Logo from '../../assets/icons/logo.svg'
import Menu from '../../assets/icons/menu.svg'
import NoConnectionLarge from '../../assets/icons/no-connection-large.svg'
import NoConnection from '../../assets/icons/no-connection.svg'
import NotificationsBell from '../../assets/icons/notifications-bell.svg'
import OnboardBank from '../../assets/icons/onboard-bank.svg'
import OnboardBorrow from '../../assets/icons/onboard-borrow.svg'
import OnboardEarn from '../../assets/icons/onboard-earn.svg'
import OnboardSpend from '../../assets/icons/onboard-spend.svg'
import OrangeBank from '../../assets/icons/orange-bank.svg'
import HintArrow from '../../assets/icons/orange-hint-arrow.svg'
import Padlock from '../../assets/icons/padlock.svg'
import Passport from '../../assets/icons/passport.svg'
import PasswordDots from '../../assets/icons/password-dots.svg'
import PasswordLock from '../../assets/icons/password-lock.svg'
import Pending from '../../assets/icons/pending.svg'
import PhoneNumber from '../../assets/icons/phone-number.svg'
import PlusCircle from '../../assets/icons/plus-circle.svg'
import Plus from '../../assets/icons/plus.svg'
import Privacy from '../../assets/icons/privacy.svg'
import Profile from '../../assets/icons/profile.svg'
import QRCode from '../../assets/icons/qrcode.svg'
import RequestAccessKey from '../../assets/icons/request-access-key.svg'
import RequestAccessPadlock from '../../assets/icons/request-access-padlock.svg'
import Reset from '../../assets/icons/reset.svg'
import RewardAccountApproved from '../../assets/icons/reward-account-approved.svg'
import RewardBalanceReached from '../../assets/icons/reward-balance-reached.svg'
import RewardEarn from '../../assets/icons/reward-earn.svg'
import RewardInvite from '../../assets/icons/reward-invite.svg'
import RewardReferralApproved from '../../assets/icons/reward-referral-approved.svg'
import RewardSignUp from '../../assets/icons/reward-sign-up.svg'
import RewardSpend from '../../assets/icons/reward-spend.svg'
import RewardsCardClassic from '../../assets/icons/rewards-card-classic.svg'
import RewardsCardPlatinum from '../../assets/icons/rewards-card-platinum.svg'
import RewardsCardPremium from '../../assets/icons/rewards-card-premium.svg'
import RewardsCheck from '../../assets/icons/rewards-check.svg'
import RewardsCircleBasicBlack from '../../assets/icons/rewards-circle-basic-black.svg'
import RewardsCircleBasic from '../../assets/icons/rewards-circle-basic.svg'
import RewardsCircleInnerShadow from '../../assets/icons/rewards-circle-inner-shadow.svg'
import RewardsCirclePremium from '../../assets/icons/rewards-circle-premium.svg'
import RewardsCircleShadow from '../../assets/icons/rewards-circle-shadow.svg'
import RewardsClassicIconBlack from '../../assets/icons/rewards-classic-square-black.svg'
import RewardsClassicIconGrey from '../../assets/icons/rewards-classic-square-grey.svg'
import RewardsDepositClassic from '../../assets/icons/rewards-deposit-classic.svg'
import RewardsDepositPlatinum from '../../assets/icons/rewards-deposit-platinum.svg'
import RewardsDepositPremium from '../../assets/icons/rewards-deposit-premium.svg'
import RewardsEarnClassic from '../../assets/icons/rewards-earn-classic.svg'
import RewardsEarnPlatinum from '../../assets/icons/rewards-earn-platinum.svg'
import RewardsEarnPremium from '../../assets/icons/rewards-earn-premium.svg'
import RewardsGoldenTicketClassic from '../../assets/icons/rewards-goldenTicket-classic.svg'
import RewardsIconClassic from '../../assets/icons/rewards-icon-classic.svg'
import RewardsIconPlatinum from '../../assets/icons/rewards-icon-platinum.svg'
import RewardsIconPremium from '../../assets/icons/rewards-icon-premium.svg'
import RewardsInnerCircleBasicDashboard from '../../assets/icons/rewards-inner-circle-basic-dashboard.svg'
import RewardsInnerCircleBasic from '../../assets/icons/rewards-inner-circle-basic.svg'
import RewardsInnerCirclePremium from '../../assets/icons/rewards-inner-circle-premium.svg'
import RewardsInviteClassic from '../../assets/icons/rewards-invite-classic.svg'
import RewardsInvitePlatinum from '../../assets/icons/rewards-invite-platinum.svg'
import RewardsInvitePremium from '../../assets/icons/rewards-invite-premium.svg'
import RewardsLabelBGPlatinum from '../../assets/icons/rewards-label-bg-platinum.svg'
import RewardsLabelBGPremium from '../../assets/icons/rewards-label-bg-premium.svg'
import RewardsPlatinumIconBlack from '../../assets/icons/rewards-platinum-diamond-black.svg'
import RewardsPlatinumIconGrey from '../../assets/icons/rewards-platinum-diamond-grey.svg'
import RewardsPointsClassic from '../../assets/icons/rewards-points-classic.svg'
import RewardsPointsPlatinum from '../../assets/icons/rewards-points-platinum.svg'
import RewardsPointsPremium from '../../assets/icons/rewards-points-premium.svg'
import RewardsPremiumIconBlack from '../../assets/icons/rewards-premium-star-black.svg'
import RewardsPremiumIconGrey from '../../assets/icons/rewards-premium-star-grey.svg'
import RewardsRedeemTransaction from '../../assets/icons/rewards-redeem-transaction.svg'
import RewardsSpendClassic from '../../assets/icons/rewards-spend-classic.svg'
import RewardsSpendPlatinum from '../../assets/icons/rewards-spend-platinum.svg'
import RewardsSpendPremium from '../../assets/icons/rewards-spend-premium.svg'
import RewardsPlatinumStar from '../../assets/icons/rewards-star.svg'
import Search from '../../assets/icons/search.svg'
import SecurityShieldBlue from '../../assets/icons/security-shield-blue.svg'
import SecurityShieldDarkBlue from '../../assets/icons/security-shield-dark-blue.svg'
import SecurityShield from '../../assets/icons/security-shield.svg'
import SelfieLines from '../../assets/icons/selfie-lines.svg'
import SelfiePhone from '../../assets/icons/selfie-phone.svg'
import Selfie from '../../assets/icons/selfie.svg'
import Share from '../../assets/icons/share.svg'
import ShieldBiometric from '../../assets/icons/shield-biometric.svg'
import SmallCard from '../../assets/icons/small-card.svg'
import SmallGreaterThanGrey from '../../assets/icons/small-greater-than-grey.svg'
import SmallGreaterThan from '../../assets/icons/small-greater-than.svg'
import SmartBannerLogo from '../../assets/icons/smart-banner-logo.svg'
import StarOrange from '../../assets/icons/star-orange.svg'
import SwitchArrows from '../../assets/icons/switch-arrows.svg'
import SwitchVerticalArrows from '../../assets/icons/switch-vertical-arrows-transfer.svg'
import Terms from '../../assets/icons/terms.svg'
import ThreeDots from '../../assets/icons/three-dots.svg'
import TopTriangleWide from '../../assets/icons/top-triangle-wide.svg'
import TopTriangle from '../../assets/icons/top-triangle.svg'
import TOTPShieldLight from '../../assets/icons/totp-shield-light.svg'
import TOTPShield from '../../assets/icons/totp-shield.svg'
import TransactionCategoryEntertainment from '../../assets/icons/transaction-category-entertainment.svg'
import TransactionCategoryFoodDrink from '../../assets/icons/transaction-category-food-drink.svg'
import TransactionCategoryGeneral from '../../assets/icons/transaction-category-general.svg'
import TransactionCategoryHealth from '../../assets/icons/transaction-category-health.svg'
import TransactionCategoryServices from '../../assets/icons/transaction-category-services.svg'
import TransactionCategoryShopping from '../../assets/icons/transaction-category-shopping.svg'
import TransactionCategoryTransportation from '../../assets/icons/transaction-category-transportation.svg'
import TransactionCategoryTravel from '../../assets/icons/transaction-category-travel.svg'
import TwoFA from '../../assets/icons/two-fa.svg'
import Upgrade from '../../assets/icons/upgrade.svg'
import USDollarDarkBlue from '../../assets/icons/us-dollar-dark-blue.svg'
import USOnly from '../../assets/icons/us-only.svg'
import USDIcon from '../../assets/icons/usd-icon.svg'
import USDCIcon from '../../assets/icons/usdc-icon.svg'
import VariableRateArrow from '../../assets/icons/variable-rate-arrow.svg'
import WaitlistDownArrow from '../../assets/icons/waitlist-down-arrow.svg'
import WaitlistUpArrow from '../../assets/icons/waitlist-up-arrow.svg'
import WarningLarge from '../../assets/icons/warning-large.svg'
import WarningSmall from '../../assets/icons/warning-small.svg'
import Warning from '../../assets/icons/warning.svg'
import WebLogo from '../../assets/icons/web-logo.svg'
import Whale from '../../assets/icons/whale.svg'
import WithdrawActivity from '../../assets/icons/withdraw-activity.svg'
import WithdrawBTC from '../../assets/icons/withdraw-btc.svg'
import WithdrawDAI from '../../assets/icons/withdraw-dai.svg'
import WithdrawETH from '../../assets/icons/withdraw-eth.svg'
import WithdrawRejectedBTC from '../../assets/icons/withdraw-rejected-btc.svg'
import WithdrawRejectedDAI from '../../assets/icons/withdraw-rejected-dai.svg'
import WithdrawRejectedETH from '../../assets/icons/withdraw-rejected-eth.svg'
import WithdrawRejectedUSDC from '../../assets/icons/withdraw-rejected-usdc.svg'
import WithdrawRejectedUSDT from '../../assets/icons/withdraw-rejected-usdt.svg'
import WithdrawUSDC from '../../assets/icons/withdraw-usdc.svg'
import WithdrawUSDT from '../../assets/icons/withdraw-usdt.svg'
import Withdraw from '../../assets/icons/withdraw.svg'
import WithdrawalWallet from '../../assets/icons/withdrawal-wallet.svg'

// Please always sort the keys
export default {
  accountFrozen: AccountFrozen,
  achDeposit: AchDeposit,
  achWithdrawal: AchWithdrawal,
  activity: Activity,
  addressBook: AddressBook,
  addressBookLarge: AddressBookLarge,
  aiDocumentDarkBlue: AiDocumentDarkBlue,
  androidMini: AndroidMini,
  apayActivity: APayActivity,
  appleMini: AppleMini,
  applePay: ApplePay,
  arrowBack: ArrowBack,
  arrowBackWhite: ArrowBackWhite,
  arrowDown: ArrowDown,
  arrowDownThick: ArrowDownThick,
  arrowDownTransfer: ArrowDownTransfer,
  arrowForward: ArrowForward,
  arrowLeft: ArrowLeft,
  arrowRight: ArrowRight,
  arrowRightBlack: ArrowRightBlack,
  arrowsTripleUp: ArrowsTripleUp,
  attachmentFile: AttachmentFile,
  attachmentVideo: AttachmentVideo,
  awaiting: Awaiting,
  backspace: Backspace,
  bankDepositReceived: BankDepositReceived,
  bankWithdrawal: BankWithdrawal,
  bell: Bell,
  bellDark: BellDark,
  bigArrowLeft: BigArrowLeft,
  bigArrowRight: BigArrowRight,
  bigBank: BigBank,
  bigCard: BigCard,
  bitcoinRewards: BitcoinRewards,
  bitcoinSymbol: BitcoinSymbol,
  bitcoinSymbolLight: BitcoinSymbolLight,
  blueBank: BlueBank,
  borrow: Borrow,
  calculator: Calculator,
  cardAmex: CardAmex,
  cardDeposit: CardDeposit,
  cardDepositFees: CardDepositFees,
  cardMastercard: CardMastercard,
  cardMastercardGrey: CardMastercardGrey,
  cardSettings: CardSettings,
  cardVisa: CardVisa,
  cashIconBig: CashIconBig,
  check: Check,
  checkbox: Checkbox,
  circle: Circle,
  circleChecked: CircleChecked,
  closeBanner: CloseBanner,
  coloredCard: ColoredCard,
  completeCheck: CompleteCheck,
  completeCheckWhite: CompleteCheckWhite,
  completeCross: CompleteCross,
  completeCrossWhite: CompleteCrossWhite,
  congratulations: Congratulations,
  cross: Cross,
  crossThin: CrossThin,
  crossWhite: CrossWhite,
  cryptoWithdrawal: CryptoWithdrawal,
  daiSymbol: DaiSymbol,
  daiSymbolLight: DaiSymbolLight,
  depositReceived: DepositReceived,
  depositReceivedBTC: DepositReceivedBTC,
  depositReceivedDAI: DepositReceivedDAI,
  depositReceivedETH: DepositReceivedETH,
  depositReceivedUSDC: DepositReceivedUSDC,
  depositReceivedUSDT: DepositReceivedUSDT,
  depositSelectMethod: DepositSelectMethod,
  dollarSign: DollarSign,
  driver: Driver,
  earnCoins: EarnCoins,
  earnGraph: EarnGraph,
  email: Email,
  emailLight: EmailLight,
  envelope: Envelope,
  ethIcon: EthIcon,
  ethSymbol: EthSymbol,
  ethSymbolLight: EthSymbolLight,
  exclamationMark: ExclamationMark,
  expandDownDark: ExpandDownDark,
  eye: Eye,
  eyeCard: EyeCard,
  eyeCrossed: EyeCrossed,
  feeAdded: FeeAdded,
  filter: Filter,
  fixedRateArrow: FixedRateArrow,
  flip: Flip,
  frozen: Frozen,
  gameBTC: GameBTC,
  gameCash: GameCash,
  gameController: GameController,
  gameControllerLight: GameControllerLight,
  gameDAI: GameDAI,
  gameETH: GameETH,
  gameExplode: GameExplode,
  gameTether: GameTether,
  gameUSDC: GameUSDC,
  gblock: GBlock,
  goldenTicket: GoldenTicket,
  goldenTicketEmail: GoldenTicketEmail,
  goldenTicketRedeemed: GoldenTicketRedeemed,
  goldenTickets0: GoldenTickets0,
  greenCheck: GreenCheck,
  help: Help,
  hightopLogo: HightopLogo,
  hintArrow: HintArrow,
  iconShadow: IconShadow,
  idCardDark: IdCardDark,
  idCardDarkBlue: IdCardDarkBlue,
  idCardLight: IdCardLight,
  idCardOrange: IdCardOrange,
  idCardWhite: IdCardWhite,
  idCircleDarkBlue: IdCircleDarkBlue,
  info: Info,
  internalTransfer: InternalTransfer,
  introBank: IntroBank,
  introBorrow: IntroBorrow,
  introEarn: IntroEarn,
  introSpend: IntroSpend,
  invitePlus: InvitePlus,
  invitePlusDashboard: InvitePlusDashboard,
  invitePlusLight: InvitePlusLight,
  kycDocDriversLicense: KYCDocDriversLicense,
  kycDocIdCard: KYCDocIdCard,
  kycDocPassport: KYCDocPassport,
  kycDocResidencePermit: KYCDocResidencePermit,
  KYCHome,
  KYCListItem,
  KYCPerson,
  largeGreaterThan: LargeGreaterThan,
  liquidation: Liquidation,
  listIcon: ListIcon,
  loanIcon0: LoanIcon0,
  loanIcon1: LoanIcon1,
  loanIcon10: LoanIcon10,
  loanIcon11: LoanIcon11,
  loanIcon2: LoanIcon2,
  loanIcon3: LoanIcon3,
  loanIcon4: LoanIcon4,
  loanIcon5: LoanIcon5,
  loanIcon6: LoanIcon6,
  loanIcon7: LoanIcon7,
  loanIcon8: LoanIcon8,
  loanIcon9: LoanIcon9,
  lock: Lock,
  login: Login,
  logo: Logo,
  menu: Menu,
  noConnection: NoConnection,
  noConnectionLarge: NoConnectionLarge,
  notificationsBell: NotificationsBell,
  onboardBank: OnboardBank,
  onboardBorrow: OnboardBorrow,
  onboardEarn: OnboardEarn,
  onboardSpend: OnboardSpend,
  orangeBank: OrangeBank,
  padlock: Padlock,
  passport: Passport,
  passwordDots: PasswordDots,
  passwordLock: PasswordLock,
  pending: Pending,
  phoneNumber: PhoneNumber,
  plus: Plus,
  plusCircle: PlusCircle,
  privacy: Privacy,
  profile: Profile,
  qrCode: QRCode,
  requestAccessKey: RequestAccessKey,
  requestAccessPadlock: RequestAccessPadlock,
  reset: Reset,
  rewardAccountApproved: RewardAccountApproved,
  rewardBalanceReached: RewardBalanceReached,
  rewardEarn: RewardEarn,
  rewardInvite: RewardInvite,
  rewardReferralApproved: RewardReferralApproved,
  rewardsCardClassic: RewardsCardClassic,
  rewardsCardPlatinum: RewardsCardPlatinum,
  rewardsCardPremium: RewardsCardPremium,
  rewardsCheck: RewardsCheck,
  rewardsCircleBasic: RewardsCircleBasic,
  rewardsCircleBasicBlack: RewardsCircleBasicBlack,
  rewardsCircleInnerShadow: RewardsCircleInnerShadow,
  rewardsCirclePremium: RewardsCirclePremium,
  rewardsCircleShadow: RewardsCircleShadow,
  rewardsClassicIconBlack: RewardsClassicIconBlack,
  rewardsClassicIconGrey: RewardsClassicIconGrey,
  rewardsDepositClassic: RewardsDepositClassic,
  rewardsDepositPlatinum: RewardsDepositPlatinum,
  rewardsDepositPremium: RewardsDepositPremium,
  rewardsEarnClassic: RewardsEarnClassic,
  rewardsEarnPlatinum: RewardsEarnPlatinum,
  rewardsEarnPremium: RewardsEarnPremium,
  rewardsGoldenTicketClassic: RewardsGoldenTicketClassic,
  rewardsIconClassic: RewardsIconClassic,
  rewardsIconPlatinum: RewardsIconPlatinum,
  rewardsIconPremium: RewardsIconPremium,
  rewardSignUp: RewardSignUp,
  rewardsInnerCircleBasic: RewardsInnerCircleBasic,
  rewardsInnerCircleBasicDashboard: RewardsInnerCircleBasicDashboard,
  rewardsInnerCirclePremium: RewardsInnerCirclePremium,
  rewardsInviteClassic: RewardsInviteClassic,
  rewardsInvitePlatinum: RewardsInvitePlatinum,
  rewardsInvitePremium: RewardsInvitePremium,
  rewardsLabelBGPlatinum: RewardsLabelBGPlatinum,
  rewardsLabelBGPremium: RewardsLabelBGPremium,
  rewardSpend: RewardSpend,
  rewardsPlatinumIconBlack: RewardsPlatinumIconBlack,
  rewardsPlatinumIconGrey: RewardsPlatinumIconGrey,
  rewardsPlatinumStar: RewardsPlatinumStar,
  rewardsPointsClassic: RewardsPointsClassic,
  rewardsPointsPlatinum: RewardsPointsPlatinum,
  rewardsPointsPremium: RewardsPointsPremium,
  rewardsPremiumIconBlack: RewardsPremiumIconBlack,
  rewardsPremiumIconGrey: RewardsPremiumIconGrey,
  rewardsRedeemTransaction: RewardsRedeemTransaction,
  rewardsSpendClassic: RewardsSpendClassic,
  rewardsSpendPlatinum: RewardsSpendPlatinum,
  rewardsSpendPremium: RewardsSpendPremium,
  savingsAccount: SavingsAccount,
  savingsAccountLight: SavingsAccountLight,
  search: Search,
  securityShield: SecurityShield,
  securityShieldBlue: SecurityShieldBlue,
  securityShieldDarkBlue: SecurityShieldDarkBlue,
  selfie: Selfie,
  selfieLines: SelfieLines,
  selfiePhone: SelfiePhone,
  share: Share,
  shieldBiometric: ShieldBiometric,
  smallCard: SmallCard,
  smallGreaterThan: SmallGreaterThan,
  smallGreaterThanGrey: SmallGreaterThanGrey,
  smartBannerLogo: SmartBannerLogo,
  starOrange: StarOrange,
  switchArrows: SwitchArrows,
  switchVertcalArrows: SwitchVerticalArrows,
  terms: Terms,
  tetherSymbol: TetherSymbol,
  tetherSymbolLight: TetherSymbolLight,
  threeDots: ThreeDots,
  topTriangle: TopTriangle,
  topTriangleWide: TopTriangleWide,
  totpShield: TOTPShield,
  totpShieldLight: TOTPShieldLight,
  transactionCategoryEntertainment: TransactionCategoryEntertainment,
  transactionCategoryFoodDrink: TransactionCategoryFoodDrink,
  transactionCategoryGeneral: TransactionCategoryGeneral,
  transactionCategoryHealth: TransactionCategoryHealth,
  // per Clement Jaquier 2/1/22, we'll use the General icon for payments, for now
  transactionCategoryPayment: TransactionCategoryGeneral,
  transactionCategoryServices: TransactionCategoryServices,
  transactionCategoryShopping: TransactionCategoryShopping,
  transactionCategoryTransportation: TransactionCategoryTransportation,
  transactionCategoryTravel: TransactionCategoryTravel,
  twoFA: TwoFA,
  upgrade: Upgrade,
  usdcIcon: USDCIcon,
  usdcSymbol: USDCSymbol,
  usdcSymbolLight: USDCSymbolLight,
  usdIcon: USDIcon,
  usDollarDarkBlue: USDollarDarkBlue,
  usdSymbol: USDSymbol,
  usdSymbolLight: USDSymbolLight,
  usOnly: USOnly,
  variableRateArrow: VariableRateArrow,
  waitlistDownArrow: WaitlistDownArrow,
  waitlistUpArrow: WaitlistUpArrow,
  warning: Warning,
  warningLarge: WarningLarge,
  warningSmall: WarningSmall,
  webLogo: WebLogo,
  whale: Whale,
  withdraw: Withdraw,
  withdrawActivity: WithdrawActivity,
  withdrawalWallet: WithdrawalWallet,
  withdrawBTC: WithdrawBTC,
  withdrawDAI: WithdrawDAI,
  withdrawETH: WithdrawETH,
  withdrawRejectedBTC: WithdrawRejectedBTC,
  withdrawRejectedDAI: WithdrawRejectedDAI,
  withdrawRejectedETH: WithdrawRejectedETH,
  withdrawRejectedUSDC: WithdrawRejectedUSDC,
  withdrawRejectedUSDT: WithdrawRejectedUSDT,
  withdrawUSDC: WithdrawUSDC,
  withdrawUSDT: WithdrawUSDT,
}
