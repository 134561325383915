import { useState, useEffect, useCallback } from 'react'
import { AppState, Keyboard, Platform } from 'react-native'

import { useNavigation, useRoute } from '@react-navigation/native'

import { useAppContext } from '../components/core/Context'
import { localizeString } from '../localization/localize'

export { useAppErrorNotifier, useModalErrorNotifier } from './errors'

export type StatusType = 'PENDING' | 'LOADING' | 'COMPLETE' | 'ERROR'

export type Status = {
  state: StatusType
  message?: { value: string; isI18n?: boolean }
}

type UpdateStatus = (state: StatusType, message?: { value: string; isI18n?: boolean }) => void

export const getStatusMessage = (status: Status): string | null | undefined => {
  return status.message?.isI18n ? localizeString(status.message.value) : status.message?.value
}

export function useStatusState(initialStatus: Status = { state: 'PENDING' }): [Status, UpdateStatus] {
  const [status, setStatus] = useState<Status>(initialStatus)
  const updateStatus = (state: StatusType, message?: { value: string; isI18n?: boolean }) => {
    setStatus({ state, message })
  }
  return [status, updateStatus]
}

export function useAppState(callback: (state: string) => any) {
  const memoizedCallback = useCallback((state) => {
    callback(state)
  }, [])

  useEffect(() => {
    AppState.addEventListener('change', memoizedCallback)

    return () => {
      AppState.removeEventListener('change', memoizedCallback)
    }
  }, [memoizedCallback])
}

export function useAppStateValue() {
  const [state, setState] = useState('active')
  useEffect(() => {
    AppState.addEventListener('change', setState)

    return () => {
      AppState.removeEventListener('change', setState)
    }
  }, [])
  return state
}

export function useAppEventEmitter() {
  const context = useAppContext()
  return (eventName: EventType, params?: any) => {
    context.eventEmitter.emit(eventName, params)
  }
}

export function useAppEventListeners(
  events: EventType[] = [],
  callback: (params: any) => any,
  referencesArray: any[] = [],
) {
  const context = useAppContext()
  const memoizedCallback = useCallback((params) => {
    callback(params)
  }, referencesArray)

  useEffect(() => {
    const unsubs = events.map((e) => context.eventEmitter.on(e, memoizedCallback))

    return () => {
      unsubs.forEach((unsub) => unsub())
    }
  }, [memoizedCallback])
}

export const useFocusedScreen = () => {
  const navigation = useNavigation()
  const route = useRoute()
  const [screen, setScreen] = useState(route)

  useEffect(() => {
    const unsub = navigation.addListener('state', (event) => {
      setScreen(event.data.state.routes[event.data.state.routes.length - 1])
    })
    return () => {
      unsub && unsub()
    }
  }, [])

  return screen
}

export const SHOW_KEYBOARD_EVENT = Platform.OS === 'android' ? 'keyboardDidShow' : 'keyboardWillShow'
const HIDE_KEYBOARD_EVENT = Platform.OS === 'android' ? 'keyboardDidHide' : 'keyboardWillHide'

export const useKeyboardOpen = () => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    const showAbove = () => setOpen(true)
    const aboveHidden = () => setOpen(false)

    Keyboard.addListener(SHOW_KEYBOARD_EVENT, showAbove)
    Keyboard.addListener(HIDE_KEYBOARD_EVENT, aboveHidden)

    return () => {
      Keyboard.removeListener(SHOW_KEYBOARD_EVENT, showAbove)
      Keyboard.removeListener(HIDE_KEYBOARD_EVENT, aboveHidden)
    }
  }, [])

  return open
}
