/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AssetCode {
  BTC = "BTC",
  DAI = "DAI",
  ETH = "ETH",
  USD = "USD",
  USDC = "USDC",
  USDT = "USDT",
}

export enum AssetType {
  CRYPTO_CURRENCY = "CRYPTO_CURRENCY",
  CRYPTO_TOKEN = "CRYPTO_TOKEN",
  FIAT = "FIAT",
}

export enum AuthMethod {
  BIOMETRIC = "BIOMETRIC",
  EMAIL_CODE = "EMAIL_CODE",
  SMS_CODE = "SMS_CODE",
  TOTP_CODE = "TOTP_CODE",
}

export enum BalanceSubtype {
  CARD = "CARD",
  CRYPTO = "CRYPTO",
  SAVINGS = "SAVINGS",
}

export enum BankAccountTransactionState {
  ACKNOWLEDGED = "ACKNOWLEDGED",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
  INITIATED = "INITIATED",
  REVERSED = "REVERSED",
}

export enum BankAccountTransactionType {
  ACQUIRING_CHARGEBACK_FEE = "ACQUIRING_CHARGEBACK_FEE",
  ACQUIRING_DEPOSIT = "ACQUIRING_DEPOSIT",
  ACQUIRING_FEE = "ACQUIRING_FEE",
  ADMIN_ADJUSTMENT = "ADMIN_ADJUSTMENT",
  CARD = "CARD",
  EXTERNAL_ACH_DEPOSIT = "EXTERNAL_ACH_DEPOSIT",
  EXTERNAL_ACH_WITHDRAWAL = "EXTERNAL_ACH_WITHDRAWAL",
  INBOUND_WIRE = "INBOUND_WIRE",
  INTERNAL_ACH_DEPOSIT = "INTERNAL_ACH_DEPOSIT",
  INTERNAL_ACH_WITHDRAWAL = "INTERNAL_ACH_WITHDRAWAL",
  INTERNAL_DEPOSIT = "INTERNAL_DEPOSIT",
  INTERNAL_WITHDRAWAL = "INTERNAL_WITHDRAWAL",
  TRANSFER = "TRANSFER",
  WITHDRAWAL = "WITHDRAWAL",
}

export enum BankCardPlan {
  BASIC = "BASIC",
  PREMIUM = "PREMIUM",
}

export enum BankCardShippingStatus {
  DELIVERED = "DELIVERED",
  ORDERED = "ORDERED",
  PROCESSING = "PROCESSING",
  SHIPPED = "SHIPPED",
}

export enum BankCardStatus {
  ACTIVE = "ACTIVE",
  BLOCK = "BLOCK",
  CLOSED = "CLOSED",
  FRAUD_BLOCK = "FRAUD_BLOCK",
  NEW = "NEW",
  UNINITIALIZED = "UNINITIALIZED",
}

export enum BankCardType {
  PHYSICAL = "PHYSICAL",
  VIRTUAL = "VIRTUAL",
}

export enum CardIssueType {
  DAMAGED = "DAMAGED",
  EXPIRED = "EXPIRED",
  NEW = "NEW",
}

export enum CheckAuthCodeType {
  EMAIL = "EMAIL",
  SMS = "SMS",
}

export enum EvolveAchAccountStatus {
  EXPIRED = "EXPIRED",
  MANUALLY_VERIFIED = "MANUALLY_VERIFIED",
  UNVERIFIED = "UNVERIFIED",
  VERIFIED = "VERIFIED",
}

export enum EvolveAchAccountType {
  CHECKING = "CHECKING",
  SAVINGS = "SAVINGS",
}

export enum FeedItemType {
  APPLE_PAY_DEPOSIT_CREDITED = "APPLE_PAY_DEPOSIT_CREDITED",
  APPLE_PAY_DEPOSIT_FAILED = "APPLE_PAY_DEPOSIT_FAILED",
  APPLE_PAY_DEPOSIT_PENDING = "APPLE_PAY_DEPOSIT_PENDING",
  DEBT_INTEREST_APPLIED = "DEBT_INTEREST_APPLIED",
  DEPOSIT_CREDITED = "DEPOSIT_CREDITED",
  DEPOSIT_PENDING = "DEPOSIT_PENDING",
  FEE_APPLIED = "FEE_APPLIED",
  INTERNAL_DEPOSIT_CREDITED = "INTERNAL_DEPOSIT_CREDITED",
  INTERNAL_WITHDRAWAL_CONFIRMED = "INTERNAL_WITHDRAWAL_CONFIRMED",
  INTERNAL_WITHDRAWAL_INITIATED = "INTERNAL_WITHDRAWAL_INITIATED",
  INTERNAL_WITHDRAWAL_REJECTED = "INTERNAL_WITHDRAWAL_REJECTED",
  LOAN_APPLIED_FOR = "LOAN_APPLIED_FOR",
  LOAN_CLOSED = "LOAN_CLOSED",
  LOAN_OPENED = "LOAN_OPENED",
  LOAN_REJECTED = "LOAN_REJECTED",
  REPAY_DEBT = "REPAY_DEBT",
  REWARD_POINTS_REDEEMED = "REWARD_POINTS_REDEEMED",
  WALLET_INTEREST_APPLIED = "WALLET_INTEREST_APPLIED",
  WITHDRAWAL_CONFIRMED = "WITHDRAWAL_CONFIRMED",
  WITHDRAWAL_INITIATED = "WITHDRAWAL_INITIATED",
  WITHDRAWAL_REJECTED = "WITHDRAWAL_REJECTED",
}

export enum KycLevel {
  ENHANCED = "ENHANCED",
  ENHANCED_BANK = "ENHANCED_BANK",
  LIGHT = "LIGHT",
  NONE = "NONE",
}

export enum KycStatus {
  ACCEPTED = "ACCEPTED",
  ENHANCED_REQUIRED = "ENHANCED_REQUIRED",
  PROCESSING = "PROCESSING",
  REJECTED = "REJECTED",
  RESUBMIT = "RESUBMIT",
}

export enum KycType {
  ADDRESS = "ADDRESS",
  DATE_OF_BIRTH = "DATE_OF_BIRTH",
  DOCUMENT_ID = "DOCUMENT_ID",
  PHONE = "PHONE",
  SSN_FULL9 = "SSN_FULL9",
}

export enum LimitAction {
  ACQUIRING_CARD_DEPOSIT_SUCCESS = "ACQUIRING_CARD_DEPOSIT_SUCCESS",
  CARD_SPEND = "CARD_SPEND",
  INTERNAL_ACH_DEPOSIT_SUCCESS = "INTERNAL_ACH_DEPOSIT_SUCCESS",
  INTERNAL_ACH_WITHDRAWAL_SUCCESS = "INTERNAL_ACH_WITHDRAWAL_SUCCESS",
  INTERNAL_TRANSFER_DEPOSIT_SUCCESS = "INTERNAL_TRANSFER_DEPOSIT_SUCCESS",
  INTERNAL_TRANSFER_WITHDRAWAL_SUCCESS = "INTERNAL_TRANSFER_WITHDRAWAL_SUCCESS",
}

export enum PeriodUnit {
  DAY = "DAY",
  MONTH = "MONTH",
  WEEK = "WEEK",
  YEAR = "YEAR",
}

export enum PlatformType {
  ANDROID = "ANDROID",
  IOS = "IOS",
}

export enum RewardActionType {
  APPROVE_ACCOUNT = "APPROVE_ACCOUNT",
  EARN = "EARN",
  EARN_BALANCE_REACHED = "EARN_BALANCE_REACHED",
  FIRST_SPEND = "FIRST_SPEND",
  REFERRAL_APPROVE_ACCOUNT = "REFERRAL_APPROVE_ACCOUNT",
  SPEND = "SPEND",
}

export enum RewardStatusTheme {
  CLASSIC = "CLASSIC",
  PLATINUM = "PLATINUM",
  PREMIUM = "PREMIUM",
}

export enum RewardTransactionState {
  CONFIRMED = "CONFIRMED",
  PENDING = "PENDING",
}

export enum UserStatus {
  BASIC_USER_DATA_REQUIRED = "BASIC_USER_DATA_REQUIRED",
  KYC_FAILED = "KYC_FAILED",
  KYC_PROCESSING = "KYC_PROCESSING",
  KYC_REQUIRED = "KYC_REQUIRED",
  PHONE_REQUIRED = "PHONE_REQUIRED",
  VERIFY_EMAIL = "VERIFY_EMAIL",
  WAITLIST = "WAITLIST",
}

export interface ActivateCardInput {
  auth?: Auth | null;
  cardId: string;
}

export interface AddPushTokenInput {
  auth?: Auth | null;
  platform: PlatformType;
  token: string;
}

export interface AddTotpInput {
  auth?: Auth | null;
}

export interface AddWhitelistAddressInput {
  address: string;
  auth?: Auth | null;
  label: string;
  walletId: string;
}

export interface AddressInput {
  addressLine1: string;
  addressLine2?: string | null;
  addressLine3?: string | null;
  city: string;
  country: string;
  postcode: string;
  region: string;
}

export interface ApplyForLoanInput {
  auth?: Auth | null;
  borrowableAssetId: string;
  name?: string | null;
  termsId: string;
  value: Monetary;
}

export interface Auth {
  emailCode?: string | null;
  smsCode?: string | null;
  totpCode?: string | null;
}

export interface CloseLoanInput {
  auth?: Auth | null;
  loanId: string;
}

export interface ConfirmAddTotpInput {
  auth?: Auth | null;
  code: string;
}

export interface CreateBankAccountInput {
  auth?: Auth | null;
}

export interface CreatePhysicalCardInput {
  auth?: Auth | null;
}

export interface FreezeCardInput {
  auth?: Auth | null;
  cardId: string;
  freeze: boolean;
}

export interface GenerateDepositAddressInput {
  auth?: Auth | null;
  depositableId: string;
}

export interface InitiateAchDepositInput {
  auth?: Auth | null;
  linkedAccountId: string;
  value: Monetary;
}

export interface InitiateAchWithdrawalInput {
  auth?: Auth | null;
  linkedAccountId: string;
  value: Monetary;
}

export interface InitiateAcquiringDepositInput {
  auth?: Auth | null;
  linkedAccountId: string;
  shouldSendNotification?: boolean | null;
  value: Monetary;
}

export interface InitiateWithdrawalInput {
  address: string;
  auth?: Auth | null;
  value: Monetary;
  walletId: string;
}

export interface InternalTransferInput {
  auth?: Auth | null;
  fromTransferrableAssetId: string;
  toAccountId: string;
  toTransferrableAssetId: string;
  value: Monetary;
}

export interface KycCreateOrFetchKycUserInput {
  auth?: Auth | null;
  dateOfBirth: Date;
}

export interface KycIncreaseLevelInput {
  auth?: Auth | null;
  kycLevel: KycLevel;
}

export interface PaybackDebtInput {
  auth?: Auth | null;
  loanId: string;
  value: Monetary;
}

export interface RedeemGoldenTicketInput {
  auth?: Auth | null;
  code: string;
}

export interface RedeemRewardPointsInput {
  auth?: Auth | null;
  depositableAssetId: string;
  points: Decimal;
}

export interface ReissueCardInput {
  auth?: Auth | null;
  cardId: string;
  issueType: CardIssueType;
}

export interface RemovePushTokenInput {
  auth?: Auth | null;
  token: string;
}

export interface RemoveTotpInput {
  auth?: Auth | null;
}

export interface RequestEmailAuthInput {
  auth?: Auth | null;
}

export interface RequestPhoneAuthInput {
  auth?: Auth | null;
}

export interface ResendEmailVerificationInput {
  auth?: Auth | null;
  email: string;
}

export interface ResendPhoneVerificationInput {
  auth?: Auth | null;
  formattedPhone: string;
}

export interface SendInviteInput {
  auth?: Auth | null;
  email: string;
}

export interface SubmitBasicUserDataInput {
  auth?: Auth | null;
  country: string;
  firstName: string;
  lastName: string;
  middleName?: string | null;
  region?: string | null;
}

export interface SyncPlaidConnectionInput {
  auth?: Auth | null;
  plaidConnectionId: string;
}

export interface TempSetPrimaryAddressInput {
  address: AddressInput;
  auth?: Auth | null;
}

export interface UnlinkAcquiringAccountInput {
  auth?: Auth | null;
  linkedAccountId: string;
}

export interface UpdateEmailInput {
  auth?: Auth | null;
  email: string;
}

export interface UpdateNameInput {
  auth?: Auth | null;
  first: string;
  last: string;
  middle?: string | null;
}

export interface UpdatePhoneInput {
  auth?: Auth | null;
  formattedPhone: string;
}

export interface UpdatePrimaryAddressInput {
  address: AddressInput;
  auth?: Auth | null;
}

export interface VerifyEmailInput {
  auth?: Auth | null;
  code: string;
  email: string;
}

export interface VerifyPhoneInput {
  auth?: Auth | null;
  code: string;
  phone: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
