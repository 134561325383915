import React from 'react'
import { View } from 'react-native'

import { BoxProps, useBoxProps } from './boxHelpers'

export type Props = React.ComponentProps<typeof View> & BoxProps

export default function Box(props: Props) {
  const computedProps = useBoxProps(props)

  return <View {...computedProps}>{props.children}</View>
}
