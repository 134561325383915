import React from 'react'
import { View } from 'react-native'

import { useSafeArea } from 'react-native-safe-area-context'

import { SPACING_BASE } from '../../styles/global-styles'

const Spacer = ({ h, v }: { h?: number; v?: number }) => {
  const style = []

  if (h) {
    style.push({ width: SPACING_BASE * h })
  }
  if (v) {
    style.push({ height: SPACING_BASE * v })
  }

  return <View style={style} />
}

export default Spacer

export const SafeAreaTop = () => {
  const safeAreaInsets = useSafeArea()
  return <Spacer v={safeAreaInsets.top / SPACING_BASE} />
}

export const SafeAreaBottom = () => {
  const safeAreaInsets = useSafeArea()
  return <Spacer v={safeAreaInsets.bottom / SPACING_BASE} />
}
