import { useMemo } from 'react'
import { StyleSheet } from 'react-native'

import { useSafeArea } from 'react-native-safe-area-context'

import { SPACING_BASE } from '../../../styles/global-styles'

export type BoxProps = {
  safeArea?: 'all' | 'top' | 'bottom'
  absolute?: boolean
  absoluteFill?: boolean
  flex1?: boolean
  center?: boolean
  /**
   * Value will be multiplied by SPACING_BASE.
   */
  padding?: number
  /**
   * Value will be multiplied by SPACING_BASE.
   */
  paddingTop?: number
  /**
   * Value will be multiplied by SPACING_BASE.
   */
  paddingBottom?: number
  /**
   * Value will be multiplied by SPACING_BASE.
   */
  paddingLeft?: number
  /**
   * Value will be multiplied by SPACING_BASE.
   */
  paddingRight?: number
  /**
   * value multiplied by SPACING_BASE.
   */
  paddingX?: number
  /**
   * value multiplied by SPACING_BASE.
   */
  paddingY?: number
  row?: boolean
  top?: number
  right?: number
  bottom?: number
  left?: number
  borderRadius?: number
  borderColor?: string
  borderWidth?: number
  width?: number | string
  minWidth?: number | string
  maxWidth?: number | string
  height?: number
  minHeight?: number
  maxHeight?: number
  backgroundColor?: string
  style?: StyleProp
  align?: string
  justify?: string
}

function getFirstValidNumber(values: (number | undefined)[]) {
  return values.find((v) => v !== undefined) || 0
}

export function useBoxProps(props: BoxProps) {
  const safeAreaInsets = useSafeArea()
  return useMemo(() => {
    const {
      flex1,
      center,
      row,
      safeArea,
      absolute,
      absoluteFill,
      top,
      right,
      bottom,
      left,
      width,
      minWidth,
      maxWidth,
      height,
      minHeight,
      maxHeight,
      backgroundColor,
      style,
      align,
      justify,
      borderRadius,
      borderColor,
      borderWidth,
    } = props
    const containerStyles: StyleProp[] = []

    if (center) {
      containerStyles.push(styles.center)
    }
    if (align) {
      containerStyles.push({ alignItems: align })
    }
    if (justify) {
      containerStyles.push({ justifyContent: justify })
    }

    const padding = {
      paddingTop: getFirstValidNumber([props.paddingTop, props.paddingY, props.padding]) * SPACING_BASE,
      paddingBottom: getFirstValidNumber([props.paddingBottom, props.paddingY, props.padding]) * SPACING_BASE,
      paddingLeft: getFirstValidNumber([props.paddingLeft, props.paddingX, props.padding]) * SPACING_BASE,
      paddingRight: getFirstValidNumber([props.paddingRight, props.paddingX, props.padding]) * SPACING_BASE,
    }
    containerStyles.push(padding)

    if (safeArea === 'all' || safeArea === 'bottom') {
      containerStyles.push({
        paddingBottom: safeAreaInsets.bottom + padding.paddingBottom,
      })
    }
    if (safeArea === 'all' || safeArea === 'top') {
      containerStyles.push({
        paddingTop: safeAreaInsets.top + padding.paddingTop,
      })
    }

    if (absolute || absoluteFill) {
      containerStyles.push({
        position: 'absolute',
      })

      if (absoluteFill) {
        containerStyles.push({
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        })
      }

      if (top !== undefined) {
        containerStyles.push({
          top,
        })
      }
      if (right !== undefined) {
        containerStyles.push({
          right,
        })
      }
      if (bottom !== undefined) {
        containerStyles.push({
          bottom,
        })
      }
      if (left !== undefined) {
        containerStyles.push({
          left,
        })
      }
    }

    if (height !== undefined) {
      containerStyles.push({
        height,
      })
    }

    if (maxHeight !== undefined) {
      containerStyles.push({
        maxHeight,
      })
    }

    if (minHeight !== undefined) {
      containerStyles.push({
        minHeight,
      })
    }

    if (width !== undefined) {
      containerStyles.push({
        width,
      })
    }

    if (maxWidth !== undefined) {
      containerStyles.push({
        maxWidth,
      })
    }

    if (minWidth !== undefined) {
      containerStyles.push({
        minWidth,
      })
    }

    if (backgroundColor) {
      containerStyles.push({
        backgroundColor,
      })
    }

    if (flex1) {
      containerStyles.push(styles.flex1)
    }

    if (row) {
      containerStyles.push(styles.row)
    }

    if (borderRadius) {
      containerStyles.push({ borderRadius })
    }
    if (borderColor) {
      containerStyles.push({ borderColor })
    }
    if (borderWidth) {
      containerStyles.push({ borderWidth })
    }

    if (style) {
      containerStyles.push(style)
    }

    return { ...props, style: StyleSheet.flatten(containerStyles) }
  }, [props, safeAreaInsets])
}

const styles = StyleSheet.create({
  flex1: {
    flex: 1,
  },
  row: {
    flexDirection: 'row',
  },
  center: {
    alignItems: 'center',
    justifyContent: 'center',
  },
})
