export default {
  //SCREEN TITLES
  walletTitle: 'Minha Carteira',
  walletDetailTitle: 'Carteira {{name}}',

  invalidEmail: 'Endereço inválido.',
  requiredField: 'Campo obrigatório.',

  // AUTH
  login: 'Login',
  Password: 'Senha',
  forgotPassword: 'Esqueceu a senha',
  hasAccountButton: 'Já possui uma conta',
  noAccountButton: 'Näo tem uma conta',
  invalidLogin: 'Usuário ou senha inválidos',
  emailSent: 'Enviamos um email para alterar sua senha',
  sendEmail: 'Enviar email',
  playgroundHowMuch: 'Quanto você quer emprestado?',
  requestAccessTitle: 'Pedir acesso',
  requestAccessButton: 'Pedir acesso',
  collateralNeeded: 'Colateral necessário',
  firstNameField: 'Nome',
  lastNameField: 'Sobrenome',
  phoneField: 'Telefone',
  createAccount: 'Criar conta',
  countrySelector: 'País',
  resetPassword: 'Resetar a Senha',
  passwordResetSuccess: 'Sua senha foi alterada com sucesso!',
  goToLogin: 'Ir para Login',
  newPassword: 'Nova Senha',
  repeatPassword: 'Repetir Senha',

  // HOME SCREEN
  activity: 'atividade',
  creditBalance: 'Saldo de crédito',
  safe: 'seguro',
  atRisk: 'arriscado',
  payback: 'pagar',
  creditLimit: 'Limite de crédito',
  collateral: 'colateral',
  credit: 'crédito',
  totalCollateral: 'colateral total',
  totalCredit: 'crédito total',
  deposit: 'Depósito',
  withdraw: 'Saque',
  liquidationPrice: 'preço para liquidar',
  liquidation: 'liquidaçäo',
  limit: 'limite',
  card: 'Cartäo',
  more: 'Mais',
  loans: 'empréstimos',
  wallet: 'Carteira',
  loan: 'empréstimo',
  awaitingApproval: 'Aguardando aprovação',
  awaitingApprovalDesc: 'Isso pode demorar até 24h',
  end: 'fim',
  close: 'Fechar',
  newAmount: 'Novo valor',
  takeOutLoan: 'Pege um empréstimo',

  // Deposit Flow
  next: 'Próximo',
  borrowInManyCurrencies: 'Pegue emprestado em várias moedas',
  obtainCredit: 'Obtenha um crédito de',
  paybackOnlyAsset: 'Esta moeda só está disponível para depósitos de pagamento.',
  depositAmount: 'Valor do Depósito',
  paybackOnly: 'Somente pagamentos',
  premium: 'Premium',

  // LOAN DETAIL
  loanWalletReceived: 'Recebidos em sua carteira',
  loanWalletAwaiting: 'Aguardando receber os fundos',
  amountBorrowed: 'Valor emprestado',
  interestPerMonth: 'júros / mês',
  loanPeriod: 'período do empréstimo',
  seeWallet: 'Ver carteira',
  loanNotFound: 'Desculpe, empréstimo não encontrado',

  //Wallet
  lockedCollateral: 'Colateral bloqueado',
  price: 'preço',
  maxAvailableWithdraw: 'Máximo disponível para saque',
  // REQUEST LOAN
  requestLoanScreenTitle: 'Escolha o valor',
  loanRequestHeader: 'Escolha o valor',
  errorFetchingLoansDesc: 'Desculpe, Houve um erro ao carregar os empréstimos',
  queryErrorTitle: 'Opa, algo deu errado',

  // REVIEW LOAN
  reviewLoanScreenTitle: 'Termos',
  reviewLoanloanAmount: 'Valor do Empréstimo',
  reviewLoanInterestRate: 'Taxa de Júros',
  reviewLoanOriginationFee: 'Taxa de Geração',
  reviewLoanTermsHeader: 'Por favor leia e aceite os termos abaixo antes de submeter sua proposta.',
  reviewLoanLoanNotFound: 'Empréstimo não encontrado',
  reviewLoanLoanNotFoundDesc: 'Desculpe, não conseguimos encontrar o empréstimo: ',
}
