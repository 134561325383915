/*eslint sort-keys: ["error", "asc", {"caseSensitive": false, "natural": false}]*/

export const EARN_ACCOUNT_CODENAME = 'Earn'
export const APP_NAME = 'Hightop'

export default {
  accountAssetsHeaderCrypto: 'Crypto Accounts',
  accountAssetsHeaderDollar: 'Bank Accounts',
  accountAwaitingDepositDescription: 'Check status in your account or activity feed.',
  accountAwaitingDepositTitle: "We're waiting for your deposit",
  accountBitcoinDeposit: 'Bitcoin Deposit',
  accountCancelComplete: 'Action deleted.',
  accountCancelHeader: 'Cancel action',
  accountCancelSubheader: 'Are you sure you want to cancel this action?',
  accountCardCreatedSubtitle:
    'Full banking service is now available. Debit card, high interest earning, direct deposit, bill pay, etc. Deposit funds to get started.',
  accountCardCreatedTitle: `${APP_NAME} bank account created!`,
  accountDeactivatedSubtitleEmail: 'help@genesisblock.com',
  accountDeactivatedSubtitleNonZeroBalance:
    'Please contact us to arrange withdrawal of your outstanding account balance.',
  accountDeactivatedSubtitlePart2:
    "You'll be the first to get access when we open up services again. We're here to help: ",
  accountDeactivatedSubtitlePausingServices:
    'Thank you for all your support! At this time, we are temporarily pausing app services to stay ahead of evolving regulations.',
  accountDeactivatedTitle: 'Your account is no longer active.',
  accountDepositConfirmedSubtitle: 'Your bank balance has been updated. Use your deposit to borrow, earn, and spend!',
  accountDepositConfirmedTitle: 'Your deposit has been confirmed.',
  accountDepositPending: '3/10 confirmations',
  accountDepositPendingSubtitle: 'Once the deposit is confirmed you will be able to borrow, earn, and spend.',
  accountDepositPendingTitle: 'Your deposit will be validated in 10 confirmations.',
  accountDepositWaiting: 'Awaiting deposit',
  accountDepositWaitingSubtitle: '38LesqynkCiQrHMguF5JwmwKy5Uhdj8K5G',
  accountDepositWaitingTitle: 'Please deposit Bitcoin to this address.',
  accountEarn: 'Earning',
  accountFrozenSubtitle:
    'We will keep you updated on your status. Thank you for your patience!\n\nFeel free to contact us if you have any questions.',
  accountFrozenTitle: 'Your account is\ncurrently under review.',
  accountHeader: 'Total balance',
  accountHideZeroBalance: 'Hide zero balances',
  accountNoTransactions: 'No transactions',
  accountPendingConfirmationDescription: 'Check status in your account or activity feed.',
  accountPendingConfirmationTitle: "You're deposit is pending confirmation",
  accountPieChartAvailable: 'Available to withdraw',
  accountPieChartLocked: 'Loan collateral',
  accounts: 'accounts',
  accountSavings: `${EARN_ACCOUNT_CODENAME}・USD`,
  accountShowZeroBalance: 'Show zero balances ',
  accountTimestamp: 'Today',
  accountTimestampPast: 'Today, 13:45',
  accountZeroStateDescription: "See all your deposits and your earnings here and the money you've borrowed.",
  accountZeroStateTitle: 'See your money',
  accreditedInvestorAttachYourDocuments: 'Attach your documents',
  accreditedInvestorAuthenticationPickerStepDescription:
    'What tools would you have liked to use, if any, to make this more of an automated process (faster and more efficient)?\n\nThis would be an authentication with:',
  accreditedInvestorAuthenticationPickerStepSubtitle: 'Choose all that apply.',
  accreditedInvestorAuthenticationPickerStepTitle: 'Authentication',
  accreditedInvestorConfirmationModalNo: 'No, choose an alternative method',
  accreditedInvestorConfirmationModalRequirements:
    'Income needs to be in excess of $200,000 USD (or $300,000 USD joint income).',
  accreditedInvestorConfirmationModalText:
    'Do you have a reasonable expectation of reaching the same income level in the current year?',
  accreditedInvestorConfirmationModalTitle: 'Confirm verification method',
  accreditedInvestorConfirmationModalYes: 'Yes',
  accreditedInvestorFinraLicenseHolderStepPlaceholder: 'CRD Number',
  accreditedInvestorFinraLicenseHolderStepSubtitle:
    'If you currently hold a Series 7, 65, or 82 license, please enter your CRD# below:',
  accreditedInvestorFinraLicenseHolderStepTitle: 'FINRA License Holder',
  accreditedInvestorIncomeAttachYourDocumentsBottomText: 'Ex. 1099, W2, or Payroll stubs showing annual income',
  accreditedInvestorIncomeAttachYourDocumentsSubtitle:
    'Verify income of at least $200,000 in each of the last 2 years (or $300,000 joint income)',
  accreditedInvestorIntroDescription: 'Once you’ve verified, you can get access to all bank functionality.',
  accreditedInvestorIntroNextStep1: 'Choose Verification method',
  accreditedInvestorIntroNextStep2: 'Attach documents',
  accreditedInvestorIntroNextStep3: 'Investor statement',
  accreditedInvestorIntroNextStep4: 'Authentication method',
  accreditedInvestorIntroTitle1: 'Let’s verify your',
  accreditedInvestorIntroTitle2: 'Accredited Investor status.',
  accreditedInvestorInvestorStatementStepSubtitle: 'Accept the following statements',
  accreditedInvestorInvestorStatementStepTermText:
    'I represent and warrant to the Company that the answers I have provided in this Accredited Investor Questionnaire, including the information contained within the supplemental documents that I have delivered to the Company as my Investor information, are current, true, correct, and complete and do not omit to state any material fact necessary in order to make the statements in those documents not misleading.\n\nI further represent and warrant that I have sufficient knowledge, understanding, and experience in financial and business matters that enables me to evaluate the merits and risks of making the investment.',
  accreditedInvestorInvestorStatementStepTitle: 'Investor Statement',
  accreditedInvestorLiabilitiesStepDescription:
    'This can include loans, credit card debt, exercised lines of credit, mortgage debt, student loans, etc.\n\nNote: Due to SEC rules we may be required to conduct a soft credit check, we will contact you directly before making this request.',
  accreditedInvestorLiabilitiesStepPlaceholder: 'Estimate of Liabilities',
  accreditedInvestorLiabilitiesStepSubtitle: 'Provide estimate of your liabilities',
  accreditedInvestorLiabilitiesStepTitle: 'Liabilities',
  accreditedInvestorNetWorthAttachYourDocumentsBottomText1:
    'Examples include: Bank, brokerage, private investment statements, Crypto wallet*, Real estate documentation',
  accreditedInvestorNetWorthAttachYourDocumentsBottomText2:
    '*If your crypto assets are held in an anonymous wallet, upload a screenshot of the wallet address and we will contact you with a simple method to verify ownership of the wallet.',
  accreditedInvestorNetWorthAttachYourDocumentsSubtitle: 'Documents must show assets in excess of $1,000,000 USD.',
  accreditedInvestorOtherFormOfVerificationStepDescription: `This is reserved for Knowledgeable Employees of ${APP_NAME} or special situations.`,
  accreditedInvestorOtherFormOfVerificationStepPlaceholder: 'Explanation',
  accreditedInvestorOtherFormOfVerificationStepSubtitle: 'Provide an explanation below.',
  accreditedInvestorOtherFormOfVerificationStepTitle: 'Other Form of Verification',
  accreditedInvestorOtherMethodsFINRA: 'Current FINRA Series 7, 65, 82 license holder.',
  accreditedInvestorOtherMethodsOther: 'Other (Knowledgeable employee/Special situations)',
  accreditedInvestorOtherMethodsProfessionalLetter: 'Professional Letter from CPA, attorney, or financial institution.',
  accreditedInvestorOtherMethodsProfessionalLetterAttachYourDocumentsBottomText1:
    'Please ensure the verification letter has the following:',
  accreditedInvestorOtherMethodsProfessionalLetterAttachYourDocumentsBottomText2: `1. Name of person/ institution verifying accreditation\n2. License / registration # (for attorney/ CPA)\n3. State in which the 3rd party verifier is admitted/ registered\n4. Signature and date (within 90 days)`,
  accreditedInvestorOtherMethodsProfessionalLetterAttachYourDocumentsSubtitle:
    'Upload a Professional/ 3rd Party Verification Letter',
  accreditedInvestorSteplabel: 'Part 2',
  accreditedInvestorStepMessage: 'Part 2 of 2:  Accredited Investor',
  accreditedInvestorUploadingMessage: 'Approval may take some time.',
  accreditedInvestorUploadingTitle: 'Uploading\ninformation...',
  accreditedInvestorVerificationMethodsIncome: 'Income of at least $200,000 (or $300,000 if jointly)',
  accreditedInvestorVerificationMethodsNetWorth: 'Net worth in excess of $1,000,000 (including crypto assets)',
  accreditedInvestorVerificationMethodsOther: 'Other (CPA letter, FINRA license, special situations)',
  accreditedInvestorVerificationMethodsSubtitle: 'Select one of the following',
  accreditedInvestorVerificationMethodsTitle: 'Verification methods',
  accreditedInvestorYourSituationSubtitle: 'Select the situation that applies to you',
  accreditedInvestorYourSituationTitle: 'Your Situation',
  achDeposit: 'Deposit from {{name}}・{{last4}}',
  achWithdrawal: 'Withdraw to {{name}}・{{last4}}',
  achWithdrawalUnlinkedAccountTransactionName: 'Withdraw to bank account',
  acquiringCardNotSupported: 'Your card does not support cash transfers. Please try a different card.',
  acquiringInvalidCardNum: 'Your card does not support cash transfers. Please try a different card.',
  acquiringTxFailed: 'Sorry, there was an issue making the deposit from your bank or card',
  acquiringTxFailedCardExpired: 'This card has expired',
  acquiringTxFailedFraud: 'Transaction failed as it was flagged for fraudulent activity',
  acquiringTxFailedInsufficientFunds: 'Transaction failed due to insufficient funds',
  acquiringTxFailedLimit: 'This transaction exceeds the withdraw limits for your linked account.',
  activate: 'Activate',
  activateCardClosedErrorMessage: 'This card can no longer be activated, you will need to order a new one.',
  activateCardClosedErrorTitle: 'Activation period expired',
  activateCardTitle: 'Activate Your Card',
  activated: 'Activated',
  activateDebitCard: 'Activate your debit card!',
  activateDebitCardDescription: 'You can now order your debit card. Get 1% back in BTC with all purchases.',
  active: 'Active',
  activity: 'activity',
  activityFeedNoRecord: 'No transactions',
  activityFeedToday: 'Today',
  activityFeedYesterday: 'Yesterday',
  add: 'add',
  addAddressAlreadyWhitelisted: 'Address already added',
  addAddressHeader: 'Add new\n{{assetName}} address.',
  addAddressHeaderGeneric: 'Add new\nexternal address',
  addAddressHighlight: 'Add new',
  addAddressNoWallets: 'No wallets found, please deposit some funds to generate wallets.',
  address: 'address',
  addressAdded: 'Address added.',
  addressAddedDescription: 'Your new address has been added to your address book.',
  addressCapitalized: 'Address',
  addressChanged: 'Address Changed',
  addressesWhenWithdrawing: 'Addresses used when withdrawing to an external account.',
  addressFormAddressCitysLabel: 'City',
  addressFormAddressLabel: 'Address',
  addressFormAddressLine2Label: 'Apt ・ Suite ・ Other',
  addressFormAddressStateLabel: 'State ・ Region ・ Province',
  addressFormAddressStateUSLabel: 'State',
  addressFormAddressZipLabel: 'Zip code',
  agreeButton: 'I agree',
  alreadyHaveAccountButton: 'Already have an account?',
  amount: 'amount',
  amountBorrowed: 'Amount Borrowed',
  and: 'and',
  androidApp: 'Android app',
  asset: 'asset',
  assetBitcoin: 'Bitcoin',
  assetBTC: 'BTC',
  assetDai: 'Dai',
  assetDAI: 'DAI',
  assetETH: 'ETH',
  assetEthereum: 'Ethereum',
  assets: '{{number}} assets',
  assetSelect: 'Select the asset',
  assetTether: 'Tether',
  assetUSD: 'USD',
  assetUSDC: 'USDC',
  assetUSDCoin: 'USD Coin',
  assetUSDNumber: 'Account # 489266315',
  assetUSDollar: 'US Dollar',
  assetUSDT: 'USDT',
  atmWithdrawals: 'ATM withdrawals',
  atmWithdrawalsDescription: 'If you don’t withdraw cash from\nthe ATM, switch it off.',
  atRisk: 'at risk',
  attachmentErrorInvalidExtension: 'File type is not supported',
  attachmentErrorInvalidFile: 'File invalid or not supported',
  attachmentErrorSizeTooBig: 'File size is too big',
  attachmentFromCamera: 'Take Photo or Video',
  attachmentFromCameraPhoto: 'Take Photo',
  attachmentFromDocument: 'Search a File',
  attachmentFromLibrary: 'From Media Library',
  authenticateError: 'Error trying to authenticate',
  authSendCodeFailed: 'Failed to send code',
  availableLoansSubtitle: 'Choose the currency you want to borrow',
  availableLoansTitle: 'Get a credit line',
  awaitingApproval: 'Waiting for approval',
  awaitingApprovalDesc: 'This can take up to 24hr',
  awaitingDepositAddressHeader: 'Please deposit {{assetName}} to this address.',
  awaitingDepositTitle: '{{asset}} Deposit',
  awaitingTransfer: 'Awaiting Transfer',
  awaitingTransferCaption: 'Once your deposit is comfirmed you will be able to obtain a credit line.',
  back: 'back',
  backButton: 'Back',
  backupCodes: 'backup codes',
  backupCodesShare: 'Security backup codes {{codes}}',
  balanceBarBalance: 'Total bank balance',
  bank: 'bank',
  bankAccount: 'Bank account {{account}}',
  billingAddressMissing: 'Missing billing address',
  billingAddressMissingCountry: 'Missing country code in billing address',
  biometric: 'Biometrics',
  biometricModalSkip: 'Skip',
  biometricModalSubtitle: 'Your app will be protected with the biometric technology of your phone.',
  biometricModalTitle: `Secure access to your\n${APP_NAME} app.`,
  biometricModalTurnOnbiometric: 'Turn on Biometrics',
  biometricModalTurnOnfaceID: 'Turn on Face ID',
  biometricModalTurnOntouchID: 'Turn on Touch ID',
  blockchainExplorerButton: 'Blockchain explorer',
  borrow: 'borrow',
  borrowBarLeft: 'Loans',
  borrowBarRight: 'Max',
  borrowBarTitle: 'Available to borrow',
  borrowButton: 'Borrow',
  borrowCollateral: 'Collateral locked',
  borrowInManyCurrencies: 'BORROW IN MANY CURRENCIES',
  borrowNotificationsHeader: 'Want to be notified when your loan gets approved?',
  borrowPreviewButton: 'Sounds good',
  borrowPreviewSubtitle:
    'This is a Beta version of the product in testing phase. Loan requests won’t be approved during this time.',
  borrowPreviewTitle: 'The borrow feature is only an alpha preview.',
  borrowPreviewTitleHighlight: 'alpha preview.',
  borrowRatioBarLeft: 'Loans',
  borrowRatioBarRight: 'Max',
  borrowStep2Header: 'How much {{assetName}} would you like to borrow?',
  borrowStep2HeaderHighlight: 'How much {{assetName}}',
  borrowStep2HeaderUSDC: 'How much USD Coin would you like to borrow?',
  borrowStep3FixedTitle: 'Fixed-rate loan',
  borrowStep3FixedTitle1: 'Term length:',
  borrowStep3FixedTitle1Months: 'months',
  borrowStep3FixedTitle2: 'Rates fixed, starting at',
  borrowStep3Header: 'What type of loan would you like?',
  borrowStep3HeaderHighlight: 'What type of loan',
  borrowStep3VariableTitle: 'Variable-rate loan',
  borrowStep3VariableTitle1: 'Term length: flexible',
  borrowStep3VariableTitle2: 'Rates change, currently',
  borrowStep4FixedTitle: 'Fixed interest rate',
  borrowStep4FixedTitle1: 'This is a variable interest rate loan.',
  borrowStep4FixedTitle2: 'This rate is subject to change.',
  borrowStep4Header: 'How long do you want to borrow 500 USD?',
  borrowStep4Header2: 'Pay back your $500 loan whenever you want.',
  borrowStep4Header2USDC: 'Pay back your 3,000 USDC loan whenever you want.',
  borrowStep4HeaderUSDC: 'How long do you want to borrow 3,000 USDC?',
  borrowStep4VariableTitle: 'Current interest rate',
  borrowStep5FixedField1: 'Principal',
  borrowStep5FixedField2: 'Interest',
  borrowStep5FixedField3: 'Total due',
  borrowStep5FixedField4: 'Due by',
  borrowStep5FixedField5: 'Collateral locked',
  borrowStep5FixedField6: 'I have read and accept the terms.',
  borrowStep5Header: 'Review the terms.',
  borrowStep5HeaderHighlight: 'Review',
  borrowStep5VariableField2: "Today's rate",
  borrowStep5VariableField3: 'This rate will change',
  borrowStep5VariableField4: 'Due by',
  borrowStep5VariableField5: 'Collateral locked',
  borrowStep5VariableField6: 'I have read and accept the terms.',
  borrowStepVariableField1: 'Principal',
  borrowTitle: 'Select the asset you would like to borrow.',
  borrowTitleHighlight: 'Select the asset',
  bottom: 'Bottom',
  cameraPermissionButtonNegative: 'Cancel',
  cameraPermissionButtonPositive: 'Ok',
  cameraPermissionMessage: 'We need your permission to use your camera',
  cameraPermissionRequesting: 'Requesting permission to access camera',
  cameraPermissionRequired: 'This app requires camera access to scan the QRCode, please edit the permission settings.',
  cameraPermissionTitle: 'Permission to use camera',
  cancel: 'cancel',
  cancelButton: 'Cancel',
  card: 'card',
  cardAddNewNewCardTitle: 'Add a new card to\ndeposit from.',
  cardAddNewNewCardTitleHighlight: 'Add a new card',
  cardAddNewSaveMethod: 'Save deposit method',
  cardAddNewZipCode: 'Zip code',
  cardAddressChange: 'Not the right address? Change.',
  cardBankAccount: 'Bank account {{account}}',
  cardCreatedModalButton: 'Want a physical card?',
  cardCreatedModalButtonLater: 'Later',
  cardCreatedModalSubtitleDeposit: 'Make a deposit to earn and start using your card to spend.',
  cardCreatedModalSubtitleNotification: 'Turn on notifications to be alerted when you make a payment or receive money.',
  cardCreatedModalTitle: `Your ${APP_NAME} Account has been approved!`,
  cardDeposit: 'Card Deposit',
  cardDepositComplete: 'Deposit complete.',
  cardDepositConfirmAcceptTerms: 'I have read and accept',
  cardDepositConfirmFees: 'Fees',
  cardDepositConfirmFrom: 'From',
  cardDepositConfirmPayment: 'Payment',
  cardDepositConfirmSourceCard: 'Card',
  cardDepositConfirmTo: 'To',
  cardDepositError: 'Sorry, there was a problem processing the deposit.',
  cardDepositFees: 'Card Fees',
  cardDepositSourceAddCard: 'Add card',
  cardDepositSourceAmountBorrowAvailable: '{{amount}} available',
  cardDepositSourceBankInfo: 'Bank info',
  cardDepositSourceBankTransfer: 'Add bank',
  cardDepositSourceCheckingOrSavings: `Checking or ${EARN_ACCOUNT_CODENAME}`,
  cardDepositSourceDebitOrCredit: 'Debit or credit',
  cardDepositSourceTitle: 'Select the source of\nyour deposit.',
  cardDepositSourceTitleHighlight: 'Select the source',
  cardDepositSourceTransferToGB: `Transfer to ${APP_NAME}`,
  cardDetailMonthlyLimit: 'Monthly limit:',
  cardDetailMonthlyLimitLeft: 'Left:',
  cardDirectDepositLimitWarning:
    'This is a Beta version of the product in testing phase. Your direct deposit will bounce back if it is above.',
  cardEarnSubtitle: 'Make a purchase with your card and get 1% BTC-back.',
  cardEarnTitle: 'Earn 1% BTC-back on every purchases!',
  cardFailedLinking: 'Failed to add card',
  cardFailedNotSupported: 'This card is not supported',
  cardFieldCvc: 'CVV',
  cardFieldExpiration: 'Expiration date',
  cardFieldInvalidCardNumber: 'Invalid card number',
  cardFieldInvalidCvcNumber: 'Invalid CVV number',
  cardFieldInvalidExpiration: 'Invalid expiration date',
  cardFieldNumber: 'Card number',
  cardFielsInvalidExpiration: 'Invalid expiration date',
  cardFrozenActivate: 'You have to\nactivate your card.',
  cardFrozenLabel: 'You have frozen\nyour card.',
  cardHeader: 'Cash account',
  cardHowMuchDeposit: 'How much would you\nlike to deposit?',
  cardHowMuchDepositBalance: 'Balance: {{amount}}',
  cardHowMuchDepositHighlight: 'How much',
  cardHowMuchDepositTo: 'To: Cash Acount',
  cardKycPendingDescription: 'Your cash account will be created when everything is approved.',
  cardKycPendingSubmittedDescription:
    'We’ll review your information and send an email update shortly - usually under 24 hours. In the meantime, if you have any questions, please reach out directly to ',
  cardKycPendingTitle: 'Thanks for submitting your information!',
  cardLinkAdded: 'Card Linked',
  cardLinkAdding: 'Please wait while we make some checks, this can take up to a minute...',
  cardNotFound: 'Failed to load card details',
  cardOrderCardBannerSubtitle: 'And get 1% BTC-back on all your purchases.',
  cardOrderCardBannerTitle: 'Order your free card',
  cardPhysicalCardTitle: 'Physical card details',
  cardRewardsBannerTitle: 'Card rewards',
  cardRewardsBannerTransactions: '{{total}} transactions',
  cardRewardsFeedTitle: 'Total rewards',
  cardRewardsSubtitle: '1% Bitcoin-back on every purchase',
  cardRewardsTitle: 'BTC Rewards coming soon',
  cardScheduledTransactionTransfer: 'Deposit from {{place}}',
  cardScheduledTransactionTransferAvailable: 'Available by {{date}}',
  cardScreenTransfer: 'Transfer',
  cardServiceOnlyAvailableUS: 'Service only available for US residents.',
  cardServiceOnlyAvailableUSMakeAvailable: 'Make it accessible in my country.',
  cardSettingsAccountDetailsProvider: 'Provided by Evolve Bank & Trust',
  cardSettingsAccountNumber: 'Account number',
  cardSettingsAccountNumberNotAvailable: 'Not available',
  cardSettingsAccountPartner: 'Funds are held by Evolve Bank & Trust,\nand FDIC insured up to $250,000.',
  cardSettingsActivateCardButton: 'Activate card',
  cardSettingsActivateCardErrorMessage: 'There was an error while trying to activate your card.',
  cardSettingsActivateCardModalButton: 'Activate my card',
  cardSettingsActivateCardModalSubtitle: 'Activate it now by tapping on the button below.',
  cardSettingsActivateCardModalTitle: 'Have you received your card?',
  cardSettingsActivateCardSuccessMessage: 'Card activated',
  cardSettingsBankAccount: 'Bank account',
  cardSettingsBankAccountTitle: 'Account Details',
  cardSettingsBankAddress: 'Bank address',
  cardSettingsBankName: 'Bank name',
  cardSettingsCardBeenOrdered1: 'Your card has been ordered.',
  cardSettingsCardBeenOrdered2: 'Ready to be processed.',
  cardSettingsCardBeenProcessed1: 'Your card has been processed.',
  cardSettingsCardBeenProcessed2: 'Ready to be shipped.',
  cardSettingsCardBeenShipped1: 'Your card has been shipped.',
  cardSettingsCardBeenShipped2: 'It should arrive within 5-15 business days.',
  cardSettingsCardBeenShippedExpedited: 'It should arrive within 2-5 business days.',
  cardSettingsCardComingHeader: 'Your card is on the way!',
  cardSettingsCardComingSubheader:
    'You should receive your card in 5-7 business days. We will email tracking information.',
  cardSettingsCardDesign: '{{theme}} Card Design',
  cardSettingsCardDesignPhysical: 'Physical Card',
  cardSettingsCardDesignVirtual: 'Virtual Card',
  cardSettingsCardSettingsNewCard: 'Request new card',
  cardSettingsCardSettingsNewNumber: 'Generate new card number',
  cardSettingsCardSettingsPIN: 'Change PIN',
  cardSettingsCardSettingsStatements: 'Statements',
  cardSettingsCardSettingsTitle: 'Account Cards',
  cardSettingsCardSettingsViewCard: 'View card details',
  cardSettingsCardTracking: 'Card tracking',
  cardSettingsCardTrackingButton: 'View tracking details',
  cardSettingsCardTrackingTitle: 'Card tracking',
  cardSettingsChange: 'Change',
  cardSettingsChangePIN: 'Change PIN',
  cardSettingsConfirmPIN: 'Confirm new PIN',
  cardSettingsdAccountNumber: 'Account number',
  cardSettingsdAccountOwner: 'Account owner',
  cardSettingsErrorChangingStatus: 'Error trying to change the status',
  cardSettingsHeader: 'Cash Account',
  cardSettingsNewPIN: 'New PIN',
  cardSettingsNumberChanged: 'Number changed.',
  cardSettingsOwner: 'Account owner',
  cardSettingsPhysicalCardSettingsTitle: 'Physical card',
  cardSettingsPINChanged: 'PIN changed.',
  cardSettingsReissueError: 'There was an error while trying to reissue your card number.',
  cardSettingsRequestCard: 'Request new card',
  cardSettingsRequestCardWhy: 'Why are you requesting a new card?',
  cardSettingsRequestCardWhyField1: 'Lost my card',
  cardSettingsRequestCardWhyField2: 'Card was stolen',
  cardSettingsRequestCardWhyField3: 'Card expired',
  cardSettingsRequestCardWhyField4: 'Card damaged',
  cardSettingsRequestCardWhyKeepNumber: 'Existing card number will remain',
  cardSettingsRequestCardWhyNewNumber: 'New card number will be assigned',
  cardSettingsRequestNewCard: 'Request new card',
  cardSettingsRequestNewCardNumber: 'Request new card number',
  cardSettingsRequestNewNumberButton: 'Request',
  cardSettingsRequestNewNumberDescription:
    'Remember to update card information with merchants who may have your current number on file.',
  cardSettingsRequestNewNumberSuccess: 'New number generated.',
  cardSettingsRequestNewNumberTitle: 'request new card number',
  cardSettingsRequestNumber: 'Request new card number',
  cardSettingsRequestNumberWhy: 'Why do you need a new card number?',
  cardSettingsRequestProcessedHeader: 'Your request is being processed...',
  cardSettingsRoutingNumber: 'Routing number',
  cardSettingsSecuritySettingsATM: 'ATM withdrawals',
  cardSettingsSecuritySettingsATMText: 'If you don’t withdraw cash from the ATM, switch it off.',
  cardSettingsSecuritySettingsChip: 'Chip and PIN transactions',
  cardSettingsSecuritySettingsChipText: 'Internet transactions can be high-risk. Turn them off for added security.',
  cardSettingsSecuritySettingsContactless: 'Contactless payments',
  cardSettingsSecuritySettingsContactlessText: 'If you don’t want to use this, turn it off for extra security.',
  cardSettingsSecuritySettingsFreeze: 'Freeze card',
  cardSettingsSecuritySettingsFreezeText: 'Freeze your card when compromised or not in use.',
  cardSettingsSecuritySettingsLocation: 'Location-based security',
  cardSettingsSecuritySettingsLocationText:
    'We’ll use your location to help prevent fraudulent transactions. Turn GPS on.',
  cardSettingsSecuritySettingsOnline: 'Online transactions',
  cardSettingsSecuritySettingsOnlineText: 'Internet transactions can be high-risk. Turn them off for added security.',
  cardSettingsSecuritySettingsSwipe: 'Swipe payments',
  cardSettingsSecuritySettingsSwipeText: 'If you do not swipe, switch off magnetic stripe for extra security.',
  cardSettingsSecuritySettingsTitle: 'Security settings',
  cardSettingsSetupPINConfirmNewTitle: 'Confirm your new PIN code',
  cardSettingsSetupPINConfirmTitle: 'Confirm your PIN code',
  cardSettingsSetupPINCurrentTitle: 'Insert your current PIN code',
  cardSettingsSetupPINDontMatchError: "The PIN codes don't match",
  cardSettingsSetupPINSetupNewTitle: 'Set up your new PIN code',
  cardSettingsSetupPINSetupTitle: 'Set up your PIN code',
  cardSettingsSetupPINSuccess: 'PIN changed',
  cardSettingsSetupPINWrongPinError: 'Wrong PIN code',
  cardSettingsSetupSignature: 'Create your signature',
  cardSettingsSetupSignatureConfirm: 'Confirm my signature',
  cardSettingsSetupSignatureSignHere: 'Sign here',
  cardSettingsShipCardButton: 'Order my card',
  cardSettingsShipModalProcessingFees: 'Processing fees',
  cardSettingsShipModalShippingButton: 'Yes, Order my card',
  cardSettingsShipModalShippingError: 'There was an error while trying to issue your card.',
  cardSettingsShipModalShippingFees: 'Shipping',
  cardSettingsShipModalShippingOptions: 'Shipping options',
  cardSettingsShipModalShippingOptionsFedex: 'FedEx',
  cardSettingsShipModalShippingOptionsFedexDays: '2 business days',
  cardSettingsShipModalShippingOptionsStandard: 'Standard USPS',
  cardSettingsShipModalShippingOptionsStandardDays: '5-10 business days',
  cardSettingsShipModalShippingSuccess: 'Card ordered.',
  cardSettingsShipModalShippingTotal: 'Total due',
  cardSettingsShippingInsuficientFunds: 'Insufficient funds,',
  cardSettingsShippingMakeDeposit: 'make a deposit.',
  cardSettingsShipTo: 'Ship to',
  cardSettingsTitle: 'Cash Account',
  cardSettingsTittle: 'USD Account',
  cardSettingsTrackingDelivered: 'Delivered',
  cardSettingsTrackingOrdered: 'Ordered',
  cardSettingsTrackingProcessed: 'Processed',
  cardSettingsTrackingShipped: 'Shipped',
  cardSettingsViewPin: 'View/change PIN',
  cardSettingsViewPIN: 'View PIN',
  cardSettingsVirtualCardSettingsTitle: 'Virtual card',
  cardSetupFeedback: 'Your card is on its way!',
  cardSetupFeedbackError: 'Error shipping your card',
  cardSetupStartHeader: `Let's set up your ${APP_NAME} debit card.`,
  cardSetupStep1Header: 'Done! You’re virtual debit card is active!',
  cardSetupStep1HeaderHighlight: 'Done!',
  cardSetupStep1Subtitle: 'We’ll generate a debit card number, so you can start spending now.',
  cardSetupStep1Title: 'Virtual card',
  cardSetupStep2Header: 'Do you want to ship your physical card?',
  cardSetupStep2Optional: 'Optional',
  cardSetupStep2Subtitle: 'The card number will be the same as the virtual card you have just activated.',
  cardSetupStep2Title: 'Ship card',
  cardSetupSubtitle: 'And get 1% BTC-back on all your purchases.',
  cardSetupTitle: 'Order your free card',
  cardShipComplete: 'Your card is on its way!',
  cardShipError: 'There was an error, try again later',
  cardShipNotNow: 'Not now',
  cardShipPhysicalDescription: 'The card number will be the same as the virtual card you have just activated.',
  cardShipPhysicalNotRightAddress: 'Not the right address?',
  cardShipPhysicalNotRightAddressChange: 'Change.',
  cardShipPhysicalShipTo: 'Ship to',
  cardShipPhysicalTitle: 'Do you want to ship your physical card?',
  cardShippingAddress: 'Ship to',
  cardShipSetupCreateCardButton: 'Create my card',
  cardShipSetupShipCardDescription: 'You’ll have the option to ship a physical card if you want to spend in stores!',
  cardShipSetupShipCardOptional: 'Optional',
  cardShipSetupShipCardTitle: 'Ship card',
  cardShipSetupTitle: `Let’s set up your ${APP_NAME} debit card.`,
  cardShipSetupVirtualCardDescription: 'We’ll generate a debit card number, so you can start spending now.',
  cardShipSetupVirtualCardTitle: 'Virtual card',
  cardShipSubtitle: 'You can add it to your phone wallet or access it from the card settings.',
  cardShipTitle: 'Ship your card, anytime',
  cardShipVirtualCardStepTitle: 'Perfect! You’re virtual debit card is active!',
  cardShipVirtualCardStepTitleHighlight: 'active',
  cardShipYes: 'Yes, ship',
  cardStatementsCurrent: 'Current statement',
  cardStatementsError: 'Error loading statement',
  cardStatementsHeader: 'Statements',
  cardStatementsToday: 'Today',
  cardSuggestModalBankField: 'Who is the most innovative bank in your country that we should try and partner with?',
  cardTotalRewards: 'Total BTC-back Rewards',
  cardTransactionCategory: 'Category',
  cardTransactionFee: 'Fee',
  cardTransactionField1: 'Card',
  cardTransactionField2: 'Spent',
  cardTransactionField3: 'Fee',
  cardTransactionField4: 'Rewards',
  cardTransactionID: 'ID',
  cardTransactionNotFound: 'Transaction not found',
  cardTransactionReceived: 'Received',
  cardTransactionReportIssueButton: 'Report an issue',
  cardTransactionReportIssueField: 'Tell us what happened',
  cardTransactionReportIssueFormButton: 'Report',
  cardTransactionRewards: 'Rewards',
  cardTransactionSpent: 'Spent',
  cardTransactionStateCompleted: 'Completed',
  cardTransactionStatePending: 'Pending',
  cardTransactionTransfer: 'Transfer',
  cardTypePhysical: 'Physical Card',
  cardTypeVirtual: 'Virtual card',
  cardViewLimits: 'See all limits',
  cardZeroStateDescription: 'Open your cash account and start spending with your card.',
  cardZeroStateTitle: 'Earn points with\neach purchase',
  cashAccountAgreementsPrefix: 'By opening a cash account, you agree with our',
  cashAccountDebitCardAgreement: 'Debit Card Agreement',
  cashAccountDepositAgreement: 'Deposit Agreement',
  cashAccountElectronicCommunicationsAgreement: 'Electronic Communications Agreement',
  cashAccountInsurance: 'Your funds are held by Evolve Bank & Trust, and are insured by the FDIC for up to $250,000.',
  cashAccountPrivacyPolicyAgreement: 'Privacy Policy',
  change: 'Change',
  changeAddress: 'Change address',
  changeAddressInProgress: 'Verifying your new address...\nIt may take some time.',
  changeAddressWarningDone: 'Sounds good',
  changeAddressWarningText:
    "If your identity verification doesn't pass, your account will be suspended until we manually review your address change.",
  changeAddressWarningTitle: 'Changing your address will resubmit your identity verification.',
  changeEmail: 'Change Email',
  changeEmailButton: 'Sounds good',
  changeEmailWarningDescription:
    "If your identity verification doesn't pass, your account will be suspended until we manually review your email change.",
  changeEmailWarningTitle: 'Changing your email will resubmit your identity verification.',
  changePassword: 'Change Password',
  changePhone: 'Change phone number',
  charged: 'charged',
  chartFetchError: 'Sorry, there was a problem fetching price data.',
  checkEmailLink: 'Check your email and click on the reset password link.',
  chipAndPinTransactions: 'Chip and PIN transactions',
  chipAndPinTransactionsDescription: 'Internet transactions can be high-risk.\nTurn them off for added security.',
  chooseCountry: 'Choose your country',
  close: 'Close',
  closeButton: 'Close',
  closed: 'Closed',
  closedLoansTitle: 'Closed Loans',
  code: 'Code',
  collateral: 'collateral',
  collateralNeeded: 'Collateral needed',
  comingSoon: 'Coming soon',
  confirm: 'confirm',
  connectionStateSubtitle: 'Data is not updating',
  connectionStateTitle: 'No internet connection',
  contactButton: 'Contact us',
  contactlessPayments: 'Contactless payments',
  contactlessPaymentsDescription: 'If you don’t want to use this, turn it off for extra security.',
  contactUs: 'Contact Us',
  contactUsMessage: 'For assistance, please email us at',
  contactUsTitle: 'Send us a message',
  copied: 'Copied',
  copiedButton: 'Copied',
  copy: 'Copy',
  copyButton: 'Copy',
  countrySelector: 'Country of residence',
  createAccount: 'Create your account',
  createAccountSubtitle: 'Let’s finalize everything.',
  createCardButton: 'Create my card',
  createCardModalLoading: 'Finalizing the setup...',
  credit: 'credit',
  creditAwaitingDepositDesc: 'Check status in your account or activity feed.',
  creditAwaitingDepositTitle: "We're waiting for your deposit",
  creditBalance: 'Loans',
  creditBalanceHeader: 'Available to Borrow',
  creditCalculate: 'Calculate how much you can borrow based on the deposit amount.',
  creditDepositIndicator: 'Check deposit status here',
  creditDepositReceivedDesc: 'Borrow against your assets you have in your accounts.',
  creditDepositReceivedTitle: 'Your deposit gave you borrowing power!',
  creditGetCreditButton: 'Get a credit line',
  creditHealthImpactTitle: 'Borrowing Power',
  creditHelperStep1Desc:
    'Once your deposit is confirmed, your credit limit will be updated and you will be ready to borrow.',
  creditHelperStep2Desc: 'Borrow BTC, ETH, stablecoins and more up to your credit limit.',
  creditHelperStep2Title: 'Get a line of credit',
  creditLimit: 'Credit limit',
  creditMenu: 'Credit',
  creditOpenCalculator: 'Open Calculator',
  creditZeroStateDesc: 'Deposit assets, then take out a loan against your collateral. Simple.',
  creditZeroStateTitle: 'Alright, let’s make some magic.',
  cryptoWithdrawAddNewAddress: 'Add a new {{assetName}} address',
  cryptoWithdrawFrom: 'From: {{asset}}',
  dashboard2FAMarketing: 'Protect your money, learn more about 2FA',
  dashboardAccountSubtitle: 'Total balance',
  dashboardActivity: 'Activity',
  dashboardAvailableToBorrow: 'Borrow',
  dashboardCardMarketing: 'Get 1% Bitcoin-back',
  dashboardCardMarketingActive: 'BTC rewards active',
  dashboardCardPromo: 'Get 1% crypto-back',
  dashboardCardSubtitle: 'FDIC・Up to 3% back',
  dashboardCardSubtitleZeroState: 'Open my cash account',
  dashboardCardTitle: 'Cash account',
  dashboardCreditLines: 'credit lines',
  dashboardDonateDescription: 'We donate 10% of our revenue to causes you choose.',
  dashboardDonateTitle: 'Give',
  dashboardEarnCrypto: 'Crypto',
  dashboardEarnDescription: 'Earn up to 10% annually on deposits to your Earn Account. ',
  dashboardEarnHeader: 'Earn',
  dashboardEarnInv: 'Growth ',
  dashboardEarnInvest: 'Growth',
  dashboardEarnMarketingValue: 'Up to 7% APY',
  dashboardEarnOther: 'Other',
  dashboardEarnSubtitle: 'Earning on',
  dashboardEarnTitle: 'Earn',
  dashboardEarnUpTo: 'Earn up to 9.8% APR',
  dashboardGoldenTickets: 'Golden Tickets',
  dashboardGoldenTicketsCopy:
    'These codes allow a user to skip the waitlist (currently {{total}} people) and immediately get into the app. Share these codes wisely.',
  dashboardGoldenTicketsCopyAll: 'Share all',
  dashboardGoldenTicketsHeader: 'Let your friends skip The Queue.',
  dashboardGoldenTicketsMore: 'Want more tickets?',
  dashboardInviteButton: 'Invite',
  dashboardInviteFriends: 'Invite friends',
  dashboardInviteFriendsCheck: 'Include Golden Ticket to skip The Queue',
  dashboardInviteFriendsCopy:
    'For every person that you invite who activates their account and deposits at least $100, you both get a free month of the premium account.',
  dashboardInviteFriendsEmail: 'Email',
  dashboardInviteFriendsHeader: 'Free month of Premium membership.',
  dashboardInviteFriendsSend: 'Send',
  dashboardInviteFriendsSent: 'Sent',
  dashboardInviteFriendsShare: 'Share',
  dashboardInviteHeader: 'Refer a friend',
  dashboardInviteLabel: 'Invite a friend and\nget free premium',
  dashboardLending: 'Total earnings',
  dashboardLoanSubtitle: 'Available to borrow',
  dashboardLoanTitle: 'Borrow',
  dashboardNotificationsHeader: 'Want to be notified about your account activity?',
  dashboardOnboardAccountBody: "This is where you'll see all your money and all the different assets you hold.",
  dashboardOnboardAccountTitle: 'Bank',
  dashboardOnboardBankBody: "This is where you'll see all your money and all the different assets you hold.",
  dashboardOnboardBankTitle: 'Bank',
  dashboardOnboardBorrowBody: 'Borrow money without selling your crypto. See all your loans here.',
  dashboardOnboardBorrowTitle: 'Borrow',
  dashboardOnboardCardBody: 'Activate a debit card to start spending your money. Earn 1% BTC back on every purchase.',
  dashboardOnboardCardTitle: 'Spend',
  dashboardOnboardEarnBody: "See all the money you're making with your deposits. Start earning automatically.",
  dashboardOnboardEarnTitle: 'Earn',
  dashboardOnboardLoanBody: 'Borrow money without selling your crypto. See all your loans here.',
  dashboardOnboardLoanTitle: 'Borrow',
  dashboardOnboardSpendBody: 'Activate a debit card to start spending your money. Bitcoin rewards available.',
  dashboardOnboardSpendTitle: 'Spend',
  dashboardOnboardStartBody: `Let's show you the four most important sections of ${APP_NAME}.`,
  dashboardOnboardStartTitle: "Congrats, you're in!",
  dashboardOnboardStartTitleHighlight: "Congrat's ",
  dashboardRewardsDescription: 'Earn up to 3% crypto cashback with your Hightop Visa card.',
  dashboardRewardsPromoText: 'Get started:\nEarn +800 points',
  dashboardRewardsSubtitle: 'Up to 50% APY boost',
  dashboardRewardsTitle: 'Rewards',
  dashboardSettings: 'Settings',
  dashboardSpendDescription: 'Buy lunch, pay rent, transfer money, and more, all fee-free. ',
  dashboardSpendTitle: 'Spend',
  dashboardTotalAvailable: 'Total available',
  dashboardUnsupportedCountryHelp: "we're here to help.",
  dashboardWelcomeModalButton: 'Verify my identity',
  dashboardWelcomeModalButtonDismiss: 'Not yet, I would like to explore first.',
  dashboardWelcomeModalButtonVerifying: 'Verify my identity (waiting for approval)',
  dashboardWelcomeModalDescription:
    'To take advantage of the full banking experience you will need to provide more information to verify your identity.',
  dashboardWelcomeModalName: 'Welcome {{name}}!',
  dashboardWelcomeModalTermsAccountTerms: '\nAccount Terms',
  dashboardWelcomeModalTermsAllTerms: '\nTerms of Service.',
  dashboardWelcomeModalTermsAnd: ', and all other related ',
  dashboardWelcomeModalTermsPrivacyPolicy: 'Privacy Policy',
  dashboardWelcomeModalTermsStart: `By continuing, you agree with ${APP_NAME} `,
  days: 'days',
  debitCard: 'Debit Card',
  deposit: 'Deposit',
  depositActive: 'deposit methods',
  depositAddBank: 'Add bank',
  depositAddBankStep1Field1: 'Routing number',
  depositAddBankStep1Field2: 'Account number',
  depositAddBankStep1Field3: 'Bank',
  depositAddBankStep1Field4: 'Save bank account',
  depositAddBankStep1Header: 'Add a new bank to\ndeposit from.',
  depositAddCard: 'Add card',
  depositAddCardStep1Description: 'Visa debit/credit cards only.',
  depositAddCardStep1Field1: 'Card number',
  depositAddCardStep1Field2: 'Expiration date',
  depositAddCardStep1Field3: 'CVV',
  depositAddCardStep1Field4: 'Zip code',
  depositAddCardStep1Field5: 'Save deposit method',
  depositAddCardStep1Header: 'Add a debit/credit card.',
  depositAddCardStep1HeaderHighlight: 'debit/credit card.',
  depositAddCardStep1Subheader: 'No fees on debit. 3% fees on credit.\nInstant deposit on both.',
  depositAddCardStep2Header: 'How much would you like to deposit?',
  depositAddCardStep2HeaderHighlight: 'How much',
  depositAddCardStep3Header: 'Confirm the deposit.',
  depositAddCardStep3HeaderHightlight: 'Confirm',
  depositAddressErrorUS: 'Invalid billing country, we currently only support deposits to US billing addresses',
  depositAmount: 'Deposit Amount',
  depositAwaitingTransfer: 'Awaiting transfer',
  depositBanks: 'Banks',
  depositBitcoinHeader: 'Deposit {{currency}} to this address.',
  depositBitcoinTitle: 'Only send {{type}} to this address',
  depositBorrowStep1Header: 'How much do you want to borrow for your deposit?',
  depositBorrowStep2Header: 'What type of loan would you like?',
  depositBorrowStep3Header: 'How long do you want to borrow $500?',
  depositBorrowStep4Header: 'Review the terms.',
  depositButton: 'Deposit',
  depositCard: 'Cash account',
  depositCards: 'Cards',
  depositCash: 'Cash・USD',
  depositCashDisclaimerButton: 'Sounds good',
  depositCashDisclaimerLimitsSubtitle:
    'As we are testing the app and its security we have put limits in place to protect everyone. We will eventually remove them at a later stage.',
  depositCashDisclaimerLimitsTitle: 'Limits during our\nbeta version.',
  depositCashDisclaimerLimitsTitleHighlight: 'Limits',
  depositCashDisclaimerSubtitle: `Please transfer it manually to ${EARN_ACCOUNT_CODENAME} (USD) if you want to earn interest on this amount.\n\nACH deposit in ${EARN_ACCOUNT_CODENAME} (USD) will be available soon, please be patient.`,
  depositCashDisclaimerTitle: 'Your money will be deposited in your Cash Account (USD).',
  depositCashDisclaimerTitleHighlight: 'Cash Account (USD).',
  depositCashSub: 'For your visa debit card.',
  depositComplete: 'Deposit completed.',
  depositConfirm: 'Confirm deposit',
  depositConfirmField1: 'From',
  depositConfirmField2: 'To',
  depositConfirmField3: 'Amount',
  depositConfirmField4: 'Fees',
  depositConfirmField5: 'Total',
  depositConfirmField6Part1: 'By depositing you agree to the applicable ',
  depositConfirmField6Part2: 'Terms',
  depositConfirmHeader: 'Confirm the deposit.',
  depositConfirmHeaderHightlight: 'Confirm',
  depositDisclaimerAcquiring:
    'This deposit has transited through the Cash Account (USD). New transactions will show there as well.',
  depositDisclaimerNote: 'Note: ',
  depositFailedFeeQuery: 'Failed to fetch payment fee',
  depositFiatCalculatingLimit: 'Fetching limit...',
  depositFiatCalculatingLimitFailed: 'Failed to fetch limit',
  depositHeader: 'Select the source of\nyour deposit.',
  depositHeaderHighlight: 'Select the source',
  depositLimitReached: 'Deposit limit reached',
  depositMaxBalance: 'Above your balance limit',
  depositMaxDaily: 'Above your daily limit',
  depositMaxMonthly: 'Above your monthly limit',
  depositMaxRange: 'Max: {{amount}}',
  depositMaxWarning: 'Above your limit',
  depositMaxWeekly: 'Above your weekly limit',
  depositNoAddressError: 'Sorry, there is currently no deposit address for this asset.',
  depositNotificationsHeader: 'Want to be notified when your deposit arrives?',
  depositOption1: 'Borrow',
  depositOption1Subtitle: 'Loan',
  depositOption1SubtitleAmount: '{{amount}} available',
  depositOption2: 'Apple Pay',
  depositOption2Subtitle: 'Card',
  depositOption3: 'Add Debit/Credit Cards',
  depositOption3CreditSubtitle: '3% fees. Instant.',
  depositOption3DebitSubtitle: 'No fees. Instant.',
  depositOption3Subtitle: 'No fees. Instant.',
  depositOption3SubtitleNoFeeInfo: 'Instant.',
  depositOption4: 'Add Bank (ACH)',
  depositOption4Subtitle: 'No fees. 1-3 business days.',
  depositOption4SubtitleNoFeeInfo: '1-3 business days.',
  depositOption5: 'Bank wire',
  depositOption5Subtitle: `Transfer to ${APP_NAME}`,
  depositOptionSelectMethod: 'Select a deposit method',
  depositOptionSelectMethodCTA: 'Select',
  depositOptionSelectMethodSubtitle: 'Bank, Debit/Credit card',
  depositOptionWire: 'Wire Transfer',
  depositOptionWireDataTitle: 'Cash account details',
  depositOptionWireHighlight: 'Transfer',
  depositOptionWireSubtitle: 'Initiate a wire transfer from another bank using your Cash Account details.',
  depositOther: 'Internal transfer',
  depositSavings: `${EARN_ACCOUNT_CODENAME} account`,
  depositSavingsSub: 'Earn interest, currently X% APY',
  depositSavingsSubtitleAmount: 'Balance: {{amount}}',
  depositSavingsViaCashFailedTransfer: `Your deposit has arrived in your cash account but failed to transfer over to ${EARN_ACCOUNT_CODENAME}, you can try again by using the transfer method in your cash account.`,
  depositSendConfirmButton: 'Sounds good',
  depositSendConfirmSubtitleBTC:
    'Sending any other asset, including Bitcoin Cash (BCH), will result in permanent loss.',
  depositSendConfirmSubtitleETH:
    'Sending any other asset, including Ethereum Classic (ETC), might result in permanent loss.',
  depositSendConfirmSubtitleStablecoins:
    'This is an ERC-20 address. Sending any other asset might result in permanent loss.',
  depositSendConfirmTitle: 'Only send {{type}} to this address.',
  depositSendTitle: 'Deposit {{currency}}\nto this address.',
  depositSendTitleHighlight: 'Deposit {{currency}}',
  depositSignAgreementButton: 'Agree & Confirm Deposit',
  depositSourceNone: 'Sorry, there are currently no deposit options available for this asset',
  depositTermsLink: 'By depositing, you agree with our  ',
  depositTermsLinkUnderlined: 'Terms.',
  depositTitle: 'Select the asset you would like to deposit.',
  depositTitleDepositTo: 'Select the asset you would like to deposit to.',
  depositTitleDepositToHighlight: 'Select the asset',
  depositTitleHighlight: 'Select the asset',
  depositToCash: 'To Cash',
  depositToGetCard: 'Deposit $100 and get your debit card',
  depositToGetCardDescription: 'Deposit funds, direct deposit or borrow USD. Earn 1% BTC with each purchase.',
  depositToSavings: `To ${EARN_ACCOUNT_CODENAME}`,
  depositWarningBody:
    'This is a Beta version of the product in testing phase. You will not earn interest on more than that.',
  depositWarningDone: 'Sounds good',
  depositWarningTitle: 'Do not deposit more than',
  details: 'details',
  done: 'done',
  doneButton: 'Done',
  download: 'Download',
  downloadAndPlayGame: 'Download the mobile app to play the game and jump 100 spots every day!',
  downloadMobileApp: 'Be ready to skip the queue and download the mobile app.',
  downloadMobileAppHighlight: 'and download the mobile app.',
  earnAccount: `${EARN_ACCOUNT_CODENAME} Account`,
  earnAccountCodename: EARN_ACCOUNT_CODENAME,
  earnAndPoint: 'and {{amount}}pt',
  earnAndPoints: 'and {{amount}}pts',
  earnAwaitingDepositDescription: 'Check status in your account or activity feed.',
  earnAwaitingDepositTitle: "We're waiting for your deposit",
  earnCalculate: 'Calculate how much money you can earn with each deposit.',
  earnCalculateBorrow: 'Earned in a year',
  earnCalculateButton: 'Open Calculator',
  earned: 'earned',
  earnHeader: 'Total earnings',
  earning: 'earning',
  earnMoreButton: 'Earn more',
  earnOpenCalculator: 'Open calculator',
  earnPendingConfirmationDescription: 'Check status in your account or activity feed.',
  earnPendingConfirmationTitle: "You're deposit is pending confirmation",
  earnScreenEarnButton: 'Earn more',
  earnSettingsHeader: 'Earn',
  earnZeroStateDescription: "Earn up to 7% on your deposits. It's automatic.",
  earnZeroStateHeaderDescription: 'Deposit some funds in your accounts and start earning right away!',
  earnZeroStateHeaderTitle: 'Earn up to 7% APY\non your deposits.',
  earnZeroStateTitle: 'Making money for you',
  editLoanAmountCollateralLocked: 'Collateral locked',
  editLoanTitle: 'How much {{assetName}} would you like to borrow?',
  email: 'Email',
  emailChanged: 'Email Changed',
  emailCode: 'Email code',
  emailSent: "We've sent you an e-mail in order to reset your password",
  emailVerified: 'Email verified',
  emilCodeSent: 'Email sent',
  end: 'end',
  errorChangingEmail: 'There was an error trying to change your email',
  errorChangingPassword: 'There was an error trying to change your password',
  errorChangingPhoneNumber: 'There was an error trying to change your phone number',
  errorDepositAddrNotFoundDesc: "Sorry, it's not possible to deposit this asset at the current time.",
  errorDepositAddrNotFoundTitle: 'No deposit address found',
  errorFetchingLoansDesc: 'Sorry there was a problem fetching available loans',
  errorInvalidSSN: 'SSN is invalid or already in use.',
  errorLoadingData: 'Error loading data',
  errorLoadingSecurity: 'Error loading security settings',
  errorReadingResponse: 'There was a problem reading the response',
  errorSecurityCheckFailed: 'Security check failed',
  errorTwoFASetup: 'Error setting up Two-Factor Authentication',
  errorValidatingEmail: 'Error validating email',
  everytimeWithdraw: 'Everytime you withdraw.',
  faceID: 'Face ID',
  failed: 'failed',
  feedbackSubmitted: 'Feedback submited.',
  feedItemConfirmations: 'confirmations',
  feedItemSubtitleConfirmed: 'Confirmed',
  feedItemSubtitleDenied: 'Denied',
  feedItemSubtitleFailed: 'Failed',
  feedItemSubtitlePending: 'Pending',
  feedItemSubtitleRejected: 'Rejected',
  feedItemTitleApplePayDeposit: 'Apple Pay Deposit',
  feedItemTitleDeposit: 'Deposit',
  feedItemTitleFeeApplied: 'Origination fees',
  feedItemTitleInterestApplied: 'Interest Applied',
  feedItemTitleLoanApplication: 'Loan Application',
  feedItemTitleLoanClosed: 'Loan Closed',
  feedItemTitleLoanOpened: 'Loan Approved',
  feedItemTitleLoanRejected: 'Loan Request Denied',
  feedItemTitleRepayDebt: 'Loan Repayment',
  feedItemTitleTransferIn: 'Transfer from Cash',
  feedItemTitleTransferOut: 'Transfer to Cash',
  feedItemTitleWithdrawal: 'Withdrawal',
  feedItemTitleWithdrawalRejected: 'Withdrawal denied',
  fiatCashAccount: 'Cash',
  fiatDepositSourceDetailsConfirmationLoading: 'Removing...',
  fiatDepositSourceDetailsConfirmationMessage: 'Are you sure you want to remove?',
  fiatDepositSourceDetailsConfirmationYes: 'Yes, remove',
  fiatDepositSourceDetailsModalCardNetwork: 'Card',
  fiatDepositSourceDetailsModalCardType: 'Type',
  fiatDepositSourceDetailsModalExpiryDate: 'Valid until',
  fiatDepositSourceDetailsModalLast4: 'Last 4 digits',
  fiatSavingsAccount: EARN_ACCOUNT_CODENAME,
  fiatTypeCash: 'Cash',
  firstNameField: 'First Name',
  flexible: 'flexible',
  followUs: 'Follow us',
  forgotPassword: 'Forgot password',
  forgotPasswordSubtitle: 'We’ll send a link to your email',
  free: 'Free',
  freeApp: 'Free app',
  freezeCard: 'Freeze card',
  freezeCardDescription: 'Freeze your card when compromised \nor not in use.',
  from: 'from',
  fromColon: 'from: ',
  gameMaxPointsEarned: "You've reached the max points.\nCongratulations!",
  gamePointsEarned: 'points',
  generatingAddress: 'Generating deposit address',
  genericErrorDescription: 'Sorry, there was a problem retrieving the data.',
  genericErrorTitle: 'Oops, something went wrong.',
  giveButton: 'Give',
  giveHeader: 'Total given',
  goldenTicketCodeValidation: 'This code is invalid',
  goldenTicketCodeValidationAlreadyRedeemed: 'This code was already redeemed',
  goldenTicketInfoHeader: 'Want more tickets?',
  goldenTicketInfoSub: 'After a ticket code is redeemed,\na new ticket will appear here',
  goldenTicketInputLabel: 'Code',
  goldenTicketKycPendinDescription: '*Once your friend is approved, your points will appear in your account.',
  goldenTicketModalModalListItem1:
    'Golden Tickets are exclusive codes that will allow you to jump The Queue and get inside the app to start using it.',
  goldenTicketModalModalListItem2:
    'Only users who are already inside the app (not in The Queue) will be able to give you a Golden Ticket.',
  goldenTicketModalModalListItem3: `Try to find someone already using ${APP_NAME} who's willing to give you a Golden Ticket (they only will have a few).`,
  goldenTickets: 'Golden tickets',
  goldenTicketSeeAllRedeemedButton: 'See all redeemed tickets',
  goldenTicketShareButton: 'Share golden ticket',
  goldenTicketShareDescription: 'Share codes with your friends (in the US)\n to let them skip the waitlist.',
  goldenTicketShareTheLove: 'Share the love',
  goldenTicketTitle: 'share golden tickets',
  goToInbox: 'Go to Inbox',
  goToLogin: 'Go to Login',
  hasAccountButton: 'Already have an account',
  help: 'Help',
  helpAccount: 'Account Access / Login / 2FA',
  helpAttachmentsLabel: 'Add Attachment',
  helpCryptoDepositsWithdrawals: 'Crypto Deposits / Withdrawals',
  helpDescriptionLabel: 'Description',
  helpKYC: 'Face or Document Verification (KYC)',
  helpLoansBorrowing: 'Loans / Borrowing',
  helpMessageSent: 'Message Sent',
  helpOther: 'Other / General',
  helpProductSuggestion: 'Product Idea / Suggestion',
  helpRegistrationWaitlist: 'Registration / Waitlist',
  helpSecurity: 'Security Concerns / Account Compromised',
  helpSendLabel: 'Send',
  helpSubjectLabel: 'Subject',
  helpTopicLabel: 'Topic',
  howMuchBorrow: 'How much do you want to borrow?',
  howMuchLend: 'How much do you want to lend?',
  iAcceptButton: 'I accept',
  iDontAcceptButton: 'I don’t accept',
  inboxButton: 'Go to inbox',
  incorrectTOTPCode: 'Incorrect Two FA Code',
  initiated: 'Initiated',
  insufficientFunds: 'Insufficient funds',
  interest: 'interest',
  interestDisclaimer: '*Anticipated interest per month',
  interestPerMonth: 'interest / month',
  introBankBody: "This is where you'll see all your money and all the different assets you hold.",
  introBankTitle: 'Bank',
  introBorrowBody: 'Borrow money without selling your crypto. See all your loans here.',
  introBorrowTitle: 'Borrow',
  introEarnBody: "See all the money you're making with your deposits. Start earning automatically.",
  introEarnTitle: 'Earn',
  introSpendBody: 'Activate a debit card to start spending your money. Earn 1% BTC back on every purchase.',
  introSpendTitle: 'Spend',
  invalidAddress: 'Invalid address',
  invalidCharsValidation: 'Invalid characters',
  invalidCode: 'Invalid Code',
  invalidCodeTOTP: 'Invalid or expired authenticator code',
  invalidDOB: 'You must be at least 18',
  invalidEmail: 'Invalid email address',
  invalidJurisdictionMessage: `We are working actively to make it accessible to everyone.`,
  invalidJurisdictionPartOne: 'Sorry, this is only\n available to ',
  invalidJurisdictionPartTwo: 'US residents.',
  invalidJurisdictionTitle: 'We’re currently open to US residents only. International access coming soon!',
  invalidLogin: 'Invalid Email or Password',
  invalidPassword: 'Invalid Password',
  invalidPhoneNumber: 'Invalid phone number',
  invalidSSN: 'Invalid TIN',
  invalidTermsState: 'Please tick the box before scrolling.',
  inviteErrorDuplicate: 'Already invited',
  inviteErrorGeneric: 'Invite failed',
  inviteServiceUnavailable: 'Invite Service Unavailable',
  iosApp: 'iOS app',
  kycAddressStartDate: 'Start Date',
  kycAddressSubtitle: 'Where do you live?',
  kycAddressTitle: 'Address',
  kycAddressUpdateError: 'Failed to update address',
  kycAgreementsAnd: ' and ',
  kycAgreementsPrivacyPolicy: 'Privacy Policies',
  kycAgreementsStart: 'By continuing, you agree with all',
  kycAgreementsTerms: 'Terms',
  kycApprovedSubtitle: 'Your identity has been verified,\nenjoy your account.',
  kycApprovedTitle: 'Data confirmed.',
  kycBuildingNumber: 'Building Number',
  kycBuildingNumberOrName: 'Building Number or Name',
  kycCitizenship: 'Citizenship',
  kycCity: 'City',
  kycDateOfBirth: 'Date of birth',
  kycDocDrivingLicense: "Driver's License",
  kycDocIdentityCard: 'National Identification Card',
  kycDocPassport: 'Passport',
  KYCDocumentPhotoBackOfDocumentSubtitle: 'Position the document horizontally in the frame on a dark background.',
  KYCDocumentPhotoBackOfDocumentTitle: 'Back of document',
  KYCDocumentPhotoDocumentConfirm: 'Is the photo clear and readable?',
  KYCDocumentPhotoFrontOfDocumentSubtitle: 'Position the document horizontally in the frame on a dark background.',
  KYCDocumentPhotoFrontOfDocumentTitle: 'Front of document',
  KYCDocumentPhotoSelfieConfirm: 'Is your picture clear?',
  KYCDocumentPhotoSelfieSubtitle: 'Position face in oval and take a picture',
  KYCDocumentPhotoSelfieTitle: 'Selfie identification',
  kycEmailConfirm: 'Email confirmed.',
  kycErrorContactUs: 'contact us',
  kycErrorForHelp: ' if you have any questions.',
  kycErrorPlease:
    'We’ll notify you when everything is approved (usually within 24 hours).\nThanks for your patience!\n\nFeel free to ',
  kycFlatNumber: 'Flat Number',
  kycIntoList1: 'Full Address',
  kycIntoList2: 'Phone Number',
  kycIntoList3: 'Name and Date of Birth',
  kycIntoList4: 'Tax Identification Number',
  kycIntoList5: 'Identification Document',
  kycIntoList6: 'Selfie',
  kycIntroBody: `Before depositing money in your ${APP_NAME} wallet, we need to get some extra information about yourself:`,
  kycIntroSubtitle: 'Last formality',
  kycMessageCodeHeader: `${APP_NAME}`,
  kycMessageCodeSubheader: `Your ${APP_NAME} code is 360 450. It will expire in 10 minutes.`,
  kycPersonalVerificationSubtitle: 'A couple more things...',
  kycPersonalVerificationTitle: 'Personal verification',
  kycProgressSubtitle: "Approval may take some time.\nPlease don't close this screen.",
  kycProgressTitle: 'Verification in progress.',
  kycReasonBank: "Once you've verified, you can get access to all functionality.",
  kycReasonBorrow: 'Once you’ve verified your identity, you can start borrowing money.',
  kycReasonDeposit: 'Once you’ve verified your identity, you can start making deposits.',
  kycReasonWithdraw: 'Once you’ve verified your identity, you can start withdrawing money.',
  kycRejectedSubtitle: 'Please check your email for details',
  kycRejectedTitle: 'Thanks for submitting your information!',
  kycResidencePermit: "Resident's Permit",
  kycResubmitSubtitle: 'Please contact us to get help.',
  kycResubmitTitle: 'Your verification needs more information',
  kycSelfieFailed: 'Failed to recognize a person, please try again',
  kycSocialSecurity: 'Social Security Number (or TIN)',
  kycSocureCaptureFailedMessage:
    'It looks like we couldn’t automatically detect your ID, so now you can just snap a photo of it. Apologies.',
  kycSocureCaptureTryAgain:
    'There was an issue automatically detecting your ID, please try again or try using a passport instead.',
  kycSSNExplanation: `${APP_NAME} uses US government issued Tax Identification Numbers (Social Security Number or equivalent TIN) to verify your identity and reduce fraud. We do not perform credit checks. Tax Identification Numbers are encrypted and transferred and stored securely and separately from all other personal data.`,
  kycSSNSubtitle: 'Please provide a valid SSN\nor equivalent TIN',
  kycSSNTitle: 'Social Security Number',
  kycStartSubtitle: 'You may be asked to provide your:',
  kycStartTitle: 'Access all features by\nverifing your identity.',
  kycStepConfirmField1: `Full Name`,
  kycStepConfirmField2: `Date of Birth`,
  kycStepConfirmField3: `Social Security Number`,
  kycStepConfirmField4: `Address`,
  kycStepConfirmSubmessage: `Make sure your information matches your verification doc. You won't be able to change it later.`,
  kycStepConfirmSubtitle: `Carefully check your details.`,
  kycStepConfirmTitle: 'Correct Information?',
  kycStepDateOfBirth: 'Name and date of birth',
  kycStepDOBSubtitle: "Let's get started...",
  kycStepDOBTitle: 'Personal verification',
  kycStepFiveOption1: 'Take Selfie',
  kycStepFiveSubtitle: "Verify it's you",
  kycStepFiveTitle: 'Selfie',
  kycStepFourOption1: 'Identification card',
  kycStepFourOption2: "US Driver's license",
  kycStepFourOption3: 'Passport',
  kycStepFourOption4: "Resident's permit",
  kycStepFourSubtitle: 'Please choose a document type.',
  kycStepFourTitle: 'ID Document',
  kycStepFullAddress: 'Full address',
  kycStepFullAddressUS: 'Full US address',
  kycStepIDDocument: 'ID document, Selfie',
  kycStepNotRequired: '*might not be required',
  kycStepOneDirections: 'You will be asked to provide:',
  kycStepOneSubtitle: 'A couple more things...',
  kycStepOneTitle: 'What we need',
  kycStepPhoneNumber: 'Phone number',
  kycStepSelfie: 'Selfie',
  kycStepSSN: 'Social Security Number',
  kycStepThreeField1: 'Phone number',
  kycStepThreeField2: 'Date of birth',
  kycStepThreeField3: 'Citizenship',
  kycStepThreeField4: 'Social security number',
  kycStepThreeSubtitle: 'A couple more things...',
  kycStepThreeTitle: 'Personal verification',
  kycStepTwoField1: 'Address',
  kycStepTwoField2: 'Apt ・ Suite ・ Other',
  kycStepTwoField3: 'Zip code',
  kycStepTwoField4: 'City',
  kycStepTwoField5: 'Country',
  kycStepTwoField6: 'State',
  kycStepTwoSubtitle: 'Where do you live?',
  kycStepTwoTitle: 'Address',
  kycStreet: 'Street',
  kycSubtitleAddress: 'Enter your current address',
  kycSubtitleDocument: 'Please choose a document type.',
  kycSubtitlePersonalDetails: 'A couple more things...',
  kycTitleAddress: 'Address',
  kycTitleDocument: 'ID Document',
  kycTitlePersonalDetails: 'Personal Details',
  kycTown: 'Town',
  kycUploadAgain: 'Upload again',
  kycUploadErrorSSN: 'Tax Identification Number invalid or already in use.',
  kycUploadErrorSubtitle: 'Please try again.',
  kycUploadErrorTitle: 'Oops, there was an issue during the upload.',
  kycUploadSubtitle: 'Approval may take some time.',
  kycUploadTitle: 'Uploading identity information…',
  kycUSCountryRequired: 'Available only for the US',
  kycUserDetailsError: 'Failed to update KYC information',
  kycUSOnlyConfirmation: 'Yes, I am a US resident',
  kycUSOnlyMakeAvailable: 'Make it accessible in your country',
  kycUSOnlyTitle: 'Sorry, this is only\navailable to US residents.',
  kycUSOnlyTitleHighlight: 'US residents.',
  kycVerifyPhoneChange: 'Change phone number',
  kycVerifyPhoneCode: 'Send code again in 30 seconds',
  kycVerifyPhoneCode1: 'Send code again in 14 seconds',
  kycVerifyPhoneHeader: 'Verify your phone number',
  kycVerifyPhoneSubheader: 'Code sent to +1 (801) 450-6598',
  kycVerifyPhoneSubheader1: 'If you do not receive a text, click here.',
  kycZipCode: 'Zip Code / Postcode',
  label: 'label',
  labelCapitalized: 'Label',
  lastNameField: 'Last Name',
  laterButton: 'Not now',
  lend: 'lend',
  limit: 'limit',
  limitsCardTransactions: 'Card transactions',
  limitsDaily: 'Daily',
  limitsDepositFromCard: 'deposit from debit/credit cards',
  limitsDepositFromExternalAch: 'ACH deposit\n(initiated from another bank)',
  limitsDepositFromInternalAch: `ACH deposit\n(initiated from ${APP_NAME})`,
  limitsDepositFromSavings: `transfer from ${EARN_ACCOUNT_CODENAME} account`,
  limitsMonthly: 'Monthly',
  limitsNoLimits: 'No limits',
  limitsRemaining: 'Remaining: {{value}}',
  limitsWeekly: 'Weekly',
  limitsWireTranfers: 'wire transfer\n(deposit and withdrawal)',
  limitsWithdrawalFromExternalAch: 'ACH withdrawal\n(initiated from another bank)',
  limitsWithdrawalFromInternalAch: `ACH withdrawal\n(initiated from ${APP_NAME})`,
  limitsWithdrawalToSavings: `transfer to ${EARN_ACCOUNT_CODENAME} account`,
  linkSent: 'We sent you a link!',
  liquidation: 'liquidation',
  liquidationPrice: 'Liquidation price',
  liquidationRatioValue: 'Liq. ratio {{value}}%',
  loan: 'Loan',
  loanAwaitingDepositDescription: 'Check status in your account or activity feed.',
  loanAwaitingDepositTitle: "We're waiting for your deposit",
  loanBannerSubtitle: 'With your deposit, borrow up to your max available.',
  loanBannerTitle: 'Borrowing now available!',
  loanBorrowBarExpandLeft: 'Liquidation',
  loanBorrowBarExpandRight: 'Bank',
  loanBorrowBarLeft: 'Owed',
  loanBorrowBarRight: 'Max',
  loanCalculate: 'Calculate how much you can borrow based on the deposit amount.',
  loanCalculateAssetHeader: 'Select an asset',
  loanConfirmPaymentClose: 'This loan will be closed out',
  loanDetailFullTerms: 'Full terms',
  loanDetailInterestSchedule: 'Interest Schedule',
  loanDetailMakePayment: 'Make a Payment',
  loanDetailMoneyOwed: 'Money Owed',
  loanDetailsField1: 'Status',
  loanDetailsField2: 'Principal',
  loanDetailsField3: 'Interest',
  loanDetailsField3Flexible: "Today's rate",
  loanDetailsField3Subtitle: 'This rate will change',
  loanDetailsField4: 'Due by',
  loanDetailsOwed: 'Owed',
  loanDetailsTerms: 'Full terms',
  loanDetailVariableLength: 'Variable',
  loanExpandField1: 'Loans',
  loanExpandField2: 'Max available',
  loanExpandField3: 'Liquidation point',
  loanExpandField4: 'Total bank balance',
  loanHeader: 'Available to borrow',
  loanLengthBottomTitleFixed: 'Fixed Interest Rate',
  loanLengthBottomTitleVariable: 'Current Interest Rate',
  loanLengthDueDate: 'Due date',
  loanLengthFixedInfoBottom: 'Pay principle + interest by due date.',
  loanLengthFixedInfoTop: 'No monthly payments.',
  loanLengthInfo:
    'The length of your contract will impact the interest rate. Shorter periods tend to increase the rate.',
  loanLengthTitleFixed: 'How long do you want to borrow {{amount}}?',
  loanLengthTitleFixedHighlight: 'How long',
  loanLengthTitleVariable: 'Pay back your {{amount}} loan whenever you want.',
  loanLengthTitleVariableHighlight: 'Pay back',
  loanLengthTotalInterest: 'Total Interest',
  loanLengthVariableInfoBottom: 'This rate is subject to change.',
  loanLengthVariableInfoTop: 'This is a variable interest rate loan.',
  loanLiquidDirections: 'If what you borrow falls below this point, you will incur a fee.',
  loanLiquidLeftColumn: 'Balance',
  loanLiquidLeftColumnBottom: 'Total balance',
  loanLiquidRightColumn: 'Liquidation',
  loanLiquidRightColumnBottom: 'Liquidation point',
  loanLiquidWarning:
    'If you go over your limit for 48+ hours you will incur fees ($40). You will be alerted before that.',
  loanListHeader: "What I've Borrowed",
  loanMaxDirections:
    'Your max available is the most your can borrow. This is calculated based on your bank account balances and the credit to value ratio with each asset.',
  loanMaxLeftColumn: 'Balance',
  loanMaxLeftColumnBottom: 'Total Balance',
  loanMaxRightColumn: 'Max',
  loanMaxRightColumnBottom: 'Total Borrow',
  loanMaxWarning: 'If you go over your limit for 48+ hours you will incur fees ($40). You will be alerted before that.',
  loanNotFound: 'Sorry, loan not found',
  loanOpenCalculator: 'Open calculator',
  loanPast: 'Closed',
  loanPendingConfirmationDescription: 'Check status in your account or activity feed.',
  loanPendingConfirmationTitle: "You're deposit is pending confirmation",
  loanPendingDescription: 'Loan will be deposited into accounts within 24 hours.',
  loanPendingTitle: 'USD loan is pending',
  loanPeriod: 'loan period',
  loanRepaymentDepositPrompt: 'You need {{assetCode}} in your wallet to start the payment process.',
  loanRequestHeader: 'Choose your amount',
  loanReviewAcceptTermsPart1: 'I have read and accept ',
  loanReviewAcceptTermsPart2: 'the terms',
  loanReviewCollateralLocked: 'Collateral locked',
  loanReviewDueBy: 'Due by',
  loanReviewFlexible: 'Flexible',
  loanReviewFlexiblePayback: 'Pay back whenever you want',
  loanReviewInterest: 'Interest',
  loanReviewInterestVariable: "Today's rate",
  loanReviewInterestVariableSubtitle: 'This rate will change',
  loanReviewPrincipal: 'Principal',
  loanReviewTotalDue: 'Total due',
  loanReviewVariableRate: 'Variable',
  loans: 'loans',
  loansListHeader: "What I've borrowed",
  loansListLoans: 'Loans',
  loanStatusClosedDetail: 'Loan repaid',
  loanStatusOpenDetail: 'Money sent to your account',
  loanStatusPendingDetail: 'This may take up to 24 hours',
  loanStatusRejectedDetail: 'Loan request denied',
  loanTitle: 'Title',
  loanTypeOptionsFixedRateTitle: 'Fixed-rate loan',
  loanTypeOptionsFromRate: 'Rates fixed, starting at',
  loanTypeOptionsTermLength: 'Term length:',
  loanTypeOptionsTitle: 'What type of loan\nwould you like',
  loanTypeOptionsTitleHighlight: 'type of loan',
  loanTypeOptionsVariableInfo: 'Rates change, currently',
  loanTypeOptionsVariableSubtitle: 'Term length: flexible',
  loanTypeOptionsVariableTitle: 'Variable-rate loan',
  loanUSDTitle: 'USD Loans',
  loanWalletAwaiting: 'Waiting to receive funds',
  loanWalletReceived: 'Received in your wallet',
  loanZeroStateDescription: 'Deposit assets, then take out a loan against your collateral. Simple.',
  loanZeroStateTitle: "Alright, let's make\nsome magic.",
  locationBasedSecurity: 'Location-based security',
  locationBasedSecurityDescription: 'We’ll use your location to help prevent\nfraudulent transactions. Turn GPS on.',
  locked: 'locked',
  lockedCollateral: 'Locked collateral',
  login: 'Login',
  loginButton: 'Login',
  loginField1: 'Email',
  loginField2: 'Password',
  loginForgotPassword: 'Forgot password?',
  loginHeaderForgotPassword: 'Forgot Password',
  loginHeaderResetPassword: 'Reset Password',
  loginNoAccount: "Don't have an account?",
  loginPasswordSubmissionHeader: "We've sent you a link!",
  loginPasswordSubmissionRequest: 'You can request another one in 00:45',
  loginPasswordSubmissionSubheader: 'Check your email and click on the reset password link.',
  loginPasswordSubmissionTryAgain: "Didn't receive it? Try again.",
  loginResetConfirmation: 'Password Reset.',
  loginResetPasswordField1: 'New password',
  loginResetPasswordField2: 'Confirm password',
  loginTitle: 'Login',
  max: 'max',
  maxAddressLength: 'max {{length}} characters',
  maxAvailableWithdraw: 'Max available\nto withdraw',
  middleNameField: 'Middle Name',
  modalActivateCardLast4Digits: 'Insert the 4 last digit of your card',
  modalCountryPickerChoose: 'Choose your country',
  modalEmailConfirmed: 'Email confirmed.',
  modalFindInviteText: `Golden Tickets are exclusive codes that allow you to jump The Queue and get inside the app to start using it. Only users who are already inside the app (not in The Queue) can give you a Golden Ticket. Try to find someone already using ${APP_NAME} who's willing to give you a Golden Ticket (they only have a few).`,
  modalFindInviteTitle: 'Golden Ticket',
  modalGoldenTicket: 'ways to get more Golden Tickets',
  modalGoldenTicketBorrow: 'Borrow $500+',
  modalGoldenTicketBorrowText: 'Earn a Golden Ticket each time you borrow (get a loan) worth $500+.',
  modalGoldenTicketEarn: 'Deposit $500+',
  modalGoldenTicketEarnText: 'Earn a Golden Ticket each time you deposit $500+ (and keep it ther for a week+)',
  modalGoldenTicketInvite: 'Invite 3 people',
  modalGoldenTicketInviteText: 'Earn a Golden Ticket when you invite 3 people and they join the waitlist.',
  modalGoldenTicketSpend: 'Spend $500+',
  modalGoldenTicketSpendText: 'Earn a Golden Ticket for each $500 that you spend on your card (cumulative)',
  modalInviteFriends: 'Invite friends',
  modalInviteFriendsAccepted: 'Accepted invites',
  modalInviteFriendsEmailsSent: 'Emails sent',
  modalInviteFriendsField1: 'Email',
  modalInviteFriendsJumps: 'Spots jumped',
  modalInviteSubtitle: 'Jump 250 spots when you invite someone and they join The Queue.',
  modalStatePickerChoose: 'Choose your State',
  modalVerifyAuthCodeInvalid: 'Invalid code, please try again.',
  modalVerifyEmailChange: 'Change email',
  modalVerifyEmailCode: 'Send code again in {{count}} seconds',
  modalVerifyEmailCodeSendAgain: 'Send again',
  modalVerifyEmailHeader: 'Verify your email',
  modalVerifyEmailSendFailed: 'Failed to send verifaction email',
  modalVerifyEmailSubheader: 'Code sent to {{email}}',
  month: 'month',
  months: 'months',
  more: 'More',
  more2FAActivated: 'Activated',
  more2FACode: 'Code',
  more2FARemove: 'Remove',
  more2FATitle: 'Set-up two-factor authentication',
  moreAddresses: 'Withdrawal addresses',
  moreBasic: `${APP_NAME} Basic`,
  moreCashAccount: 'Card / Cash account',
  moreChangeButton: 'Change',
  moreContactField1: 'Name',
  moreContactField2: 'Email',
  moreContactField3: 'Message',
  moreContactSend: 'Send',
  moreEarn: 'Earn',
  moreEmailChange: 'Change email',
  moreEmailChanged: 'Email changed.',
  moreEmailField1: 'New email',
  moreEmailSave: 'Save',
  moreFriends: 'Invite your friends',
  moreFriendsAdSubtitle: 'Invite friends',
  moreFriendsAdTitle: 'Invite your friends and get upgraded to a premium account.',
  moreGame: 'Play the game',
  moreHeader: 'More',
  moreHelp: 'Help',
  moreHelpField1: 'Getting started',
  moreHelpField2: 'Transaction issues',
  moreHelpField3: 'Updating profile info',
  moreHelpField4: 'Account issues',
  moreHelpField5: 'Send us a message',
  moreHelpField6: 'Chat with us',
  moreHelpTitle1: 'FAQ',
  moreHelpTitle2: 'Contact Us',
  moreHelpTitle3: 'Follow us',
  moreLegal: 'Legal',
  moreLimits: 'Limits',
  moreLiquidation: 'Liquidation',
  moreLiquidationField1: 'Email',
  moreLiquidationField2: 'Push notifications',
  moreLiquidationField3: 'SMS',
  moreLiquidationField4: 'Password',
  moreLiquidationField5: 'Email code',
  moreLiquidationField6: 'SMS code',
  moreLiquidationField7: '2FA',
  moreLiquidationField8: 'Wait 24 hours',
  moreLiquidationField9: 'Face ID',
  moreLiquidationSubtitle1: 'Everytime you withdraw',
  moreLiquidationSubtitle2: 'You need at least 3 options.',
  moreLiquidationTitle1: 'Notifications',
  moreLiquidationTitle2: 'Security',
  moreLogin: 'Login',
  moreLoginField1: 'Password',
  moreLoginField2: 'Email code',
  moreLoginField3: 'SMS code',
  moreLoginField4: '2FA',
  moreLoginField5: 'Face ID',
  moreLoginSubtitle1: 'When logging in',
  moreLoginTitle1: 'Security',
  moreNotifications: 'Turn On Notifications',
  moreNotificationsField1: 'Email',
  moreNotificationsField2: 'SMS',
  moreNotificationsField3: 'Push notifications',
  moreNotificationsText:
    'General notification settings. You can set up specific notifications for deposit, withdraw, liquidation etc.',
  morePasswordChange: 'Change password',
  morePasswordChanged: 'Password changed.',
  morePasswordField1: 'New password',
  morePasswordField2: 'Confirm password',
  morePasswordSave: 'Save',
  moreProfileEdit: 'Need to edit? Contact us.',
  moreSecurity: 'Security',
  moreSecurity2FA: 'Authenticator app',
  moreSecurityEmail: 'Email',
  moreSecurityPassword: 'Password',
  moreSecurityPhone: 'Phone number',
  moreSeeProfile: 'See profile',
  moreTerms: 'Terms & conditions',
  moreUpgrade: 'Upgrade account',
  moreWithdraw: 'Withdraw',
  moreWithdrawField1: 'Email',
  moreWithdrawField2: 'Push notifications',
  moreWithdrawField3: 'SMS',
  moreWithdrawField4: 'Password',
  moreWithdrawField5: 'Email code',
  moreWithdrawField6: 'SMS code',
  moreWithdrawField7: '2FA',
  moreWithdrawField8: 'Wait 24 hours',
  moreWithdrawField9: 'Face ID',
  moreWithdrawSubtitle1: 'Everytime you withdraw',
  moreWithdrawSubtitle2: 'You need at least 3 options.',
  moreWithdrawTitle1: 'Notifications',
  moreWithdrawTitle2: 'Security',
  needs3Options: 'You need at least 3 options.',
  networkErrorMessage: 'Please check your network connection',
  networkErrorTitle: 'Mobile network not available',
  newAmount: 'New Amount',
  newPassword: 'New Password',
  next: 'Next',
  nextButton: 'Next',
  noAccountButton: "Don't have an account",
  noAddresses: 'You don’t have any addresses. Add an address to get started!',
  noAssetsAvailable: 'No assets available.',
  noButton: 'No',
  nodeNotFoundDesc: 'Sorry, there was a problem loading this {{nodeType}}.',
  nodeNotFoundTitle: '{{nodeType}} not found.',
  noGoldenTickets: 'No Golden Tickets available right now.',
  noPhoneNumber: 'No phone number',
  notActive: 'Not Active',
  notification1Header: 'Loan activated',
  notification1Subheader: '$500 deposited in your USD bank account.',
  notification2Header: 'USDC loan activated',
  notification2Subheader: '3,000 USDC deposited into your USDC account.',
  notification3Header: `${APP_NAME}`,
  notification3Subheader: `Your ${APP_NAME} code is 7804. It will expire in 10 minutes.`,
  notifications: 'Notifications',
  notificationsAcceptButton: 'Turn on notifications',
  notificationsButton: 'Turn on notifications',
  notificationsDescription:
    'General notification settings. You can set up specific notifications for deposit, withdraw, liquidation etc.',
  notificationsHeadersHighlight: 'Want to be notified',
  notificationsOn: 'You will be notified when you get approved',
  notYetButton: 'Not yet',
  numbersValidation: 'Amount must be a valid number',
  oauthAuthorizeButton: 'Authorize',
  oauthDenyButton: 'Deny',
  oauthViewAccounts: 'View your account numbers and balances',
  oauthViewPeronsalInfo: 'View your personal information including your name, email, and address',
  oauthViewTransactions: 'View all transactions',
  obtainCredit: 'Obtain a credit of',
  ok: 'OK',
  onlineTransactions: 'Online transactions',
  onlineTransactionsDescription: 'Internet transactions can be high-risk.\nTurn them off for added security.',
  open: 'open',
  openCashAccountButton: 'Open my cash account',
  openCashAccountButtonZero: 'Set Up Card',
  openLoansTitle: '{{assetCode}} Loans',
  other: 'other',
  owed: 'owed',
  paid: 'paid',
  password: 'Password',
  passwordChanged: 'Password Changed',
  passwordInvalid: 'Must be at least 12 characters',
  passwordResetConfirmationDescriprion: `Open the ${APP_NAME}\nmobile app to continue.`,
  passwordResetConfirmationTitle: 'Your password has been reset.',
  passwordResetSuccess: 'Your password was succesfully reset!',
  passwordValidation: 'Passwords must match',
  pay: 'pay',
  payback: 'payback',
  paybackCreditLine: 'Credit line',
  paybackOnly: 'Payback Only',
  paybackOnlyAsset: 'This asset is only avalable for payback deposits.',
  payment: 'payment',
  paymentButton: 'Make a payment',
  paymentComplete: 'Payment complete.',
  paymentConfirmField1: 'From',
  paymentConfirmField2: 'To',
  paymentConfirmField3: 'Payment',
  paymentConfirmField4: 'Total paid',
  paymentFullSubtitle: 'Principal + interest',
  paymentFullTitle: 'Loan paid in full. It will be closed.',
  paymentNoFundsMessage: 'You need USDC in your wallet to start the payment process.',
  paymentStep1Header: 'How much of this loan would you like to pay?',
  paymentStep1HeaderHighlight: 'How much',
  paymentStep1Subtitle: 'Balance: {{value}} {{asset}}',
  paymentStep1Title: 'From: {{asset}} account',
  paymentStep2Header: 'Confirm the payment.',
  paymentStep2HeaderHighlight: 'Confirm',
  paymentUSDCHeader: 'How much of USDC Loan 1 would you like to pay?',
  payoff: 'payoff',
  pending: 'Pending',
  periodMonth: 'Month',
  periodMonthInitial: 'M',
  periodWeek: 'Week',
  periodWeekInitial: 'W',
  periodYear: 'Year',
  periodYearInitial: 'Y',
  phoneAlreadyExistsError: 'This phone number is already in use',
  phoneField: 'Phone',
  phoneNumber: 'Phone number',
  phoneNumberChanged: 'Phone Number Changed',
  phoneWalletInfoDescription1: 'This automatic feature is not yet available but ',
  phoneWalletInfoDescription2: ' and copy/paste your card details.',
  phoneWalletInfoDescriptionHighlight: 'you can manually add your card if you go to your phone wallet',
  phoneWalletLabelApple: 'Add to Apple Wallet',
  phoneWalletLabelGoogle: 'Add to Google Pay',
  phoneWalletLabelSamsung: 'Add to Wallet',
  phoneWalletOpenWalletIOS: 'Open wallet app',
  physicalCardActivate1: 'Received your card?',
  physicalCardActivate2: 'Activate it here.',
  physicalCardBannerActivateText: 'Received your card? Activate it now.',
  physicalCardBannerText: 'Get your physical card and start swiping it!',
  playgroundHowMuch: 'How much do you want to borrow?',
  pleaseAuthenticate: 'Please Authenticate',
  points: 'points',
  premium: 'Premium',
  price: 'price',
  profile: 'Profile',
  profileAddress: 'Address',
  profileEmail: 'Email',
  profileName: 'Name',
  profilePhone: 'Phone',
  profileSubtitle: 'Let us know about yourself.',
  pushNotifications: 'Push notifications',
  pushNotificationsAlert: 'Turn on push notifications to be alerted when you are approved.',
  queryErrorTitle: 'Oops, something went wrong',
  rankOptions: 'Rank the following options from most to least interested',
  readTermsButton: 'Read terms',
  recommended: 'recommended',
  redeemedTicketsHeader: 'Redeemed Tickets',
  rejected: 'Rejected',
  remove: 'Remove',
  repaymentCloseLoan: 'Close out loan',
  repaymentConfirmHeader: 'Confirm the payment.',
  repaymentConfirmHighlight: 'Confirm',
  repaymentEditValueHighlight: 'How much',
  repaymentEditValueTitle: 'How much would you\nlike to pay?',
  repaymentErrorNoWallet: 'No wallet found for this loan',
  repaymentErrorTitle: 'Repayment not yet available',
  repaymentHeader: 'Repay Loan',
  repeatPassword: 'Repeat Password',
  requestAccess: 'Request access',
  requestAccessButton: 'Request access',
  requestAccessCreateAccountSubtitle: "Let's get you set up.",
  requestAccessCreateAccountTerms: 'By creating an account (clicking Next), you agree to our',
  requestAccessCreateAccountTermsPrivacy: 'Privacy Policy',
  requestAccessCreateAccountTermsTerms: 'Terms',
  requestAccessCreateAccountTitle: 'Create your account',
  requestAccessEmailExists: 'This email address is already registered.',
  requestAccessGameDirections: 'Tap the logos before they turn red.',
  requestAccessGameMax: "You've reached your daily max.\nCome back tomorrow!",
  requestAccessGameSpots: 'Spots jumped',
  requestAccessProfileSubtitle: 'Please use your full legal names as displayed on your ID documents.',
  requestAccessProfileTitle: 'Profile',
  requestAccessQueueFindSubtitle: 'Coming soon...',
  requestAccessQueueFindTitle: 'Golden Ticket',
  requestAccessQueueGameSubtitle: 'Jump 100 spots daily.',
  requestAccessQueueGameTitle: 'Play the game',
  requestAccessQueueHeader: "Yay! You're in the queue.",
  requestAccessQueueHeaderHighlightedWord: 'Yay!',
  requestAccessQueueHeaderYay: 'Yay!',
  requestAccessQueueInviteSubtitle: 'Jump 250 spots everytime.',
  requestAccessQueueInviteTitle: 'Invite a friend',
  requestAccessQueueOutOf: 'out of {{value}} people.',
  requestAccessQueueTitle: 'Want to jump ahead in The Queue?',
  requestAccessRankingDescription: 'Rank the following options from most important to least important.',
  requestAccessStep3Field1: 'First name',
  requestAccessStep3Field2: 'Middle name',
  requestAccessStep3Field3: 'Last name',
  requestAccessStep3Field4: 'Country of residence',
  requestAccessStep3Field5: 'State',
  requestAccessSubtitle: 'Tell us more abour your plans.',
  requestAccessSurveyDirections: 'Rank the following from most important to least important.',
  requestAccessSurveyOptionFive: 'Billboard',
  requestAccessSurveyOptionFour: 'Twitter or LinkedIn',
  requestAccessSurveyOptionOne: 'Friends or family member',
  requestAccessSurveyOptionSeven: 'Other',
  requestAccessSurveyOptionSix: 'Article or TV segment',
  requestAccessSurveyOptionThree: 'Event or meetup',
  requestAccessSurveyOptionTwo: 'Colleague or work channels',
  requestAccessSurveySubtitle: 'How did you hear about us?',
  requestAccessTitle: 'Request Access',
  requestAccessWaitlistSpotsDown: 'down {{value}} spots',
  requestAccessWaitlistSpotsUp: 'up {{value}} spots',
  requestAccessWebDownloadAppSubtitle:
    'Now you need to dowload the mobile app to continue.\n\nAfter installing the app, press the login button and enter your email and password.',
  requestAccessWebDownloadAppTitle: 'Thanks for signing up.',
  requestLoanScreenTitle: 'Choose your amount',
  requiredField: 'This field is required',
  resend: 'Resend',
  resetButton: 'Reset',
  resetPassword: 'Reset Password',
  retake: 'Retake',
  retryButton: 'Retry',
  reviewLoanAmount: 'Amount',
  reviewLoanHeader: 'Review the terms.',
  reviewLoanHeaderHighlight: 'terms.',
  reviewLoanInterestRate: 'Interest per month',
  reviewLoanLength: 'Length',
  reviewLoanLoanNotFound: 'Loan not found',
  reviewLoanLoanNotFoundDesc: 'Sorry, we were unable to find the loan: ',
  reviewLoanOriginationFee: 'Origination Fee',
  reviewLoanTermsHeader: 'Please read and accept the terms below before submitting your request.',
  rewardsAccountApprovedTransaction: 'Account approved',
  rewardsAdjustmentTransaction: 'Administrative Adjustment',
  rewardsBalance: 'Balance: {{value}} pts',
  rewardsBalanceReachedTransaction: 'First deposit',
  rewardsBannerButton: 'Get points',
  rewardsBannerContent:
    '• Earn higher APY on deposits\n• Lower borrow rates\n• Get a better debit card\n• Make it easier to earn more points',
  rewardsBannerTitle: 'Get more points and',
  rewardsBoostTransaction: 'Boost action',
  rewardsCalculator1Day: '1 day',
  rewardsCalculator1Month: '1 month',
  rewardsCalculator1Week: '1 week',
  rewardsCalculatorChartFiller: 'Select a value to see the graph.',
  rewardsCalculatorDays: '{{amount}} days',
  rewardsCalculatorDescriptionEarn: 'Keep an Earn balance of',
  rewardsCalculatorDescriptionGoldenTickets: 'And send Golden tickets to',
  rewardsCalculatorDescriptionSpend: 'And spend',
  rewardsCalculatorMonths: '{{amount}} months',
  rewardsCalculatorPercentageValue: '{{amount}}%',
  rewardsCalculatorPoints: '{{amount}} pts',
  rewardsCalculatorThousandPoints: '{{amount}}K pts',
  rewardsCalculatorTitle: 'Rewards calculator',
  rewardsCalculatorToday: 'Today',
  rewardsCalculatorToTargetStatus: 'to {{status}}',
  rewardsCalculatorValueDescriptionGoldenTickets: 'people / month',
  rewardsCalculatorValueDescriptionSpend: '/ month',
  rewardsCalculatorWeeks: '{{amount}} weeks',
  rewardsEarn: 'Earn',
  rewardsEarnBackStatus: 'Earn {{x}}% back with this status.',
  rewardsFirstSpendTransaction: 'First spend',
  rewardsOfPtsAndAbreviation: '{{currentPoints}} of {{points}}pts',
  rewardsOther: 'Misc.',
  rewardsPerksButton: 'Perks',
  rewardsPerksCashBackPercentage: '{{amount}}%',
  rewardsPerksCashBackTitle: 'Cash Back',
  rewardsPerksEarnRatesTitle: 'Earn Rates',
  rewardsPerksPointsTitle: 'Points',
  rewardsPerksTitle: 'Perks',
  rewardsPointsButton: 'Points',
  rewardsPointsDepositCTA: 'For every $1 of interest earned, you receive:',
  rewardsPointsDepositSubtitle: `The more cash or crypto that you deposit with ${APP_NAME} (and let it grow), the more points you receive.`,
  rewardsPointsDepositTitle: 'Deposit',
  rewardsPointsGoldenTicketCTA: 'For every 1 friend approved, you receive:',
  rewardsPointsGoldenTicketTitle: 'Golden ticket',
  rewardsPointsHeaderSubtitle: 'You need {{points}} points to unlock {{status}}.',
  rewardsPointsHeaderTitle: 'Rewards status',
  rewardsPointsInviteCTA: 'For every friend you get',
  rewardsPointsInviteSubtitle: 'Earn points when you invite a friend who opens an account and deposits $100.',
  rewardsPointsInviteTitle: 'Invite',
  rewardsPointsOverPreviousLevel: '{{points}}pts over {{status}}',
  rewardsPointsSpendCTA: 'For every $1 spent, you receive:',
  rewardsPointsSpendSubtitle: `The more you spend with your ${APP_NAME} debit card (physical or virtual), the more points you receive.`,
  rewardsPointsSpendTitle: 'Spend',
  rewardsPointsStatusLabel: '{{name}} status',
  rewardsPointsTitle: 'Get points',
  rewardsPointsToNextLevel: '{{points}}pts to {{status}}',
  rewardsPointsToUnlock: '{{points}} more points to unlock {{status}}',
  rewardsPointsToUnlockYouNeed: 'You need {{points}} more points\nto unlock {{status}}.',
  rewardsPointsYourStatus: 'Your Status',
  rewardsPreview0: 'Get points',
  rewardsPreview1: 'on every swipe',
  rewardsPreview2: 'and deposit.',
  rewardsPreview3: 'Coming soon!',
  rewardsPtsAbreviation: 'pts',
  rewardsPtsAbreviationSingle: 'pt',
  rewardsPtsAndAbreviation: '{{points}}pts',
  rewardsPtsBackPercent: '({{amount}}% back)',
  rewardsPtsBackPercentNoParenthesis: '{{amount}}% BACK',
  rewardsRedeemBottomBarTitle: 'updated REWARDS STATUS',
  rewardsRedeemButton: 'Redeem',
  rewardsRedeemConfirmAccount: '{{name}} account',
  rewardsRedeemConfirmAmountDisclaimer: 'Amounts quoted are approximate and\nsubject to minor fluctuations.',
  rewardsRedeemConfirmButton: 'Confirm',
  rewardsRedeemConfirmTitle: 'Confirm the transfer.',
  rewardsRedeemConfirmTitleHighlight: 'Confirm',
  rewardsRedeemFrom: 'From: Rewards',
  rewardsRedeemFromBalance: 'Balance: {{balance}}pts',
  rewardsRedeemNewStatusChange: 'Your status will change to {{status}}',
  rewardsRedeemNewStatusRemain: 'Your status will remain at {{status}}',
  rewardsRedeemStablecoins: 'Stable Coins',
  rewardsRedeemTitle: 'How many points\ndo you want to reedem?',
  rewardsRedeemTitleAssets: 'Which asset do you want to redeem your {{amount}} to?',
  rewardsRedeemTitleHighlight: 'How many points',
  rewardsRedeemTransactionTitle: 'Redeemed Points',
  rewardsRedeemTransferComplete: 'Transfer completed.',
  rewardsReferral: 'Ticket',
  rewardsReferralTransaction: 'Golden Ticket',
  rewardsSpend: 'Spend',
  rewardsStableCoinSubtitle: 'DAI, USDC, USDT',
  rewardsStarterHeader: 'Boost your points',
  rewardsStarterPerkDeposit: 'Deposit $100 to Earn',
  rewardsStarterPerkSpend: 'Spend $',
  rewardsStatus: '{{value}} status',
  rewardsStatusCardClassicBullet0: '3D Texture',
  rewardsStatusCardClassicBullet1: 'No card details',
  rewardsStatusCardClassicBullet2: 'Contactless',
  rewardsStatusCardPlatinumBullet0: 'Metal card',
  rewardsStatusCardPlatinumBullet1: 'Black foil',
  rewardsStatusCardPlatinumBullet2: 'No card details',
  rewardsStatusCardPlatinumBullet3: 'Contactless',
  rewardsStatusCardPlatinumBullet4: 'Visa premium logo',
  rewardsStatusCardPremiumBullet0: 'Rainbow reflection',
  rewardsStatusCardPremiumBullet1: 'No card details',
  rewardsStatusCardPremiumBullet2: 'Contactless',
  rewardsStatusCardPremiumBullet3: 'Visa premium logo',
  rewardsTimerPreKYC: 'for 24 hrs',
  rewardsTitle: 'Rewards',
  rewardsTitleWithPoints: 'Rewards: {{points}}pts',
  rewardsTotalPoints: 'Points',
  rewardsUserTotalPoints: 'Your total: {{amount}}pts',
  rewardsViewAllTransactions: 'View all transactions',
  rewardTransactionAcceptedInvitation: 'Accepted Invitation',
  rewardTransactionInterestEarned: 'Interest earned',
  rewardTransactionSignedUp: 'Signed Up',
  rewardTransactionWelcome: 'Welcome!',
  safe: 'safe',
  save: 'Save',
  saveButton: 'Save',
  savingsHowMuchDepositTo: `To: ${EARN_ACCOUNT_CODENAME} Account`,
  search: 'Search',
  searchButton: 'Search',
  security: 'Security',
  securityAuthenticator: 'Authenticator app',
  securityAuthenticatorDescription: 'Authy, Google Auth etc.',
  securityCheck: 'Security check.',
  securityField1: 'Password',
  securityField2: 'Email code',
  securityField3: 'SMS code',
  securityField4: 'Face ID',
  securitySettings: 'Security Settings',
  seeWallet: 'See Wallet',
  SelectAddressHighlight: 'Choose which address',
  SelectAddressTitle: 'Choose which address to send your {{assetName}} to.',
  selectLinkedCardHighlight: 'card',
  selectLinkedCardTitle: 'Select a card to deposit from',
  selectLoanAssetHighlight: 'borrow.',
  selectLoanAssetTitle: 'Select the asset you would like to borrow.',
  sendAuthCodeError: 'Sorry, unable to send code',
  sendCodeAgain: 'Send code again',
  sendCodeAgainCount: 'Send code again in {{count}} seconds',
  sendEmail: 'Send E-mail',
  sendNewEmailButton: 'Send new email',
  setUp: 'Set up',
  setupCard: 'Set up Card',
  setupTwoFA: 'Set-up two-factor authentication',
  setupTwoFAAppListSubtitle: "Download an authenticator app if you don't have one already.",
  setupTwoFAAppListTitle: 'Authenticator App',
  setupTwoFAAuthyDescription: 'Easy to use, feature-rich and supports multi-device sync.',
  setupTwoFABackupCodesTitle: 'Save security\nbackup codes',
  setupTwoFABackupCodesTitleHighlight: 'Save',
  setupTwoFACodeInsertionCodeReceivingHint: 'When prompted for codes in the future, this is how you will receive them.',
  setupTwoFACodeInsertionSubtitle: 'Enter the code generated by your app to make sure everything works.',
  setupTwoFACodeInsertionTitle: 'Verify',
  setupTwoFACodeTitle: 'Enter the code generated by your authenticator app',
  setupTwoFACodeTitleHighlight: 'Enter the code',
  setupTwoFACodeViewSubtitle: 'Copy this key into your authenticator app to link your account.',
  setupTwoFACodeViewTitle: 'Link your account',
  setupTwoFAComplete: 'Two-Factor Authentication\nis now setup.',
  setupTwoFADownload: 'Download now',
  setupTwoFADownloadConfirmation: 'Are you sure you downloaded\nan authenticator app?',
  setupTwoFADownloadConfirmationHighlight: 'downloaded\nan authenticator app?',
  setupTwoFADuoDescription: 'Mutiple connections, easy to use, and excellent support.',
  setupTwoFAErrorToRemove: 'There was an error removing the Authenticator app.',
  setupTwoFAGoogleDescription: 'Offline support, easy access, and multi-platform.',
  setupTwoFAHelp: 'I still need help',
  setupTwoFAHintMobile: 'Mobile',
  setupTwoFAHintRecommended: 'Recommended',
  setupTwoFAInsertCode: 'Enter code from app',
  setupTwoFAIntroButton: "Let's get started",
  setupTwoFAIntroText1: `For important actions in ${APP_NAME}, you will be prompted to enter a code to protect your money.`,
  setupTwoFAIntroText2:
    'These codes will come from an external authenticator app and will help keep your account secure.',
  setupTwoFAIntroTitle: 'Set up your Two-Factor\nAuthentication.',
  setupTwoFAIntroTitleHighlight: 'Two-Factor\nAuthentication.',
  setupTwoFAMicrosoftDescription: 'Can be used without wifi and doesn’t require toggling between apps.',
  setupTwoFARemoved: 'Authenticator app removed.',
  setupTwoFARemoveDialog: 'Do you really want to remove the authenticator app link?',
  setupTwoFASubtitle: 'Use this code into your authenticator app (Authy, Google Auth, etc.)',
  setupTwoFAWhereToUse: 'Find out where to use this code',
  share: 'Share',
  shareButton: 'Share',
  shareTwoFA: 'Two Factor Authentication Key {{url}}',
  shipButton: 'Yes, ship',
  signout: 'Log out',
  signOutButton: 'Sign out',
  simulate: 'Simulate',
  singleAsset: '1 asset',
  skipButton: 'Skip',
  smartBannerButton: 'Ok',
  smartBannerError: 'Error sending the SMS',
  smartBannerLabel: 'Phone number',
  smartBannerResponse: 'Message Sent!',
  smartBannerTextMessage: 'Or we can text you a link.',
  smartBannerTextMessageQR: 'Use the QR code to downoad the app',
  sms: 'SMS',
  smsCode: 'SMS Code',
  splashEarnCopy: 'Earn interest on your deposits (crypto or fiat). Your money grows while you sleep.',
  splashRewardsCopy: 'Earn points on all deposits and card swipes. Reedem points for dollars or crypto!',
  splashSpendCopy: `Get rewards on all spending with your ${APP_NAME} debit card. `,
  splashWelcomeCopy: 'The all-in-one financial app for the crypto generation.',
  splashWelcomeTitle: 'Welcome',
  start: 'start',
  startFlowButton: 'Start',
  stateSelector: 'State',
  submit: 'submit',
  submitButton: 'Submit',
  submitConfirmPart1: 'By clicking Submit, you agree to our ',
  swipePayments: 'Swipe payments',
  swipePaymentsDescription: 'If you do not swipe, switch off\nmagnetic stripe for extra security.',
  title: 'Title',
  to: 'to',
  toColon: 'to: ',
  today: 'today',
  tooManyAttempts: 'Access to this account has been temporarily disabled due to many failed login attempts.',
  top: 'Top',
  total: 'total',
  totalCredit: 'total credit',
  touchID: 'Touch ID',
  transferAccountBalance: 'Balance: {{amount}}',
  transferActive: 'transfer methods',
  transferAddCardStep1Header: 'How much would you like to transfer?',
  transferAddCardStep2Header: 'Confirm the transfer.',
  transferButton: 'Transfer',
  transferComplete: 'Transfer complete.',
  transferConfirmButton: 'Confirm Amount',
  transferConfirmField1: 'From',
  transferConfirmField2: 'To',
  transferConfirmField3: 'Amount',
  transferConfirmField4: 'Fees',
  transferConfirmField5: 'Total',
  transferConfirmTitle: 'Transfer amount.',
  transferConfirmTitleHighlight: 'Transfer',
  transferFiat: 'Transfer・USD',
  transferFromSavings: `Transfer from ${EARN_ACCOUNT_CODENAME}`,
  transferHeader: 'Select the destination\nof your transfer.',
  transferHeaderHighlight: 'Select the destination',
  transferSavingsCashErrorBalance: 'Insufficient funds',
  transferSavingsFloatBalError: 'Sorry, there was a problem processing your transfer.',
  transferSignAgreementButton: 'Agree & Confirm Transfer',
  transferTerms: 'Terms of the transfer.',
  transferTermsHighlight: 'Terms',
  transferToSavings: `Transfer to ${EARN_ACCOUNT_CODENAME}`,
  transferUSDCToUSDZeroHashNote: `On confirm, you authorize ${APP_NAME} to instruct Zero Hash (our cryptocurrency services partner) to exchange {{amount}} USDC to \${{amount}} (using a fixed exchange rate of 1 USD = 1 USDC) on your behalf.`,
  transferUSDToUSDCZeroHashNote: `On confirm, you authorize ${APP_NAME} to instruct Zero Hash (our cryptocurrency services partner) to exchange \${{amount}} to {{amount}} USDC (using a fixed exchange rate of 1 USD = 1 USDC) on your behalf.`,
  tryAgain: 'Try again',
  turnOnNotifications: 'Turn on Notifications',
  twoFA: 'two-factor authentication',
  twoFACode: 'Authenticator app code',
  unableToSendEmail: 'There was an error to send the email verification',
  unavailable: 'Unavailable',
  unlinkCardConfirmationButton: 'Remove my card',
  unlinkCardConfirmationDescription:
    'By removing your card, you are unlinking it from your Hightop account and must add it again to use it in the future.',
  unlinkCardConfirmationTitle: 'Are you sure you want to remove your card?',
  unlinkCardConfirmationTitleHighlighted: 'remove',
  unlinkCardSuccess: 'Your card has been removed.',
  unlockButton: 'Unlock',
  unlockScreenSubtitle: 'Welcome back',
  unlockScreenTitle: 'Hi, {{name}}.',
  updateAddressError: 'Error updating the address',
  upgradeAppSubtitle: 'We’ve made some exciting upgrades to our beta version that will improve your experience.',
  upgradeAppTitle: 'Looks like your app\nversion is a little dusty',
  upgradeButton: 'Update now',
  uploadDocument: 'Upload document',
  usdListTitle: 'US Dollar Accounts',
  usDollar: 'US Dollar',
  usePasteValue: 'Use code: {{value}}',
  variable: 'variable',
  verify: 'verify',
  verifyEmail: 'Verify your email',
  verifyEmailSubtitle: 'Code sent to {{email}}',
  verifyMicroDeposits: 'Verify micro deposits',
  verifyPhone: 'Verify your phone number',
  verifyPhoneSubtitle: 'Code sent to {{phone}}',
  virtualCardBillingAddress: 'Billing address',
  virtualCardCardHolder: 'Card holder',
  virtualCardCardNumber: 'Card number',
  virtualCardCVV: 'CVV',
  virtualCardExpDate: 'Exp date',
  virtualCardReveal: 'Reveal Card',
  wait24: 'Wait 24 hours',
  waitlistNotificationsBody: `Tap on the button below to turn on\n${APP_NAME} notifications.`,
  waitlistNotificationsHeader: 'Want to be notified when\nyou are off the queue?',
  wallet: 'Wallet',
  walletAssetsHeaderBank: `${APP_NAME} Bank`,
  walletAssetsHeaderCrypto: 'Crypto Accounts',
  walletAwaitingDepositDescription: 'Check status in your account or activity feed.',
  walletAwaitingDepositTitle: "We're waiting for your deposit",
  WalletChartAvailable: 'Available to withdraw',
  WalletChartCollateral: 'Loan Collateral',
  WalletChartLending: 'Fixed-rate lending',
  walletDetailAPYbreakdown: 'APY breakdown',
  walletDetailAPYbreakdownConfirmation: 'Sounds good',
  walletDetailAPYDescription:
    'Your total APY (Annual Percentage Yield) is comprised of two parts: a “Base” yield that is paid out as interest each day, and a “Reward” yield that you earn daily as Reward points. You can redeem your Reward points for currency at any time. The base rate and the rate at which you earn Rewards points changes according to your rewards level. See the Rewards Perks details for more information.',
  walletDetailPriceTitle: '{{name}} Price',
  walletDetailsBalance: 'Balance',
  walletDetailsEarnings: 'Earnings',
  walletDetailsPrice: 'Price',
  walletDetailTitle: '{{name}} Wallet',
  walletDetailWalletNotFound: 'Wallet not found',
  walletDetailWalletNotFoundDesc: 'Sorry, there was a problem loading this wallet.',
  walletHeader: 'Total Balance',
  walletHeaderBalance: 'Balance: {{amount}}',
  walletHeaderCurrentPortfolio: 'Current Portfolio',
  walletHeaderTitle: 'Total Balance',
  walletHeaderTotalBalance: 'Total Balance',
  WalletHideZeroBalance: 'Hide zero balances',
  walletMenu: 'Wallet',
  WalletShowZeroBalance: 'Show zero balances',
  walletTitle: 'My Wallet',
  walletTotal: 'Wallet value',
  walletZeroStateHeaderDescription:
    'Deposit funds in your accounts and earn money immediately, borrow money against your assets, spend with a card',
  walletZeroStateHeaderTitle: 'Manage your money',
  welcomeRequestAccess: 'Request Access',
  whatBorrowAssets: 'What assets do you want to borrow?',
  whatInterest: 'What are you interested in?',
  whatLendAssets: 'What assets do you want to lend?',
  whenLoggingIn: 'When logging in',
  whitelistAddressWarningNoWallets: 'You can only add a withdrawal address once you have deposited some funds',
  whitelistedAddresses: 'Withdrawal Addresses',
  withdraw: 'Withdraw',
  withdrawAddAddress: 'Add address',
  withdrawAddAddressField1: 'Asset',
  withdrawAddAddressField2: 'Label',
  withdrawAddAddressField3: 'Address',
  withdrawAddAddressField4: 'Icon',
  withdrawAddAddressField5: 'Save deposit method',
  withdrawAddAddressHeader: 'Add a new address',
  withdrawalLimitReached: 'Withdrawal limit reached',
  withdrawBalanceExceeded: 'Insufficient funds',
  withdrawButton: 'Withdraw',
  withdrawCash: 'Cash・USD',
  withdrawComplete: 'Withdrawal completed.',
  withdrawConfirm: 'Confirm withdrawal',
  withdrawConfirmBalanceAmount: 'Balance: {{amount}}',
  withdrawConfirmButton: 'Confirm Withdrawal',
  withdrawConfirmField1: 'From',
  withdrawConfirmField2: 'To',
  withdrawConfirmField3: 'Amount',
  withdrawConfirmField4: 'Fees',
  withdrawConfirmField5: 'Total',
  withdrawConfirmField6Part1: 'By withdrawing you agree to the applicable ',
  withdrawConfirmField6Part2: 'Terms',
  withdrawConfirmHeader: 'Confirm the withdrawal.',
  withdrawConfirmHeaderHightlight: 'Confirm',
  withdrawConfirmModalButton: 'Sounds good',
  withdrawConfirmModalSubtitle: 'It might take up to 24-72 hours to be approved.',
  withdrawConfirmModalSubtitleTwoLines: 'It might take up to 24-72 hours\nto be approved.',
  withdrawConfirmModalTitle: 'Your withdrawal is now pending.',
  withdrawConfirmTitle: 'Confirm the withdrawal.',
  withdrawConfirmTitleHighlight: 'Confirm',
  withdrawConfirmTotal: 'Total due',
  withdrawDone: 'Withdrawal initiated.',
  withdrawEditValueHighlight: 'How much',
  withdrawEditValueTitle: 'How much {{assetName}} would you like to withdraw?',
  withdrawErrorNoWallet: "Sorry, we couldn't find any accounts to withdraw from",
  withdrawExternalAchCashAccountDetails: 'Cash account details',
  withdrawExternalAchHeader: 'External ACH Transfer',
  withdrawExternalAchHeaderHighlight: 'ACH Transfer',
  withdrawExternalAchSubtitle: 'Initiate an ACH transfer from another bank using your Cash Account details.',
  withdrawFromAsset: '{{assetName}} Account',
  withdrawMaxAvailable: 'Max available',
  withdrawMaxWarning: 'Exceeds balance: {{value}}',
  withdrawMinWarning: 'Must be above minimum: {{value}}',
  withdrawNotificationsHeader: 'Want to be notified when your withdrawal has been processed?',
  withdrawOptionExternalAch: 'External ACH transfer',
  withdrawOptionExternalAchSubtitle: 'No fees. 1-3 business days.',
  withdrawOptionSelectMethod: 'Select a bank',
  withdrawOptionSelectMethodCTA: 'Select',
  withdrawOptionSelectMethodSubtitle: 'Tap here',
  withdrawProgressHeader: 'Your withdrawal is in progress...',
  withdrawProgressSubheader:
    'Transfer may take up to 24 hours. You will receive a notification when your withdrawal is complete.',
  withdrawSelectAssetHighlight: 'withdraw.',
  withdrawSelectAssetTitle: 'Select the asset you would like to withdraw.',
  withdrawSetValueAccountBalance: 'Balance: {{amount}}',
  withdrawSetValueAccountTitle: '{{assetCode}} account',
  withdrawStepFourTitle: 'Confirm the withdrawal.',
  withdrawStepOneTitle: 'Select the asset you would like to withdraw.',
  withdrawStepThreeTitle: 'Choose an address to send your USD Coin to.',
  withdrawStepTwoTitle: 'How much USD Coin would you like to withdraw?',
  withdrawStepTwoTitleHighlight: 'withdraw?',
  yay: 'Yay!',
  yesButton: 'Yes',
  youreInLine: 'You’re in line.',
  zipCodeValidation: 'Invalid zip code length',
}
